import React from 'react';
import ReactPaginate from 'react-paginate';
import IconNext from '../../../assets/image/next.png';
import IconPrev from '../../../assets/image/prev.png';

import './Pagination.scss';

const Pagination = ({active, pageItemsCount, pageTotalCount, onChange}) => {
    return (
        <div className="pagination-container">
            <ReactPaginate
                forcePage={active - 1}
                onPageChange={onChange}
                pageCount={Math.ceil(pageTotalCount / pageItemsCount)}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                previousLabel= {<img src={IconPrev} alt=""/>}
                nextLabel={<img src={IconNext} alt=""/>}
                containerClassName="pagination-list"
                pageClassName="pagination-item"
                pageLinkClassName="pagination-link"
                activeLinkClassName="pagination-link-active"
                breakClassName="pagination-ellipsis"
            />
        </div>
    );
};

export default Pagination;