import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';

import './Sidebar.scss';

class Sidebar extends Component {

    render(){

        const { open, onClick } = this.props;
        return (
            <div>
                <Drawer
                    open={open}
                    onClose={onClick}
                    classes={{
                        root: 'sidebar_wrapper',
                        paper: 'sidebar_paper',
                        paperAnchorLeft: 'sidebar_paper_left',
                    }}
                >
                    <div>
                        <div className="burger-button-default burger-button-default_open  burger-button-default_sidebar " onClick={onClick}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <p><NavLink to={`/admin/price-list`} className="linkClass" onClick={onClick}>Общий прайс-лист</NavLink></p>
                        <p><NavLink to={`/admin/import-orders`} className="linkClass" onClick={onClick}>Импорт заказов</NavLink></p>
                        <p><NavLink to={`/admin/black-list`} className="linkClass" onClick={onClick}>Черный список</NavLink></p>
                        <div className="line_side_bar"></div>
                        <p><NavLink to={`/admin/orders-page`} className="linkClass" onClick={onClick}>Заказы</NavLink></p>
                        <p><NavLink to={`/admin/refund-requests`} className="linkClass" onClick={onClick}>Заявки на возврат</NavLink></p>
                        <p><NavLink to={`/admin/transactions`} className="linkClass" onClick={onClick}>Транзакции</NavLink></p>
                        <div className="line_side_bar"></div>
                        <p><NavLink to={`/admin/good-reception`} className="linkClass" onClick={onClick}>Приём товара</NavLink></p>
                        <p><NavLink to={`/admin/disposal-balances`} className="linkClass" onClick={onClick}>Выдача остатков</NavLink></p>
                    </div>

                </Drawer>
            </div>
        );
    }
}

export default Sidebar;