import React, { Component } from 'react';
import { connect } from 'react-redux';
import TransactionsTable from '../TransactionsTable/TransactionsTable';
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";

import { getTransactions } from "../../../actions/transactionsActions";

import BackLink from '../../HelperComponents/BackLink/BackLink';
import TransactionPanel from '../TransactionPanel/TransactionPanel';


class TransactionsItem extends Component {

    state = {
        loading: true,
        typeOfTransactions: ''
    };

    componentDidMount = () => {
        this.doRequest();
    };

    doRequest = (activePage = 1) => {
        const { getTransactions,  match: { params } } = this.props;
        const { typeOfTransactions } = this.state

        let arr = [];
        if (typeOfTransactions) {
            arr.push(`type_transaction=${typeOfTransactions}`);
        }
        arr.push(`company=${params.id}`);
        arr.push(`page_size=10`);
        arr.push(`page=${activePage}`);

        getTransactions(arr).then(res => {
            if (res.payload && res.payload.status) {
                this.setState({ loading: false });
            }
        });
    };

    onChangePanel = (pageNumber, typeOfTransactions) => {
        this.setState({ typeOfTransactions });
        setTimeout(() => {
            this.doRequest(pageNumber);
        }, 0);
    };

    render(){
        const { loading } = this.state;
        const { transactions: { all_transactions } } = this.props;

        if (loading) return null;

        return (
            <TransitionedBlock>
                <div className="container transactions_page">
                    <div className="flex-center-btw title-block-page">
                        <div className="title-page">
                            <BackLink />
                            {
                                all_transactions && all_transactions.count > 0 ?
                                    <h1>Транзакции "{all_transactions.results.transactions && all_transactions.results.transactions[0].company}"</h1> :
                                    <h1>Транзакций не найдено</h1>
                            }
                            
                        </div>
                    </div>
                    <div className="page_block">
                         <TransactionPanel
                            type = "sorting"
                            doRequest = {this.onChangePanel}
                        />
                        <TransactionsTable
                            doRequest = {this.doRequest}
                            all_transactions = {all_transactions}
                        />
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = ({transactions}) => ({
    transactions
});

const mapDispatchToProps = {
    getTransactions
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsItem);