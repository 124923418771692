import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BackLink from "../../HelperComponents/BackLink/BackLink";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import SearchIcon from "../../../assets/image/search_grey.png";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import PrintComponents from "react-print-components";
import PopperInfo from "../../HelperComponents/PopperInfo/PopperInfo";
import NoItems from "../../HelperComponents/NoItems/NoItems";
import EmptyIcon from "../../../assets/image/no_prices.png";
import LogoBlue from '../../../assets/image/logo_blue.png';
import AllGoods from "./AllGoods";

import moment from "moment/moment";

import {
    searchCompanies,
    getCompaniesGoods,
    postNewResidue,
} from "../../../actions/disposalBalancesActions";
import { isArray } from "../../../helpers/functions";

import IconButton from "@material-ui/core/IconButton";
import CloseDialogIcon from "../../../assets/image/Mask.png";

import "./NewIssue.scss";

class NewIssue extends Component {
    state = {
        open: false,
        openPopper: false,
        anchorEl: null,
        value: "",
        idCompany: null,
        printed: false,
        loading: false,
        now: new Date().toISOString(),
    };

    componentDidMount = () => {
        const { searchCompanies } = this.props;
        this.setState({ anchorEl: document.getElementById("products") });
        searchCompanies();
    };

    doRequest = (id) => {
        const { getCompaniesGoods } = this.props;
        getCompaniesGoods(id);
    };

    toggleDialog = () => {
        const { printed } = this.state;
        if (printed) {
            this.handlePost();
        } else {
            this.setState(({ open }) => ({
                open: !open,
            }));
        }
    };

    onChange = (e) => {
        const value = e.target.value;
        this.setState({ value });
    };

    onKeyUp = (e) => {
        const value = e.target.value;
        if (value.length > 0 && value.indexOf(" ") === -1) {
            this.setState({
                openPopper: true,
                idCompany: null,
            });
        } else {
            this.setState({
                openPopper: false,
                idCompany: null,
            });
        }
    };

    clickAway = () => {
        this.setState(({ openPopper }) => ({
            openPopper: !openPopper,
        }));
    };

    selectProduct = (e) => {
        this.setState({ value: e.company, idCompany: e.id, openPopper: false });
        this.doRequest(e.id);
    };

    handleChangeTextarea = (e) => {
        this.setState({ comment: e.target.value });
    };

    handlePost = () => {
        const { postNewResidue, history } = this.props;
        const { comment, idCompany } = this.state;
        const data = {
            company_id: idCompany,
            comment: comment,
        };
        postNewResidue(data).then((res) => {
            if (res.payload) {
                this.setState({ loading: false });
                history.push("/admin/disposal-balances");
            }
        });
    };

    handlePrint = () => {
        this.setState({ 
            now: new Date().toISOString(),
            printed: true 
        });
    };

    handleDialogPrint = () => {
        const button = document.getElementById("print_button");
        button.click();
    };

    render() {
        const {
            disposal_balances: { companies, goods },
        } = this.props;
        const {
            open,
            openPopper,
            anchorEl,
            value,
            idCompany,
            loading,
            now,
        } = this.state;
        let items = [];
        if (!!companies && companies.length > 0) {
            items = companies.filter((el) =>
                !!el.company && el.company !== null
                    ? el.company.toLowerCase().includes(value.toLowerCase())
                    : null
            );
        }

        return (
            <TransitionedBlock>
                <div className="new_issue container">
                    <div className="flex-center-btw title-block-page">
                        <div className="title-page">
                            <BackLink />
                            <h1>Новая выдача</h1>
                        </div>
                    </div>
                    <div className="page_block">
                        <div className="title">Поставщик</div>
                        <div className="search_block">
                            <input
                                id="products"
                                type="text"
                                value={value}
                                onChange={(e) => this.onChange(e)}
                                onKeyUp={(e) => this.onKeyUp(e)}
                                placeholder="Введите название или телефон компании"
                                autoComplete="off"
                            />
                            <PopperInfo
                                id="products_popper"
                                open={openPopper}
                                anchorEl={anchorEl}
                                clickAway={this.clickAway}
                                position="bottom-start"
                                transition
                            >
                                <ul className="search_transactions_list">
                                    {isArray(items) ? (
                                        items.map((el, idEl) => (
                                            <li
                                                className="search_transactions_item"
                                                onClick={() =>
                                                    this.selectProduct(el)
                                                }
                                                key={idEl}
                                            >
                                                <div className="search_transactions_item_head">
                                                    {el.company}
                                                </div>
                                            </li>
                                        ))
                                    ) : (
                                        <li className="search_transactions_no_items">
                                            Компаний не найдено
                                        </li>
                                    )}
                                </ul>
                            </PopperInfo>
                            <button>
                                <img src={SearchIcon} alt="SearchIcon" />
                            </button>
                        </div>
                        {!!goods.orders && goods.orders.length !== 0 ? (
                            <Fragment>
                                <div className="line_v" />

                                <AllGoods />

                                <div className="line_v" />
                                <div className="title">Комментарий</div>
                                <textarea
                                    className="info_new_issue"
                                    placeholder="Ваше сообщение..."
                                    onChange={(e) =>
                                        this.handleChangeTextarea(e)
                                    }
                                />
                                <div className="btn_wrapper">
                                    <div>
                                        <DefaultButton
                                            onClick={this.toggleDialog}
                                        >
                                            Выдать остаток
                                        </DefaultButton>
                                    </div>
                                    <div onClick={this.handlePrint}>
                                        <PrintComponents
                                            trigger={
                                                <button
                                                    id="print_button"
                                                    className="print_btn"
                                                >
                                                    Печать
                                                </button>
                                            }
                                        >
                                            <div className="print_goods">
                                                <div className='print_goods__header'>
                                                    <div className='print_goods__header--logo'>
                                                        <img src={LogoBlue} />
                                                    </div>
                                                    <div className='print_goods__header--company'>{value}</div>
                                                </div>
                                                <div className='print_goods__date'>
                                                    <span>{moment(now).format('DD.MM.YYYY HH:mm')}</span>
                                                </div>
                                                <AllGoods />
                                            </div>
                                        </PrintComponents>
                                    </div>
                                </div>
                            </Fragment>
                        ) : !!idCompany ? (
                            <NoItems>
                                <div className="box-img">
                                    <img src={EmptyIcon} alt="edit icon" />
                                </div>
                                <div className="title">
                                    Товаров на складе не найдено
                                </div>
                            </NoItems>
                        ) : null}
                    </div>
                    <DialogComponent open={open} onClose={this.toggleDialog}>
                        <div className="title-dialog">Выдать остаток</div>
                        <div className="descriptions_dialog_component">
                            Вы уверены, что не хотите распечатать отчёт?
                        </div>

                        <div className="btn-wrapper order-dialog-btn more-dialog-btn">
                            <div>
                                <DefaultButton
                                    variant="outlined"
                                    classes="cancel_btn"
                                    onClick={this.toggleDialog}
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    onClick={() => {
                                        this.setState({ loading: true });
                                        this.handlePost();
                                    }}
                                    loading={loading}
                                    disabled={loading}
                                >
                                    Выдать
                                </DefaultButton>
                            </div>
                            <div>
                                <button
                                    className="print_btn"
                                    onClick={this.handleDialogPrint}
                                >
                                    Печать
                                </button>
                            </div>
                        </div>
                        <IconButton
                            classes={{
                                root: "dialog_close_button",
                            }}
                            onClick={this.toggleDialog}
                        >
                            <img src={CloseDialogIcon} alt="close icon" />
                        </IconButton>
                    </DialogComponent>
                </div>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = ({ disposal_balances }) => ({
    disposal_balances,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            searchCompanies,
            getCompaniesGoods,
            postNewResidue,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewIssue);
