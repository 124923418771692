import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import BurgerButton from '../Buttons/BurgerButton/BurgerButton'

import LogoHeader from '../../assets/image/logo_header.png';

import './Head.scss';

class Head extends Component {

    state = {
        openButton: false
    };

    openSidebar = () => {
        this.setState(({openButton}) => ({
            openButton: !openButton
        }));
    };

    logOut = () => {
        const { history } = this.props;
        localStorage.clear();
        history.push('/auth');
    };

    render(){

        const { open, onClick } = this.props;
        return (
            <header className="app-head container ">
                <div className="flex-center-btw header-wrapper">
                    <BurgerButton open={open} onClick={onClick}/>
                    <div className="flex-center">
                        <Link to="/admin">
                            <img src={LogoHeader} alt="logo header"/>
                        </Link>
                    </div>
                    <div className="app-head_options">
                        <Button
                            classes={{
                                root: 'exit_btn'
                            }}
                            onClick={this.logOut}
                        >
                            Выйти
                        </Button>
                    </div>
                </div>
            </header>
        );
    }
}

export default Head;