import React, { Component } from "react";
import { connect } from "react-redux";
import BackLink from "../../HelperComponents/BackLink/BackLink";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import {
    changeSupplier,
    importOrdersFile,
    confirmImport,
    pathChangeSupplier,
} from "../../../actions/priceListActions";
import { changeOrderLocal } from "../../../actions/ordersActions";
import IconButton from "@material-ui/core/IconButton";
import CloseDialogIcon from "../../../assets/image/Mask.png";
import SearchIcon from "../../../assets/image/search_grey.png";
import Loader from "../../HelperComponents/Loader/Loader";
import NoItems from "../../HelperComponents/NoItems/NoItems";
import OrdersIcon from "../../../assets/image/no_orders.png";
import "./ImportOrdersInner.scss";
import { SuccessMessage } from "../../HelperComponents/ToastMessages/ToastMessage";

class ImportOrdersInner extends Component {
    state = {
        tab: "0",
        isOpenModal: false,
        testId: null,
        listSupplier: [],
        searchArr: [],
        searchValue: "",
        product: null,
        data: null,
        isLoaded: false,
        successToast: false,
    };

    componentDidMount() {
        const { importOrdersFile, history, import_orders } = this.props;
        if (history.location.state === undefined) {
            history.push("/admin/import-orders");
        } else {
            let file = history.location.state.file;
            let arrOrders = history.location.state.price_lists;
            let supplier_id = history.location.state.hasOwnProperty(
                "supplier_id"
            )
                ? history.location.state.supplier_id
                : null;
            let formData = new FormData();

            formData.append("file", file);
            formData.append("price_lists", arrOrders);
            if (supplier_id) {
                formData.append("company_id", supplier_id);
            }

            if (Object.keys(import_orders).length === 0) {
                importOrdersFile(formData).then((res) => {
                    this.setState({ isLoaded: true });
                    if (
                        res.payload &&
                        res.payload.status &&
                        res.payload.status === 200
                    ) {
                        this.setState({
                            isLoaded: false,
                            data: res.payload.data,
                        });
                    }
                });
            } else {
                this.setState({ data: import_orders });
            }
        }
    }

    changeTab = (tab) => {
        this.setState({ tab });
    };

    toggleDialog = () => {
        this.setState({ isOpenModal: false, searchValue: "" });
    };

    changeValueSearch = (e) => {
        const { listSupplier } = this.state;
        let value = e.target.value;
        this.setState({ searchValue: value });
        let searchArr = listSupplier.filter(
            (el) =>
                el.supplier_name.toLowerCase().indexOf(value.toLowerCase()) !==
                -1
        );
        this.setState({ searchArr });
    };

    changeSupplierWrap = (article_number, producer_name, quantity, product) => {
        const { changeSupplier } = this.props;
        changeSupplier(article_number, producer_name, quantity).then((res) => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({
                    listSupplier: res.payload.data,
                    isOpenModal: true,
                    searchArr: res.payload.data,
                    product,
                });
            }
        });
    };

    changeOrder = (el) => {
        const {
            changeOrderLocal,
            pathChangeSupplier,
            import_orders,
        } = this.props;
        const { product } = this.state;

        let newProductsArray = {
            ...import_orders,
            orders: import_orders.orders.map((order) =>
                order.price_list_id === product.price_list_id
                    ? {
                          ...order,
                          products: order.products.filter(
                              (productEl) =>
                                  productEl.offer_id !== product.offer_id
                          ),
                      }
                    : order
            ),
        };

        let isProducer = (producerId, arrayProducer) => {
            let isBool = 0;
            for (let i = 0; i < arrayProducer.length; i++) {
                if (arrayProducer[i].price_list_id === producerId) {
                    isBool = 1;
                    break;
                }
            }
            return isBool === 1 ? true : false;
        };

        if (!isProducer(el.price_list_id, import_orders.orders)) {
            newProductsArray = {
                ...newProductsArray,
                orders: [
                    ...newProductsArray.orders,
                    {
                        price_list_id: el.price_list_id,
                        supplier_name: el.supplier_name,
                        products: [el],
                    },
                ],
            };
        } else {
            newProductsArray = {
                ...newProductsArray,
                orders: newProductsArray.orders.map((order) =>
                    order.price_list_id === el.price_list_id
                        ? { ...order, products: [...order.products, el] }
                        : order
                ),
            };
        }

        this.setState({ data: newProductsArray });

        let updateOrders = newProductsArray;
        pathChangeSupplier(updateOrders);
        changeOrderLocal(updateOrders.orders);

        this.toggleDialog();
    };
    importProduct = (data, products, index_order, index) => {
        const product = products.splice(index, 1)
        if (products.length === 0) {
            data.orders.splice(index_order, 1)
        }
        data.undistributed.push(...product)
        this.setState({data: data})
    }
    handleConfirmImport = () => {
        const { data } = this.state;
        const { confirmImport, history } = this.props;
        let supplier_id = history.location.state.hasOwnProperty("supplier_id")
            ? history.location.state.supplier_id
            : null;
        if (supplier_id !== null) {
            data.company_id = supplier_id;
        }
        const allProducts = {
            company_id: data.company_id,
            orders: data.orders,
            undistributed: data.undistributed.map((product) => {
                return {
                    article_number: product.article_number,
                    producer_name: product.producer_name,
                    product_name: product.product_name,
                    quantity: product.quantity
                }
            })
        };
        confirmImport(allProducts).then((res) => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({ successToast: true });
                setTimeout(() => {
                    history.push("/admin/import-orders");
                }, 3000);
                // window.open(`https://${res.payload.data.undistributed_download_path}`, '_blank');
            }
        });
    };

    render() {
        const { import_orders } = this.props;
        const {
            tab,
            isOpenModal,
            searchValue,
            searchArr,
            isLoaded,
            successToast,
        } = this.state;
        if (Object.keys(import_orders).length === 0) {
            return (
                <div className="orders_inner_load">
                    <Loader />
                </div>
            );
        }
        return (
            <TransitionedBlock>
                <div className="import_orders_inner container">
                    <div className="flex-center-btw title-block-page">
                        <div className="title-page">
                            <BackLink />
                            <h1>Импорт заказов</h1>
                        </div>
                    </div>

                    {/*    <NoItems>
                        <div className="box-img"><img src={OrdersIcon} alt="edit icon"/></div>
                        <div className="title" >Пока здесь нет товаров</div>
                        <div className="descriptions">Добавляйте сюда товары, которые не нужно учитывать при формировании общего прайса и импорта заказов.</div>
                    </NoItems>
                    */}
                    <div className="item_page_block">
                        {isLoaded || Object.keys(import_orders).length === 0 ? (
                            <Loader />
                        ) : (
                            <div>
                                <div className="tab_custom">
                                    <button
                                        className={tab === "0" ? "active" : ""}
                                        onClick={() => this.changeTab("0")}
                                    >
                                        Заказы
                                    </button>
                                    <button
                                        className={tab === "1" ? "active" : ""}
                                        onClick={() => this.changeTab("1")}
                                    >
                                        Нераспределённые товары
                                    </button>
                                </div>

                                {tab === "0" && (
                                    <div className="orders_inner_table">
                                        <div className="table_container transactions_columns">
                                            {import_orders.orders.length ===
                                            0 ? (
                                                <NoItems>
                                                    <div className="box-img">
                                                        <img
                                                            src={OrdersIcon}
                                                            alt="edit icon"
                                                        />
                                                    </div>
                                                    <div className="title">
                                                        Пока здесь нет заказов
                                                    </div>
                                                    <div className="descriptions">
                                                        Попробуйте добавить
                                                        прайс-листы других
                                                        поставщиков.
                                                    </div>
                                                </NoItems>
                                            ) : (
                                                import_orders.orders.map(
                                                    (order, index_order) => {
                                                        return (
                                                            <div
                                                                className="block_table"
                                                                key={index_order}
                                                            >
                                                                {order.products.length > 0 &&
                                                                    <div className="title_table">
                                                                        {
                                                                            order.supplier_name
                                                                        }
                                                                    </div>
                                                                }
                                                                <div className="table_body">
                                                                    {order.products.map(
                                                                        (
                                                                            product,
                                                                            index
                                                                        ) => {
                                                                            let warningClass = ''
                                                                            product.import_price !== product.sell_price ? warningClass = "expensive-cost" : warningClass = ''
                                                                            return (
                                                                                <div
                                                                                    className="table_row"
                                                                                    key={index}
                                                                                >
                                                                                    <div className={`row_item ${warningClass}`}>
                                                                                        <span>
                                                                                            Артикул
                                                                                        </span>
                                                                                        <p>
                                                                                            #
                                                                                            {
                                                                                                product.article_number
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className={`row_item ${warningClass}`}>
                                                                                        <span>
                                                                                            Производитель
                                                                                        </span>
                                                                                        <p>
                                                                                            {
                                                                                                product.producer_name
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className={`row_item ${warningClass}`}>
                                                                                        <span>
                                                                                            Название
                                                                                            товара
                                                                                        </span>
                                                                                        <p>
                                                                                            {product.product_name
                                                                                                ? product
                                                                                                      .product_name
                                                                                                      .length >
                                                                                                  60
                                                                                                    ? product.product_name.slice(
                                                                                                          0,
                                                                                                          57
                                                                                                      ) +
                                                                                                      "..."
                                                                                                    : product.product_name
                                                                                                : "-"}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className={`row_item ${warningClass}`}>
                                                                                        <span>
                                                                                            Количество
                                                                                        </span>
                                                                                        <p>
                                                                                            {
                                                                                                product.quantity
                                                                                            }
                                                                                        </p>
                                                                                    </div>

                                                                                    <div className={`row_item ${warningClass}`}>
                                                                                        <span>
                                                                                            Цена
                                                                                        </span>
                                                                                        <p>
                                                                                            {
                                                                                                product.original_price
                                                                                            }{" "}
                                                                                            ₽
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className={`row_item ${warningClass}`}>
                                                                                        <span>
                                                                                            Цена
                                                                                            продажи
                                                                                        </span>
                                                                                        <p>
                                                                                            {
                                                                                                product.sell_price
                                                                                            }{" "}
                                                                                            ₽
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="row_item row_item__buttons">
                                                                                        <button
                                                                                            onClick={() =>
                                                                                                this.changeSupplierWrap(
                                                                                                    product.article_number,
                                                                                                    product.producer_name,
                                                                                                    product.quantity,
                                                                                                    product
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Сменить
                                                                                            поставщика
                                                                                        </button>
                                                                                        { product.import_price !== product.sell_price &&
                                                                                            <button className="button_moving"
                                                                                                onClick={() =>
                                                                                                    this.importProduct(import_orders, order.products, index_order, index)
                                                                                                }
                                                                                            >
                                                                                                Переместить в нераспределённые
                                                                                            </button>
                                                                                        }                                                                                    
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )
                                            )}

                                            <div className="wrapper_btn_table">
                                                {import_orders.orders.length !==
                                                0 ? (
                                                    <DefaultButton
                                                        variant="contained"
                                                        onClick={
                                                            this
                                                                .handleConfirmImport
                                                        }
                                                    >
                                                        Подтвердить
                                                    </DefaultButton>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {tab === "1" && (
                                    <div className="orders_inner_table undistributed_goods">
                                        <div className="table_container transactions_columns">
                                            {import_orders.undistributed
                                                .length === 0 ? (
                                                <NoItems>
                                                    <div className="box-img">
                                                        <img
                                                            src={OrdersIcon}
                                                            alt="edit icon"
                                                        />
                                                    </div>
                                                    <div className="title">
                                                        Все товары распределены
                                                    </div>
                                                </NoItems>
                                            ) : (
                                                import_orders.undistributed.map(
                                                    (el, index) => {
                                                        return (
                                                            <div
                                                                className="block_table"
                                                                key={index}
                                                            >
                                                                <div className="table_body">
                                                                    <div className="table_row">
                                                                        <div className="row_item">
                                                                            <span>
                                                                                Артикул
                                                                            </span>
                                                                            <p>
                                                                                #
                                                                                {
                                                                                    el.article_number
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        <div className="row_item">
                                                                            <span>
                                                                                Производитель
                                                                            </span>
                                                                            <p>
                                                                                {
                                                                                    el.producer_name
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        <div className="row_item">
                                                                            <span>
                                                                                Название
                                                                                товара
                                                                            </span>
                                                                            <p>
                                                                                {el.product_name
                                                                                    ? el.product_name
                                                                                    : "-"}
                                                                            </p>
                                                                        </div>
                                                                        <div className="row_item">
                                                                            <span>
                                                                                Количество
                                                                            </span>
                                                                            <p>
                                                                                {
                                                                                    el.quantity
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )
                                            )}

                                            <div className="wrapper_btn_table">
                                                {import_orders.undistributed
                                                    .length !== 0 ? (
                                                    <DefaultButton
                                                        variant="contained"
                                                        onClick={
                                                            this.handleConfirmImport
                                                        }
                                                    >
                                                        Подтвердить
                                                    </DefaultButton>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <DialogComponent open={isOpenModal} onClose={this.toggleDialog}>
                    <h1 className="change_supplier_title">
                        Сменить поставщика
                    </h1>
                    <div className="search_wrapper search_transactions_panel change_supplier_serach">
                        <div className="search_product_wrapper">
                            <input
                                // id="products"
                                type="text"
                                value={searchValue}
                                // onKeyDown={(e) => handleSearch(e)}
                                onChange={this.changeValueSearch}
                                placeholder="Введите название компании или номер заказа"
                                autoComplete="off"
                            />
                        </div>
                        <div className="search_input_icon">
                            <img src={SearchIcon} alt="SearchIcon" />
                        </div>
                    </div>
                    <div className="scroll_block">
                        <div className="block_table_wrapper">
                            {searchArr.length ? (
                                <div className="table_body">
                                    {searchArr.map((el, index) => {
                                        return (
                                            <div
                                                className="table_row"
                                                key={index}
                                                onClick={() =>
                                                    this.changeOrder(el)
                                                }
                                            >
                                                <div className="row_item">
                                                    <p>{el.supplier_name}</p>
                                                </div>
                                                <div className="row_item">
                                                    <span>Цена</span>
                                                    <p>{el.original_price} ₽</p>
                                                </div>
                                                <div className="row_item">
                                                    <span>Цена продажи</span>
                                                    <p>{el.sell_price} ₽</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <p>Нет поставщиков для смены</p>
                            )}
                        </div>
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button",
                        }}
                        onClick={this.toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon" />
                    </IconButton>
                </DialogComponent>
                {successToast && (
                    <SuccessMessage
                        close={() => this.setState({ successToast: false })}
                        message="Заказы успешно созданы"
                    />
                )}
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = ({ orders }) => {
    return {
        import_orders: orders.import_orders,
    };
};

const mapDispatchToProps = {
    changeSupplier,
    importOrdersFile,
    changeOrderLocal,
    confirmImport,
    pathChangeSupplier,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImportOrdersInner);
