import * as types from "../actions/constants";

const INITIAL_STATE = {
    all_transactions: {},
    all_companies: {}
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.GET_TRANSACTIONS_SUCCESS :
            return {...state, all_transactions : action.payload.data};
        case types.GET_COMPANIES_SUCCESS :
            return {...state, all_companies : action.payload.data};

        default:
            return state;
    }
}