import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from "moment/moment";
import Pagination from '../../HelperComponents/Pagination/Pagination';
import Loader from "../../HelperComponents/Loader/Loader";

import { getReceptionHistory } from "../../../actions/goodsReceptionActions";

import './ReceptionHistory.scss';

class ReceptionHistory extends Component {
    state = {
        loading: true,
        activePage: 1
    };

    componentDidMount = () => {
        this.doRequest();
    };

    doRequest = () => {
        const { getReceptionHistory } = this.props;
        const { activePage } = this.state;
        let arr = [];
        arr.push(`page_size=10`);
        arr.push(`page=${activePage}`);

        getReceptionHistory(arr).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ loading: false });
            }
        })
    };

    changePage = (pageNumber) => {
        this.setState({ activePage: pageNumber });
        setTimeout(() => {
            this.doRequest();
        }, 0);
    };

    render(){
        const { loading, activePage } = this.state;
        const { goods_reception:{ reception_history }} = this.props;

        if (loading) return <Loader/>;

        return (
            <div className="reception_history_block container">
                <div className="reception_history ">
                    <div className="title">История приёма товара</div>
                    <div className="reception_history_table">
                        <div className="table_container transactions_columns">
                            <div className="table_header">
                                <div className="table_row">
                                    <div className="row_item">Дата/время</div>
                                    <div className="row_item">Номер УПД</div>
                                    <div className="row_item">Сумма</div>
                                </div>
                            </div>
                            <div className="table_body">
                                {reception_history.results.map(item => (
                                    <div className="table_row" key={item.id}>
                                        <div className="row_item">{moment(item.date).format('DD.MM.YYYY HH:mm')}</div>
                                        <div className="row_item">{item.upd_number || "Mexcar Auto"}</div>
                                        <div className="row_item">{item.sum} ₽</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {reception_history.count && reception_history.count > 10 ?
                        <div>
                            <Pagination
                                active={activePage}
                                pageItemsCount={10}
                                pageTotalCount={reception_history.count}
                                onChange={(e) => this.changePage(e.selected + 1)}
                            />
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return{
        goods_reception: state.goods_reception
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getReceptionHistory
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceptionHistory);