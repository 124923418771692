import * as types from "../actions/constants";

const INITIAL_STATE = {
    companies: {},
    goods: [],
    all_residues: []
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.SEARCH_COMPANY_SUCCESS :
            return {...state, companies : action.payload.data};
        case types.GET_COMPANIES_GOODS_SUCCESS :
            return {...state, goods : action.payload.data};
        case types.POST_NEW_RESIDUE_SUCCESS :
            return {...state, goods : []};
        case types.GET_ALL_RESIDUES_SUCCESS :
            return {...state, all_residues : action.payload.data};

        default:
            return state;
    }
}