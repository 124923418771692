import * as types from "./constants.jsx";

export function postInvoiceFile(data) {
    return {
        type: types.POST_INVOICE_FILE,
        payload: {
            client: "default",
            request: {
                url: `/admin/japarts/file_download/`,
                method: "post",
                data,
            },
        },
    };
}

export function getReceptionHistory(arr) {
    return {
        type: types.GET_RECEPTION_HISTORY,
        payload: {
            client: "default",
            request: {
                url: `/admin/japarts/accepted/orders/${
                    arr.length && arr.length !== 0 ? "?" : ""
                }${arr.join("&")}`,
                method: "get",
            },
        },
    };
}

export function postAcceptedOrders(data) {
    return {
        type: types.POST_ACCEPTED_ORDERS,
        payload: {
            client: "default",
            request: {
                url: `/admin/japarts/accepted/orders/`,
                method: "post",
                data,
            },
        },
    };
}

export function getMexcarAuto() {
    return {
        type: types.GET_MEXCAR_AUTO,
        payload: {
            client: "default",
            request: {
                url: `/admin/japarts/orders/mexcarauto/`,
                method: "get",
            },
        },
    };
}

export function orderMexcarAuto(data) {
    return {
        type: types.ORDER_MEXCAR_AUTO,
        payload: {
            client: "default",
            request: {
                url: `/admin/japarts/orders/mexcarauto/`,
                method: "post",
                data,
            },
        },
    };
}

export function getFileTypeList() {
    return {
        type: types.FILE_TYPE_LIST,
        payload: {
            client: "default",
            request: {
                url: `/admin/japarts/list_filetype/`,
                method: "get",
            },
        },
    };
}

export function sendOrdersToReject(data) {
    return {
        type: types.SEND_ORDERS_TO_REJECT,
        payload: {
            client: "default",
            request: {
                url: '/admin/japarts/orders/mexcarauto/cancel/',
                method: "post",
                data,
            },
        },
    };
}

export function getWaitingReceptionGoods() {
    return {
        type: types.GET_WAITING_RECEPTION_GOODS,
        payload: {
            client: "default",
            request: {
                url: `/admin/japarts/acceptance_files/`,
                method: "get",
            },
        },
    };
}

export function sendWaitingReceptionGoods(data) {
    return {
        type: types.POST_INVOICE_FILE,
        payload: {
            client: "default",
            request: {
                url: `/admin/japarts/acceptance_files/`,
                method: "post",
                data,
            },
        },
    };
}
