import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import PrintComponents from "react-print-components";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import IconButton from "@material-ui/core/IconButton";
import CloseDialogIcon from "../../../assets/image/Mask.png";
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage";
import ErrorIcon from "@material-ui/icons/Error";

import {
    postAcceptedOrders,
    orderMexcarAuto,
} from "../../../actions/goodsReceptionActions";

import Loader from "../../HelperComponents/Loader/Loader";

import "./Summary.scss";

class Summary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            arr: [],
            originalSum: null,
            printed: false,
            file_type: localStorage.getItem("file_type"),
            loading: false,
            error: false,
        };
    }

    componentDidMount = () => {
        const { originalSum, processedOrders } = this.props;
        this.setState({
            originalSum: processedOrders.reduce(
                (sum, i) =>
                    (sum += i.amount - i.in_stock - i.canceled - i.expelled),
                0
            ),
        });
        this.doRequest();
    };

    doRequest = () => {
        const { processedOrders } = this.props;
        this.setState({ arr: processedOrders });
    };

    toggleDialog = () => {
        this.setState(({ open }) => ({
            open: !open,
        }));
    };

    handlePost = () => {
        const {
            postAcceptedOrders,
            sum,
            upd_number,
            changeTab,
            orderMexcarAuto,
            clearProcessed,
        } = this.props;
        const { arr, open } = this.state;
        const data = {
            sum: arr
                .reduce((sum, el) => {
                    return sum + el.original_price * el.amount_in_stock;
                }, 0)
                .toFixed(2),
            orders: arr.map((el) => ({
                id: el.id,
                in_stock: el.amount_in_stock,
            })),
        };
        orderMexcarAuto(data).then((res) => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                localStorage.removeItem("file_type");
                setTimeout(() => {
                    changeTab(0);
                    clearProcessed();
                    this.setState({ loading: false });
                }, 500);
            } else {
                open && this.toggleDialog();

                this.setState({
                    loading: false,
                    error: res.error.response.data.detail,
                });
            }
        });
    };

    handlePrint = () => {
        this.setState({ printed: true });
    };

    handleDialogPrint = () => {
        const button = document.getElementById("print_button");
        button.click();
    };

    render() {
        const { changeTab } = this.props;
        const { arr, originalSum, open, printed, loading, error } = this.state;
        let sum = 0;
        if (!!arr) {
            arr.forEach((el) => {
                sum += Number(el.amount_in_stock);
            });
        }
        if (originalSum === 0 || sum === 0) return <Loader />;
        return (
            <div className="summary_page">
                <div className="summary_table summary_auto">
                    <div className="table_container transactions_columns">
                        <div className="table_header">
                            <div className="table_row">
                                <div className="row_item">Заказ</div>
                                <div className="row_item">Деталь</div>
                                <div className="row_item">Поставщик</div>
                                <div className="row_item">Цена</div>
                                {/*{file_type === "1" && <div className="row_item">Комментарий</div>}*/}
                                <div className="row_item">К-во товара</div>
                                <div className="row_item">Принято</div>
                            </div>
                        </div>
                        <div className="table_body">
                            {arr.length !== 0 &&
                                arr
                                    .sort((el) =>
                                        el.amount !== el.amount_in_stock
                                            ? -1
                                            : 1
                                    )
                                    .map((el, idEl) => (
                                        <div className="table_row" key={idEl}>
                                            <div className="row_item">
                                                #{el.id}
                                            </div>
                                            <div className="row_item">
                                                #{el.article_number}{" "}
                                                &nbsp;•&nbsp; {el.producer}
                                            </div>
                                            <div className="row_item">
                                                {el.order_company}
                                            </div>
                                            <div className="row_item">
                                                {el.original_price} ₽
                                            </div>
                                            {/*{file_type === "1" && <div className="row_item">{el.comment}</div>}*/}
                                            <div className="row_item">
                                                {el.amount -
                                                    el.in_stock -
                                                    el.canceled -
                                                    el.expelled}
                                            </div>
                                            <div className="row_item">
                                                <div
                                                    className={`${
                                                        el.amount - el.in_stock - el.canceled - el.expelled ===
                                                        Number(el.amount_in_stock)
                                                            ? "green_text"
                                                            : "red_text"
                                                    }`}
                                                >
                                                    {el.amount_in_stock}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                        </div>
                    </div>
                    <div className="info_summary">
                        <div>
                            <span>Принято товаров: </span>
                            <span
                                className={`${
                                    sum === originalSum
                                        ? "green_text"
                                        : "red_text"
                                } `}
                            >
                                {sum}
                            </span>
                        </div>
                        <div>
                            <span>Общая сумма: </span>
                            <span>
                                {arr &&
                                    arr
                                        .reduce((sum, el) => {
                                            return (
                                                sum +
                                                el.original_price *
                                                    el.amount_in_stock
                                            );
                                        }, 0)
                                        .toFixed(2)}{" "}
                                ₽
                            </span>
                        </div>
                    </div>
                    <div className="btn_wrapper">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                classes="cancel_btn"
                                onClick={() => {
                                    changeTab(3);
                                    this.props.clearProcessed();
                                }}
                            >
                                Назад
                            </DefaultButton>
                            <DefaultButton
                                onClick={() => {
                                    if (!printed || originalSum !== sum) {
                                        this.toggleDialog();
                                    } else {
                                        this.setState({ loading: true });
                                        this.handlePost();
                                    }
                                }}
                                loading={loading}
                                disabled={loading}
                            >
                                Завершить сеанс
                            </DefaultButton>
                            <div className="submit_order_error flex-center">
                                {error ? (
                                    <TooltipMessage
                                        text={error}
                                        delay={200}
                                        error
                                        position="right"
                                        classes=""
                                    >
                                        <ErrorIcon />
                                    </TooltipMessage>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <div onClick={this.handlePrint}>
                            <PrintComponents
                                trigger={
                                    <button
                                        id="print_button"
                                        className="print_btn"
                                    >
                                        Печать
                                    </button>
                                }
                            >
                                <Fragment>
                                    <div className="print_goods print_goods_auto">
                                        <div className="table_container transactions_columns">
                                            <div className="table_header">
                                                <div className="table_row">
                                                    <div className="row_item">
                                                        Заказ
                                                    </div>
                                                    <div className="row_item">
                                                        Деталь
                                                    </div>
                                                    <div className="row_item">
                                                        Поставщик
                                                    </div>
                                                    <div className="row_item">
                                                        Цена
                                                    </div>
                                                    {/*{file_type === "1" && <div className="row_item">Комментарий</div>}*/}
                                                    <div className="row_item">
                                                        К-во товара
                                                    </div>
                                                    <div className="row_item">
                                                        Принято
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table_body">
                                                {arr.length !== 0 &&
                                                    arr.map((el, idEl) => (
                                                        <div
                                                            className="table_row"
                                                            key={idEl}
                                                        >
                                                            <div className="row_item">
                                                                #{el.id}
                                                            </div>
                                                            <div className="row_item">
                                                                #
                                                                {
                                                                    el.article_number
                                                                }{" "}
                                                                &nbsp;•&nbsp;{" "}
                                                                {el.producer}
                                                            </div>
                                                            <div className="row_item">
                                                                {el.company}
                                                            </div>
                                                            <div className="row_item">
                                                                {
                                                                    el.original_price
                                                                }{" "}
                                                                ₽
                                                            </div>
                                                            {/*{file_type === "1" && <div className="row_item">{el.comment}</div>}*/}
                                                            <div className="row_item">
                                                                {el.amount -
                                                                    el.in_stock -
                                                                    el.canceled -
                                                                    el.expelled}
                                                            </div>
                                                            <div className="row_item">
                                                                <div
                                                                    className={`${
                                                                        el.amount - el.in_stock - el.canceled - el.expelled  === el.amount_in_stock
                                                                            ? "green_text"
                                                                            : "red_text"
                                                                    }`}
                                                                >
                                                                    {
                                                                        el.amount_in_stock
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info_summary">
                                        <div>
                                            <span>Принято товаров: </span>
                                            <span className={"green_text"}>
                                                {sum}
                                            </span>
                                        </div>
                                        <div>
                                            <span>Общая сумма: </span>
                                            <span>
                                                {arr &&
                                                    arr
                                                        .reduce((sum, el) => {
                                                            return (
                                                                sum +
                                                                el.original_price *
                                                                    el.amount_in_stock
                                                            );
                                                        }, 0)
                                                        .toFixed(2)}{" "}
                                                ₽
                                            </span>
                                        </div>
                                    </div>
                                </Fragment>
                            </PrintComponents>
                        </div>
                    </div>
                    <DialogComponent open={open} onClose={this.toggleDialog}>
                        <div className="title-dialog">Завершить сеанс</div>
                        {Number(originalSum) !== Number(sum) ? (
                            <div className="descriptions_dialog_component no_margin">
                                Внимание! Количество товаров в накладной
                                отличается от количества принятых товаров. Вы
                                уверены, что всё равно хотите завершить сеанс
                                приёма товара?
                            </div>
                        ) : (
                            <div className="descriptions_dialog_component  no_margin">
                                Вы уверены, что не хотите распечатать отчёт?
                            </div>
                        )}
                        <div className="btn-wrapper order-dialog-btn more-dialog-btn">
                            <div>
                                <DefaultButton
                                    variant="outlined"
                                    classes="cancel_btn"
                                    onClick={this.toggleDialog}
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    onClick={() => {
                                        this.setState({ loading: true });
                                        this.handlePost();
                                    }}
                                    loading={loading}
                                    disabled={loading}
                                >
                                    Завершить
                                </DefaultButton>
                            </div>
                            {!printed ? (
                                <div>
                                    <div>
                                        <button
                                            className="print_btn"
                                            onClick={this.handleDialogPrint}
                                        >
                                            Печать
                                        </button>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <IconButton
                            classes={{
                                root: "dialog_close_button",
                            }}
                            onClick={this.toggleDialog}
                        >
                            <img src={CloseDialogIcon} alt="close icon" />
                        </IconButton>
                    </DialogComponent>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        goods_reception: state.goods_reception,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            postAcceptedOrders,
            orderMexcarAuto,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Summary);
