import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { splitByCommas } from "../../../helpers/functions";

import './NewIssue.scss';

class AllGoods extends Component {

    render(){
        const { disposal_balances:{ goods }} = this.props;
        return (
            <Fragment>
                <div className="title">Товары</div>
                <div className="new_issue_table">
                    <div className="table_container transactions_columns">
                        <div className="table_header">
                            <div className="table_row">
                                <div className="row_item">Название</div>
                                <div className="row_item">Артикул</div>
                                <div className="row_item">Производитель</div>
                                <div className="row_item">К-во товара</div>
                                <div className="row_item">Цена</div>
                                <div className="row_item">Сумма</div>
                            </div>
                        </div>
                        <div className="table_body">
                            {goods.orders && goods.orders.map((good, idGood) => (
                                <div className="table_row" key={idGood}>
                                    <div className="row_item">{good.name}</div>
                                    <div className="row_item">{good.article_number}</div>
                                    <div className="row_item">{good.producer}</div>
                                    <div className="row_item">{good.in_stock}</div>
                                    <div className="row_item">{splitByCommas(good.price.toFixed(2))}</div>
                                    <div className="row_item">{splitByCommas(good.sum.toFixed(2))}</div>
                                </div>
                            ))}
                        </div>
                        <div className="table_container__summary">Итого: {goods.total_sum}</div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = ({disposal_balances}) => ({
    disposal_balances
});


export default connect(mapStateToProps)(AllGoods);