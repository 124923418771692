import React, { Component } from "react";
import { connect } from "react-redux";
import Barcode from "react-barcode";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import IconButton from "@material-ui/core/IconButton";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import PrintComponents from "react-print-components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { bindActionCreators } from "redux";
import FormControl from "@material-ui/core/FormControl";
import { positiveNumber } from "../../../helpers/validation";
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock"
import SnackBar from "../../HelperComponents/SnackBar/SnackBar";
// import PopperInfo from "../../HelperComponents/PopperInfo/PopperInfo";

import CloseDialogIcon from "../../../assets/image/Mask.png";
import SearchIcon from "../../../assets/image/search_grey.png";
import delete_items from "../../../assets/image/delete_items.png";
import LogoIcon from "../../../assets/image/logo_to_print.png";
import LogotypeIcon from "../../../assets/image/logotype_to_print.png";
import MinusIcon from "../../../assets/image/minus.png";
import PlusIcon from "../../../assets/image/plus.png";
import { getMexcarAuto, sendOrdersToReject } from "../../../actions/goodsReceptionActions";

import "../ProductSelection/ProductSelection.scss";
import SelectComponent from "./../../HelperComponents/SelectComponent/SelectComponent";
import {
    getSuppliers,
    getSuppliersAuto,
} from "./../../../actions/priceListActions";
import Loader from "../../HelperComponents/Loader/Loader";

class ProductSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            openNoItemDialog: false,
            openItemDialog: false,
            openItemInfoDialog: false,
            openRejectDialog: false,
            arrayOfOrders: [],
            filteredOrders: [],
            processedOrders: props.processedOrders,
            specificOrder: [],
            quantity: 1,
            maxAmount: null,
            orderToDelete: null,
            originalSum: null,
            file_type: localStorage.getItem("file_type"),
            inputValue: "",
            anchorEl: null,
            inStockCurrent: null,
            errorOpened: false,
            types_list: [],
            type: { label: "Все", value: "" },
            filteredArrayOfOrders: [],
            loading: true,
            loadingReject: false,
            maxAmountAuto: null,
            localAmountLeft: null,
            showSnackBar: false,
        };
    }

    componentDidMount = () => {
        const {
            goods_reception,
            arrayOfOrders,
            getMexcarAuto,
            getSuppliersAuto,
        } = this.props;
        let originalSum = 0;
        getMexcarAuto().then((res) => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                res &&
                    res.payload &&
                    res.payload.data &&
                    this.setState({
                        arrayOfOrders: res.payload.data,
                        filteredArrayOfOrders: res.payload.data,
                        loading: false,
                    });
            }
        });
        getSuppliersAuto().then((res) => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                res &&
                    res.payload &&
                    res.payload.data &&
                    this.setState({
                        types_list: [
                            { label: "Все", value: "" },
                            ...res.payload.data.map((el) => ({
                                label: el.company,
                                value: el.company,
                            })),
                        ],
                    });
            }
        });
        // setTimeout(() => {
        //     this.setState({
        //         arrayOfOrders: arrayOfOrders.length
        //             ? [...arrayOfOrders]
        //             : [...goods_reception.orders_history.rows],
        //         originalSum: originalSum,
        //     });
        // }, 0);
    };

    deleteDialog = (processedOrder) => {
        this.setState(({ openDeleteDialog }) => ({
            openDeleteDialog: !openDeleteDialog,
            orderToDelete: processedOrder,
        }));
    };

    noItemDialog = () => {
        this.setState(({ openNoItemDialog }) => ({
            openNoItemDialog: !openNoItemDialog,
        }));
    };

    itemDialog = () => {
        this.setState(({ openItemDialog }) => ({
            openItemDialog: !openItemDialog,
        }));
    };

    itemInfoDialog = (noItemDialog) => {
        this.setState(({ openItemInfoDialog }) => ({
            openItemInfoDialog: noItemDialog ? false : !openItemInfoDialog,
            openItemDialog: false,
        }));
        setTimeout(() => {
            this.handleEventAdding();
        }, 100);
    };

    handleEventAdding = () => {
        const self = this;
        const item = document.getElementById("quantity_dialog");
        if (item) {
            item.addEventListener("keyup", function(e) {
                if (e.keyCode === 13) {
                    self.handleOrder();
                }
            });
            item.focus();
        }
    };

    selectSpecificOrder = (item) => {
        const { filteredOrders } = this.state;
        const selectedOrder = filteredOrders.filter((order) => order === item);
        this.setState({
            specificOrder: selectedOrder,
            currentOrder: selectedOrder,
            maxAmount: selectedOrder[0].amount_left || selectedOrder[0].amount,
            maxAmountAuto:
                selectedOrder[0].amount_left ||
                selectedOrder[0].amount -
                    selectedOrder[0].in_stock -
                    selectedOrder[0].canceled -
                    selectedOrder[0].expelled,
            localAmountLeft: selectedOrder[0].amount_left,
        });
        if (
            selectedOrder[0].amount_left
                ? selectedOrder[0].amount_left > 1
                : selectedOrder[0].amount > 1
        ) {
            setTimeout(() => {
                this.itemInfoDialog();
            }, 0);
        } else {
            setTimeout(() => {
                this.handleOrder(true);
            }, 0);
        }
    };

    onKeyUp = (e) => {
        if (e.keyCode === 13) {
            setTimeout(() => {
                this.searchHandle();
            }, 0);
        }
    };

    searchHandle = () => {
        const { inputValue, arrayOfOrders, filteredArrayOfOrders } = this.state;
        let searchedOrders = [];
        searchedOrders = filteredArrayOfOrders.filter(
            (item) =>
                item.article_number.toString().toLowerCase() ===
                inputValue.toString().toLowerCase()
        );
        if (searchedOrders.length === 0) {
            this.noItemDialog();
        } else if (searchedOrders.length === 1) {
            this.setState({
                specificOrder: searchedOrders,
                currentOrder: searchedOrders,
                maxAmount:
                    searchedOrders[0].amount_left || searchedOrders[0].amount,
                quantity: 1,
                inStockCurrent:
                    searchedOrders[0].in_stock +
                    searchedOrders[0].canceled +
                    searchedOrders[0].expelled,
                maxAmountAuto:
                    searchedOrders[0].amount_left ||
                    searchedOrders[0].amount -
                        searchedOrders[0].in_stock -
                        searchedOrders[0].canceled -
                        searchedOrders[0].expelled,
                localAmountLeft: searchedOrders[0].amount_left,
            });
            if (
                searchedOrders[0].amount_left
                    ? searchedOrders[0].amount_left > 1
                    : searchedOrders[0].amount > 1
            ) {
                this.itemInfoDialog();
            } else {
                setTimeout(() => {
                    this.handleOrder(true);
                }, 0);
            }
        } else {
            this.itemDialog();
            this.setState({ filteredOrders: searchedOrders });
        }
    };

    searchChange = (e) => {
        this.setState({ inputValue: e.trim() });
    };

    changeAmount = (e) => {
        const { quantity, maxAmount, maxAmountAuto } = this.state;
        if (e === "-" && quantity !== 1) {
            this.setState(({ quantity }) => ({
                quantity: Number(quantity) - 1,
            }));
        } else if (e === "+" && quantity < maxAmountAuto && quantity >= 1) {
            this.setState(({ quantity }) => ({
                quantity: Number(quantity) + 1,
            }));
        } else if (e !== "-" && e !== "+" && positiveNumber(e.target.value)) {
            if (e.target.value > maxAmountAuto) {
                this.setState({ quantity: 1 });
            } else {
                this.setState({ quantity: +e.target.value });
            }
        }
    };

    handleChange = (name) => (event) => {
        const { filteredArrayOfOrders, arrayOfOrders } = this.state;
        this.setState({
            [name]: event,
            filteredArrayOfOrders:
                event.value !== ""
                    ? arrayOfOrders.filter(
                          (el) => el.order_company === event.value
                      )
                    : arrayOfOrders,
        });
    };

    handleOrder = (noItemDialog = false) => {
        const {
            quantity,
            maxAmount,
            specificOrder,
            processedOrders,
            inStockCurrent,
            maxAmountAuto,
            localAmountLeft,
        } = this.state;
        const printButton = document.getElementById("print_btn");
        let arrayOfProcessedOrders = [...processedOrders];
        let customOrder = { ...specificOrder[0], amount_in_stock: quantity };
        let indexOfExistedOrder = arrayOfProcessedOrders.findIndex(
            (el) => el.id === customOrder.id
        );
        let maxLocalAmount = maxAmount || 0;

        if (quantity > maxAmountAuto) {
            this.itemInfoDialog(noItemDialog);
            setTimeout(() => {
                this.setState({ quantity: 1 });
            }, 500);
            this.setState({ errorOpened: true });
            return;
        } else if (Number(quantity) === localAmountLeft || Number(quantity) === maxAmountAuto) {
            if (indexOfExistedOrder !== -1) {
                arrayOfProcessedOrders[indexOfExistedOrder].amount_in_stock =  Number(arrayOfProcessedOrders[indexOfExistedOrder].amount_in_stock) + Number(quantity);
            } else {
                arrayOfProcessedOrders.push(customOrder);
            }
            this.setState(({ arrayOfOrders, filteredArrayOfOrders, inputValue }) => {
                const copyArrayOfOrders = JSON.parse(JSON.stringify(arrayOfOrders));
                const indexSelectedOrder = copyArrayOfOrders.findIndex((item) => item.id === specificOrder[0].id)
                copyArrayOfOrders.splice(indexSelectedOrder, 1)
                const arrayWithSameArticle = copyArrayOfOrders.find((item) => item.article_number === inputValue) ? inputValue : '';
                
                return {
                    processedOrders: arrayOfProcessedOrders,
                    arrayOfOrders: arrayOfOrders.filter(
                        (order) => order !== specificOrder[0]
                    ),
                    filteredArrayOfOrders: filteredArrayOfOrders.filter(
                        (order) => order !== specificOrder[0]
                    ),
                    inputValue: arrayWithSameArticle,
                }
            });
        } else {
            customOrder["amount_left"] = maxAmountAuto - quantity;
            if (indexOfExistedOrder !== -1) {
                arrayOfProcessedOrders[indexOfExistedOrder].amount_in_stock = Number(arrayOfProcessedOrders[indexOfExistedOrder].amount_in_stock) + Number(quantity);
            } else {
                arrayOfProcessedOrders.push(customOrder);
            }
            this.setState(({ filteredArrayOfOrders }) => {
                let tempArrayOfOrders = filteredArrayOfOrders.map((el) => {
                    if (el.id === customOrder.id) {
                        return customOrder;
                    } else {
                        return el;
                    }
                });
                return {
                    processedOrders: arrayOfProcessedOrders,
                    filteredArrayOfOrders: tempArrayOfOrders,
                };
            });
        }
        this.itemInfoDialog(noItemDialog);
        setTimeout(() => {
            printButton.click();
            this.setState({ quantity: 1 });
        }, 500);
    };

    deleteOrder = () => {
        const {
            orderToDelete,
            arrayOfOrders,
            filteredArrayOfOrders,
        } = this.state;
        let arrayOfPrimalOrders = [...filteredArrayOfOrders];
        const indexOfExistedOrder = filteredArrayOfOrders.findIndex(
            (el) => el.id === orderToDelete.id
        );
        if (indexOfExistedOrder !== -1) {
            arrayOfPrimalOrders[indexOfExistedOrder]["amount_in_stock"] =
                arrayOfPrimalOrders[indexOfExistedOrder]["amount"];
            delete arrayOfPrimalOrders[indexOfExistedOrder]["amount_left"];
        } else {
            let tempOrderToDelete = { ...orderToDelete };
            delete tempOrderToDelete["amount_left"];
            arrayOfPrimalOrders = [tempOrderToDelete, ...arrayOfPrimalOrders];
        }

        this.setState(({ processedOrders }) => ({
            processedOrders: processedOrders.filter(
                (order) => order !== orderToDelete
            ),
            openDeleteDialog: false,
            filteredArrayOfOrders: arrayOfPrimalOrders,
        }));
    };

    handleEnter = (e) => {
        if (e && e.keyCode === 13) {
            this.handleOrder();
        }
    };

    handleCloseError = (e) => {
        if (e && e.keyCode === 13) {
            this.setState({ errorOpened: false });
        }
    };

    sendOrdersToRejectHandler = () => {
        const { sendOrdersToReject, changeTab } = this.props;
        const { arrayOfOrders } = this.state;

        sendOrdersToReject(arrayOfOrders).then((res) => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status >= 200 && res.payload.status < 300
            ) {
                this.setState({
                    arrayOfOrders: [],
                    loadingReject: false,
                    openRejectDialog: false,
                })
                changeTab(0)
                this.props.clearProcessed();
            } else {
                this.setState({
                    loadingReject: false,
                    openRejectDialog: false,
                    showSnackBar: true,
                })
            }
        });
    }

    toggleSnack = () => {
        this.setState({ showSnackBar: false });
    };

    render() {
        const {
            changeTab,
            goods_reception: { orders_history },
        } = this.props;
        const {
            openDeleteDialog,
            openNoItemDialog,
            openItemDialog,
            openItemInfoDialog,
            openRejectDialog,
            arrayOfOrders,
            filteredOrders,
            specificOrder,
            processedOrders,
            quantity,
            maxAmount,
            originalSum,
            inputValue,
            inStockCurrent,
            types_list,
            type,
            filteredArrayOfOrders,
            loading,
            loadingReject,
            showSnackBar,
            // anchorEl
        } = this.state;
        const hours = new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        });
        const day = new Date().toLocaleDateString();
        let searchedOrders =
            filteredArrayOfOrders && filteredArrayOfOrders.length > 0
                ? [
                      ...new Set(
                          filteredArrayOfOrders &&
                              filteredArrayOfOrders
                                  .filter((el) =>
                                      el.article_number
                                          .toString()
                                          .toLowerCase()
                                          .includes(
                                              inputValue
                                                  .toString()
                                                  .toLowerCase()
                                          )
                                  )
                                  .map((el) => el.article_number)
                      ),
                  ]
                : [];
        // const searchedOrders = [];
        return (
            <div className="product_selection">
                <div className="descriptions">
                    Чтобы добавить товары из накладной, воспользуйтесь поиском
                    по артикулу детали или сканером штрих-кодов.
                </div>
                <div
                    className="search_block custom-input"
                    style={{ display: "flex" }}
                >
                    <Autocomplete
                        id="products_popper"
                        options={searchedOrders}
                        value={inputValue}
                        onInputChange={(e, value) => this.searchChange(value)}
                        onChange={(e, value) => {
                            if (value) {
                                if (e.keyCode !== 13) {
                                    this.setState({ inputValue: value });
                                    setTimeout(() => {
                                        this.searchHandle();
                                    }, 0);
                                }
                            }
                        }}
                        noOptionsText="Совпадений не найдено"
                        renderInput={(params) => (
                            <div
                                ref={params.InputProps.ref}
                                style={{ position: "relative" }}
                            >
                                <input
                                    id="inputField"
                                    type="text"
                                    onKeyUp={(e) => this.onKeyUp(e)}
                                    autoComplete="off"
                                    {...params.inputProps}
                                />
                                <button onClick={() => this.searchHandle()}>
                                    <img src={SearchIcon} alt="SearchIcon" />
                                </button>
                            </div>
                        )}
                    />
                    <div style={{ marginLeft: "25px" }}>
                        <div
                            style={{
                                marginBottom: "5px",
                                color: "#7F8FA4",
                                fontSize: "12px",
                            }}
                        >
                            Поставщик
                        </div>
                        <FormControl
                            className="select_wrapper"
                            style={{ height: "45px" }}
                        >
                            <SelectComponent
                                value={type}
                                options={types_list}
                                change={this.handleChange("type")}
                                placeholder="Статус"
                                isSearchable={false}
                                isClearable={true}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="product_wrapper_table">
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className="table_left">
                            <div className="title_table">Товары</div>
                            {filteredArrayOfOrders.length === 0 ? (
                                <div className="no_items">
                                    Все товары из накладной обработаны.
                                </div>
                            ) : (
                                <div className="table_container transactions_columns  table_body_scroll">
                                    <div className="table_header">
                                        <div className="table_row">
                                            <div className="row_item"
                                                style={{ width: "15%" }}
                                            >
                                                Заказ
                                            </div>
                                            <div
                                                className="row_item"
                                                style={{ width: "30%" }}
                                            >
                                                Деталь
                                            </div>
                                            <div
                                                className="row_item"
                                                style={{ width: "30%" }}
                                            >
                                                Наименование
                                            </div>
                                            {/*{file_type === "1" && <div className="row_item">Комментарий</div>}*/}
                                            <div className="row_item"
                                                style={{ width: "15%" }}
                                            >
                                                Поставщик
                                            </div>
                                            <div className="row_item"
                                                style={{ width: "auto",  margin: '0 auto'}}
                                            >
                                                К-во товара
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table_body">
                                        {filteredArrayOfOrders &&
                                            filteredArrayOfOrders.map(
                                                (order, idOrder) => (
                                                    <div
                                                        className="table_row"
                                                        key={idOrder}
                                                    >
                                                        <div className="row_item"
                                                            style={{ width: "15%" }}
                                                        >
                                                            #{order.id}
                                                        </div>
                                                        <div
                                                            className="row_item"
                                                            style={{ width: "30%" }}
                                                        >
                                                            #
                                                            {
                                                                order.article_number
                                                            }{" "}
                                                            &nbsp;•&nbsp;{" "}
                                                            {order.producer}
                                                        </div>
                                                        <div className="row_item"
                                                            style={{ width: "30%" }}
                                                        >
                                                            {
                                                                order.product
                                                            }
                                                        </div>
                                                        <div className="row_item"
                                                            style={{ width: "15%" }}
                                                        >
                                                            {
                                                                order.order_company
                                                            }
                                                        </div>
                                                        <div className="row_item"
                                                            style={{ width: "10%", textAlign: 'center' }}
                                                        >
                                                            {order &&
                                                                (order.amount_left ||
                                                                    order.amount -
                                                                        order.in_stock -
                                                                        order.canceled -
                                                                        order.expelled)}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    <div className="line_v" />
                    <div className="table_right">
                        <div className="title_table">Обработанные товары</div>
                        {processedOrders.length === 0 ? (
                            <div className="no_items">Здесь пока пусто.</div>
                        ) : (
                            <div className="table_container transactions_columns table_body_scroll">
                                <div className="table_header">
                                    <div className="table_row">
                                        <div className="row_item">Заказ</div>
                                        <div
                                            className="row_item"
                                            style={{ width: "50%" }}
                                        >
                                            Деталь
                                        </div>
                                        {/*{file_type === "1" && <div className="row_item">Комментарий</div>}*/}
                                        <div className="row_item">
                                            К-во товара
                                        </div>
                                        <div className="row_item" />
                                    </div>
                                </div>
                                <div className="table_body">
                                    {processedOrders &&
                                        processedOrders.length &&
                                        processedOrders.map(
                                            (
                                                processedOrder,
                                                idProcessedOrder
                                            ) => (
                                                <div
                                                    className="table_row"
                                                    key={idProcessedOrder}
                                                >
                                                    <div className="row_item">
                                                        #{processedOrder.id}
                                                    </div>
                                                    <div
                                                        className="row_item"
                                                        style={{ width: "50%" }}
                                                    >
                                                        #
                                                        {
                                                            processedOrder.article_number
                                                        }{" "}
                                                        &nbsp;•&nbsp;{" "}
                                                        {
                                                            processedOrder.producer
                                                        }
                                                    </div>
                                                    {/*{file_type === "1" && <div className="row_item">*/}
                                                    {/*{processedOrder.comment !== '' || null}*/}
                                                    {/*{processedOrder.comment}*/}
                                                    {/*</div>}*/}
                                                    <div className="row_item">
                                                        {
                                                            processedOrder.amount_in_stock
                                                        }
                                                    </div>
                                                    <div className="row_item">
                                                        <button
                                                            onClick={() =>
                                                                this.deleteDialog(
                                                                    processedOrder
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    delete_items
                                                                }
                                                                alt="delete_items"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="btn_wrapper_product">
                    <DefaultButton
                        variant="outlined"
                        classes="cancel_btn"
                        onClick={() => {
                            changeTab(0);
                            this.props.clearProcessed();
                        }}
                    >
                        Назад
                    </DefaultButton>
                    <DefaultButton
                        disabled={processedOrders.length === 0}
                        onClick={() =>
                            changeTab(
                                4,
                                orders_history.upd_number,
                                orders_history.price,
                                processedOrders,
                                originalSum,
                                arrayOfOrders
                            )
                        }
                    >
                        Продолжить
                    </DefaultButton>
                    <DefaultButton
                        disabled={arrayOfOrders.length === 0}
                        variant="outlined"
                        classes="default_button_reject"
                        lastElemInGroup
                        onClick={() => this.setState({ openRejectDialog: true })}
                    >
                        Отправить в отказ
                    </DefaultButton>                    
                </div>
                <div className="no_display">
                    <PrintComponents
                        trigger={<button id="print_btn">Печать</button>}
                    >
                        <div className="to-print-sticker">
                            <div className='to-print-sticker__logo'></div>
                            <div className='to-print-sticker__main-info'>
                                <div className='to-print-sticker__left-block'>
                                    <div className='to-print-sticker__left-block-up'>
                                        {specificOrder[0] &&
                                            <p>{specificOrder[0].producer}</p>
                                        }
                                        {specificOrder[0]  &&
                                            <p className='to-print-sticker__left-block-up--article'>{specificOrder[0].article_number}</p>
                                        }                                               
                                    </div>
                                    <div className='to-print-sticker__left-block-bottom'>
                                    {/* штрих код */}
                                        {!!specificOrder[0] && (
                                            <Barcode
                                                value={
                                                    specificOrder[0].article_number
                                                }
                                                width={1}
                                                height={50}
                                                margin={0}
                                                displayValue={false}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className='to-print-sticker__center-block'>
                                    <div className='to-print-sticker__center-block-up'>
                                        <p className='to-print-sticker__center-block-up--amount'>{quantity}&nbsp;<span>шт.</span></p>
                                    </div>
                                    <div className='to-print-sticker__center-block-bottom'>
                                        <p>{day}</p>
                                        <p>{hours}</p>
                                        <p>{specificOrder[0] && specificOrder[0].id}</p>
                                    </div>
                                </div>
                                <div className='to-print-sticker__right-block'>
                                    «{specificOrder[0] && specificOrder[0].company}»
                                </div>
                            </div>
                        </div>
                    </PrintComponents>                    
                </div>

                <DialogComponent
                    open={openDeleteDialog}
                    onClose={this.deleteDialog}
                >
                    <div className="title-dialog">Удалить товар</div>
                    <div className="descriptions_dialog_component no_margin">
                        Вы уверены, что хотите удалить данный товар из списка?
                    </div>
                    <div className="btn-wrapper order-dialog-btn">
                        <DefaultButton
                            variant="outlined"
                            classes="cancel_btn"
                            onClick={this.deleteDialog}
                        >
                            Отмена
                        </DefaultButton>
                        <DefaultButton onClick={this.deleteOrder}>
                            Удалить
                        </DefaultButton>
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button",
                        }}
                        onClick={this.deleteDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon" />
                    </IconButton>
                </DialogComponent>

                <DialogComponent
                    open={openNoItemDialog}
                    onClose={this.noItemDialog}
                >
                    <div className="title-dialog">Совпадений не найдено</div>
                    <div className="descriptions_dialog_component no_margin">
                        Убедитесь в правильности поискового запроса и повторите
                        попытку.
                    </div>
                    <div className="btn-wrapper order-dialog-btn">
                        <DefaultButton onClick={this.noItemDialog}>
                            Ок
                        </DefaultButton>
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button",
                        }}
                        onClick={this.noItemDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon" />
                    </IconButton>
                </DialogComponent>

                <DialogComponent
                    open={openItemDialog}
                    onClose={this.itemDialog}
                >
                    <div className="title-dialog no_padding_bottom">
                        Найдены совпадения
                    </div>
                    <div className="descriptions_dialog_component">
                        Выберите нужный товар, чтобы продолжить.
                    </div>
                    <div className="table_dialog_items">
                        <div className="table_container transactions_columns">
                            <div className="table_body">
                                {filteredOrders.map(
                                    (filteredOrder, idFilteredOrder) => (
                                        <div
                                            className="table_row"
                                            onClick={() =>
                                                this.selectSpecificOrder(
                                                    filteredOrder
                                                )
                                            }
                                            key={idFilteredOrder}
                                        >
                                            <div
                                                className="row_item"
                                                style={{ width: "11%" }}
                                            >
                                                <div className="name_items">
                                                    {filteredOrder.id}
                                                </div>
                                            </div>
                                            <div
                                                className="row_item"
                                                style={{ width: "22%" }}
                                            >
                                                <div className="block_table">
                                                    <span className="title_table">
                                                        Поставщик
                                                    </span>
                                                    <span className="info_item">
                                                        {
                                                            filteredOrder.order_company
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_table">
                                                    <span className="title_table">
                                                        Производитель
                                                    </span>
                                                    <span className="info_item">
                                                        {filteredOrder.producer}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_table">
                                                    <span className="title_table">
                                                        Количество
                                                    </span>
                                                    <span className="info_item">
                                                        {filteredOrder &&
                                                            (filteredOrder.amount_left ||
                                                                filteredOrder.amount -
                                                                    filteredOrder.in_stock -
                                                                    filteredOrder.canceled -
                                                                    filteredOrder.expelled)}{" "}
                                                        шт.
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_table">
                                                    <span className="title_table">
                                                        Заказ
                                                    </span>
                                                    <span className="info_item">
                                                        #{filteredOrder.id}
                                                    </span>
                                                </div>
                                            </div>
                                            {/*{file_type === "1" && <div className="row_item">*/}
                                            {/*<div className="block_table">*/}
                                            {/*<span className="title_table">Комментарий</span>*/}
                                            {/*<span className="info_item">*/}
                                            {/*{filteredOrder.comment !== '' || null }*/}
                                            {/*{filteredOrder.comment}*/}
                                            {/*</span>*/}
                                            {/*</div>*/}
                                            {/*</div>}*/}
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button",
                        }}
                        onClick={this.itemDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon" />
                    </IconButton>
                </DialogComponent>
                <DialogComponent
                    open={openItemInfoDialog}
                    onClose={this.itemInfoDialog}
                >
                    <div
                        id="quantity_dialog"
                        onKeyPress={(e) => this.handleEnter()}
                        tabIndex="0"
                    >
                        <div className="title-dialog">Количество товара</div>
                        <div className="product_wrapper">
                            <div className="table_dialog_items">
                                <div className="table_container transactions_columns">
                                    <div className="table_body">
                                        <div className="table_row">
                                            <div
                                                className="row_item"
                                                style={{ width: "33%" }}
                                            >
                                                <div className="name_items">
                                                    {specificOrder[0] &&
                                                        specificOrder[0]
                                                            .article_number}
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_table">
                                                    <span className="title_table">
                                                        Производитель
                                                    </span>
                                                    <span className="info_item">
                                                        {specificOrder[0] &&
                                                            specificOrder[0]
                                                                .producer}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_table">
                                                    <span className="title_table">
                                                        Количество
                                                    </span>
                                                    <span className="info_item">
                                                        {specificOrder[0] &&
                                                            (specificOrder[0]
                                                                .amount_left ||
                                                                specificOrder[0]
                                                                    .amount -
                                                                    specificOrder[0]
                                                                        .in_stock -
                                                                    specificOrder[0]
                                                                        .canceled -
                                                                    specificOrder[0]
                                                                        .expelled)}{" "}
                                                        шт.
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_table">
                                                    <span className="title_table">
                                                        Компания
                                                    </span>
                                                    <span className="info_item">
                                                        {specificOrder[0] &&
                                                            specificOrder[0]
                                                                .company}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_table">
                                                    <span className="title_table">
                                                        Заказ
                                                    </span>
                                                    <span className="info_item">
                                                        {specificOrder[0] &&
                                                            specificOrder[0].id}
                                                    </span>
                                                </div>
                                            </div>
                                            {/*{file_type === "1" && <div className="row_item">*/}
                                            {/*<div className="block_table">*/}
                                            {/*<span className="title_table">Комментарий</span>*/}
                                            {/*<span className="info_item">*/}
                                            {/*{specificOrder[0] && specificOrder[0].comment !== '' || null}*/}
                                            {/*{specificOrder[0] && specificOrder[0].comment}*/}
                                            {/*</span>*/}
                                            {/*</div>*/}
                                            {/*</div>}*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="descriptions_dialog_component">
                            Укажите количество единиц данного товара.
                        </div>
                        <div className="amount_wrapper">
                            <span>Количество</span>
                            <div className="amount">
                                <button
                                    style={{
                                        opacity: quantity <= 1 ? "0.3" : "1",
                                    }}
                                    onClick={() => this.changeAmount("-")}
                                >
                                    <img src={MinusIcon} alt="-" />
                                </button>
                                <input
                                    type="number"
                                    value={quantity}
                                    onChange={(e) => this.setState({quantity: e.target.value})}
                                    onBlur={(e) => this.setState(({quantity, maxAmount}) => {
                                        let correctQuantity = 1;
                                        if (quantity < 1 || quantity === null) {
                                            correctQuantity = 1;
                                        } else if (quantity > maxAmount) {
                                            correctQuantity = maxAmount
                                        } else {
                                            correctQuantity = quantity
                                        }
                                        return {
                                            quantity: correctQuantity
                                        }
                                    })}
                                    min='0'
                                    max={maxAmount}
                                />
                                <button
                                    style={{
                                        opacity:
                                            quantity >= maxAmount
                                                ? "0.3"
                                                : "1",
                                    }}
                                    onClick={() => this.changeAmount("+")}
                                >
                                    <img src={PlusIcon} alt="+" />
                                </button>
                            </div>
                        </div>
                        <div className="btn-wrapper order-dialog-btn">
                            <DefaultButton
                                variant="outlined"
                                classes="cancel_btn"
                                onClick={this.itemInfoDialog}
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton onClick={this.handleOrder}>
                                Подтвердить
                            </DefaultButton>
                        </div>
                        <IconButton
                            classes={{
                                root: "dialog_close_button",
                            }}
                            onClick={this.itemInfoDialog}
                        >
                            <img src={CloseDialogIcon} alt="close icon" />
                        </IconButton>
                    </div>
                </DialogComponent>
                <DialogComponent
                    open={this.state.errorOpened}
                    onClose={() => this.setState({ errorOpened: false })}
                >
                    <div
                        className="title-dialog"
                        style={{ paddingBottom: "10px" }}
                    >
                        Ошибка
                    </div>
                    <div
                        id="quantity_dialog"
                        onKeyPress={(e) => this.handleCloseError()}
                        tabIndex="0"
                    >
                        <div
                            className="descriptions_dialog_component"
                            style={{ fontSize: "16px" }}
                        >
                            Превышено допустимое количество товаров для данного
                            заказа.{" "}
                        </div>

                        <div className="btn-wrapper order-dialog-btn">
                            <DefaultButton
                                onClick={() =>
                                    this.setState({ errorOpened: false })
                                }
                            >
                                OK
                            </DefaultButton>
                        </div>
                        <IconButton
                            classes={{
                                root: "dialog_close_button",
                            }}
                            onClick={() =>
                                this.setState({ errorOpened: false })
                            }
                        >
                            <img src={CloseDialogIcon} alt="close icon" />
                        </IconButton>
                    </div>
                </DialogComponent>
                <DialogComponent
                    open={openRejectDialog}
                    onClose={() => this.setState({ openRejectDialog: false })}
                >
                    <div
                        className="title-dialog"
                        style={{ paddingBottom: "10px" }}
                    >
                        Отправить в отказ
                    </div>
                    <div className="descriptions_dialog_component">
                        Вы уверены, что хотите отправить все товары в отказ?
                    </div>
                    <div className="btn-wrapper order-dialog-btn more-dialog-btn">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                classes="cancel_btn"
                                onClick={() => this.setState({ openRejectDialog: false })}
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                onClick={() => {
                                    this.setState({ loadingReject: true });
                                    this.sendOrdersToRejectHandler();
                                }}
                                variant="outlined"
                                classes="default_button_reject"
                                loading={loadingReject}
                                disabled={loadingReject}
                            >
                                Отправить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <TransitionedBlock>
                    <SnackBar
                        open={showSnackBar}
                        classes="error"
                        vertical="top"
                        onClose={() => this.toggleSnack()}
                    >
                        Произошла ошибка
                    </SnackBar>
                </TransitionedBlock>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        goods_reception: state.goods_reception,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getMexcarAuto,
            getSuppliers,
            getSuppliersAuto,
            sendOrdersToReject,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductSelection);
