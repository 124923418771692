import React, {Component} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from "../../HelperComponents/Loader/Loader";
import SnackBar from "../../HelperComponents/SnackBar/SnackBar";
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock"

import { getWaitingReceptionGoods, sendWaitingReceptionGoods } from "../../../actions/goodsReceptionActions";

import './WaitingReceptionGoods.scss';

class WaitingReceptionGoods extends Component {
    state = {
        loading: true,
        showSnackBar: false,
    };

    componentDidMount = () => {
        this.doRequest();
    };

    doRequest = () => {
        const { getWaitingReceptionGoods } = this.props;

        getWaitingReceptionGoods().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ loading: false });
            }
        })
    };

    nextStepHandler = async (id) => {
        const { sendWaitingReceptionGoods, changeTab } = this.props;
        sendWaitingReceptionGoods({id}).then((res) => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                changeTab(1);
            } else {
                this.setState({
                    showSnackBar: true,
                })
            }
        });
    }

    toggleSnack = () => {
        this.setState({ showSnackBar: false });
    };

    render(){
        const { loading, showSnackBar } = this.state;
        const { waitingReceptionGoods } = this.props;

        if (loading) return <Loader/>;

        return (
            <div className="waitnig_reception_block container">
                <div className="waitnig_reception ">
                    <div className="title">Ожидают приёма</div>
                    {waitingReceptionGoods.length ? (
                        <div className="waitnig_reception_table">
                            <div className="table_container transactions_columns">
                                <div className="table_header">
                                    <div className="table_row">
                                        <div className="row_item">Поставщик</div>
                                        <div className="row_item">Номер УПД</div>
                                        <div className="row_item">Сумма</div>
                                    </div>
                                </div>
                                <div className="table_body">
                                    {waitingReceptionGoods.map(item => (
                                        <div className="table_row" onClick={() => this.nextStepHandler(item.id)} key={item.id}>
                                            <div className="row_item">{item.provider}</div>
                                            <div className="row_item">{item.number}</div>
                                            <div className="row_item">{item.total_price} ₽</div>
                                        </div>
                                    ))}
                                </div>                  
                            </div>
                        </div>
                    ) : (
                        <p>Ожидающих приёмки нет</p>
                    )
                }                            
                </div>
                <TransitionedBlock>
                    <SnackBar
                        open={showSnackBar}
                        classes="error"
                        vertical="top"
                        onClose={() => this.toggleSnack()}
                    >
                        Произошла ошибка
                    </SnackBar>
                </TransitionedBlock>                
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        waitingReceptionGoods: state.goods_reception.waitingReceptionGoods
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getWaitingReceptionGoods,
        sendWaitingReceptionGoods,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitingReceptionGoods);
