import * as types from './constants.jsx';


export function searchCompanies() {
    return {
        type: types.SEARCH_COMPANY,
        payload: {
            client: 'default',
            request: {
                url: `/admin/japarts/residues-search/`,
                method: "get"
            }
        }
    };
}

export function getCompaniesGoods(id) {
    return {
        type: types.GET_COMPANIES_GOODS,
        payload: {
            client: 'default',
            request: {
                url: `/admin/japarts/residues-products/?producer_id=${id}`,
                method: "get"
            }
        }
    };
}

export function postNewResidue(data) {
    return {
        type: types.POST_NEW_RESIDUE,
        payload: {
            client: 'default',
            request: {
                url: `/admin/japarts/residues/`,
                method: "post",
                data
            }
        }
    };
}
export function getAllResidues() {
    return {
        type: types.GET_ALL_RESIDUES,
        payload: {
            client: 'default',
            request: {
                url: `/admin/japarts/residues/`,
                method: "get"
            }
        }
    };
}
