import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ErrorIcon from "@material-ui/icons/Error";
import FormControl from "@material-ui/core/FormControl";

import ButtonLoader from "../../Buttons/ButtonLoader/ButtonLoader";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage";
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";

import { getFileTypeList } from '../../../actions/goodsReceptionActions';

import { postInvoiceFile } from "../../../actions/goodsReceptionActions";

import "./DownloadFile.scss";

class DownloadFile extends Component {
    state = {
        fileError: false,
        file: null,
        blocker: true,
        loading: false,
        // file_type: { label: "Japarts", value: "1" },
        // types_list: [
        //     { label: "Микадо", value: "2" },
        //     { label: "ArmTek", value: "3" },
        //     { label: "Rossko", value: "5" },
        //     { label: "АвтоРусь", value: "4" },
        //     { label: "Japarts", value: "1" },
        //     { label: "Mexcar", value: "6" },
        // ],
        file_type: {},
        types_list: [],
    };

    componentDidMount() {
        const { getFileTypeList } = this.props;
        getFileTypeList().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                let typeList = res.payload.data.map(item => {
                    return {label: item.name, value: item.id}
                })
                this.setState({types_list: typeList, file_type: typeList[0]})
            }
            // if (res.error && res.error.response.status === 401) {
            //     localStorage.clear();
            //     history.push('/auth');
            // }
        });
    }

    handleInputFile = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        if (file) {
            this.setState({
                fileError: false,
                file: file,
                blocker: false,
            });
            // this.downloadFile(file);
        } else {
            this.setState({
                fileError: false,
                file: null,
                blocker: true,
            });
        }
    };

    downloadFile = () => {
        const { postInvoiceFile, changeTab } = this.props;
        const { file_type, file } = this.state;
        this.setState({ loading: true });
        if (!!file) {
            let formData = new FormData();
            formData.append("file", file);
            formData.append("file_type", file_type.value);
            postInvoiceFile(formData).then((res) => {
                if (
                    res.payload &&
                    res.payload.status &&
                    res.payload.status === 200
                ) {
                    this.setState({
                        fileError: false,
                        blocker: false,
                        loading: false,
                    });
                    changeTab(1);
                } else {
                    this.setState({
                        // file: null,
                        fileError: true,
                        loading: false,
                    });
                }
            });
        }
    };

    selectFile = () => {
        let el = document.getElementById("file");
        el.click();
    };

    handleChange = (name) => (event) => {
        this.setState({ [name]: event });
    };

    render() {
        const {
            blocker,
            fileError,
            file,
            loading,
            file_type,
            types_list,
        } = this.state;
        const { goods_reception, changeTab } = this.props;
        localStorage.setItem("file_type", file_type.value);
        return (
            <div className="download_file">
                <div className="text-info">
                    Чтобы начать сеанс приёма товара, загрузите Excel-файл
                    накладной, или воспользуйтесь{" "}
                    <div className="mexcar-auto" onClick={() => changeTab(3)}>
                        Mexcar Auto
                    </div>
                    .
                </div>
                <div className="download-file">
                    <FormControl className="select_wrapper">
                        <SelectComponent
                            value={file_type}
                            options={types_list}
                            change={this.handleChange("file_type")}
                            placeholder="Выберите тип"
                            isSearchable={false}
                            isClearable={true}
                        />
                    </FormControl>
                    <div className="download">
                        {file ? file.name : "Выберите файл"}
                    </div>
                    <input
                        id="file"
                        type="file"
                        onChange={(e) => this.handleInputFile(e)}
                    />
                    <DefaultButton
                        variant="contained"
                        onClick={this.selectFile}
                    >
                        Загрузить
                    </DefaultButton>
                    <div className="download-file_error flex-center">
                        {fileError ? (
                            <TooltipMessage
                                text={goods_reception.error}
                                delay={200}
                                error
                                position="right"
                                classes=""
                            >
                                <ErrorIcon />
                            </TooltipMessage>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div className="btn-wrapper">
                    <DefaultButton
                        variant="contained"
                        disabled={blocker}
                        onClick={this.downloadFile}
                    >
                        {loading ? <ButtonLoader /> : "Продолжить"}
                    </DefaultButton>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        goods_reception: state.goods_reception,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            postInvoiceFile,
            getFileTypeList,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DownloadFile);
