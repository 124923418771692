import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";
import Pagination from "../HelperComponents/Pagination/Pagination";
import PanelPage from "../HelperComponents/PanelPage/PanelPage";
import NoItems from "../HelperComponents/NoItems/NoItems";
import SnackBar from "../HelperComponents/SnackBar/SnackBar"
import { isArray } from "../../helpers/functions";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import {
    splitByCommas,
} from "../../helpers/functions";

import { getOrders, patchStatusOrder } from "../../actions/ordersActions";
import { getProviders } from "../../actions/providersActions";

import OrdersIcon from "../../assets/image/no_orders.png";

import "./OrdersPage.scss";

class OrdersPage extends Component {
    state = {
        activePage: 1,
        pageSize: 10,
        valueSearch: "",
        loading: true,
        status: null,
        headTables: ['Дата/время', 'Заказ', 'Компания', 'Артикул', 'Производитель', 'Название', 'Всего', 'Выдано', 'На складе', 'Отменено', 'Срок поставки', 'Поставщик', 'Ориг. цена', 'Сумма', 'Статус'],
        projects_list: [
            { label: "В работе", value: "processing" },
            { label: "В пути", value: "on_way" },
            { label: "Подтверждено", value: "confirmed" },
            { label: "Выполнен", value: "complete" },
            { label: "Нет оплаты", value: "no_payment" },
            { label: "Отказ", value: "denied" },
        ],
        mexcar: { label: "Показать все", value: false },
        mexcar_list: [
            { label: "Показать все", value: '' },
            { label: "Только mexcar", value: true },
            { label: "Только поставщиков", value: false },
        ],
        provider: null,
        provider_list: [],
        orderingDate: null,
        ordering_list: [
            { label: "По возрастанию", value: 'days_to_delivery' },
            { label: "По убыванию", value: '-days_to_delivery' },
        ],
        myOrders: [],
        showSnackBar: false,
        classSnackBar: '',
        textSnackBar: '',
    };

    componentDidMount = () => {
        this.doRequest();
        this.getProviderList();
    };

    toggleSnack = () => {
        this.setState({ showSnackBar: false });
    };

    doRequest = () => {
        const { getOrders } = this.props;
        const { valueSearch, activePage, pageSize, status, orderingDate, provider } = this.state;

        let arr = [];
        if (!!status && status !== null) {
            arr.push(`status=${status.value}`);
        }
        arr.push(`mexcar_created=false`);
        if (orderingDate && orderingDate !== null) {
            arr.push(`ordering=${orderingDate.value}`);
        }
        if (valueSearch !== "") {
            arr.push(`query=${valueSearch}`);
        }
        if (provider !== null) {
            arr.push(`provider=${provider.value}`);
        }
        arr.push(`page_size=${pageSize}`);
        arr.push(`page=${activePage}`);

        getOrders(arr).then((res) => {
            if (res.payload && res.payload.status) {
                this.setState({ loading: false, myOrders: res.payload.data });
            } else {
                this.setState({ loading: false, activePage: 1 });
                this.doRequest()
            }
        });
    };

    getProviderList() {
        const { getProviders } = this.props;
        getProviders().then((res) => {
            if (res.payload && res.payload.status) {
                const providers = [];
                res.payload.data.map((item) => {
                    providers.push({label: item.name, value: item.name})
                })
                this.setState({ provider_list: providers });
            } else {
                this.setState({ provider_list: [] });
            }
        })
    }

    handleChange = (name, event) => {
        this.setState({ [name]: event });
        setTimeout(() => {
            this.doRequest();
        }, 0);
    };

    changeValueSearch = (e) => {
        this.setState({ valueSearch: e.target.value });
    };

    handleSearch = (e) => {
        if (e.keyCode === 13) {
            this.setState({ activePage: 1 });
            setTimeout(() => {
                this.doRequest();
            }, 0);
        } else if (e === "iconClick") {
            setTimeout(() => {
                this.doRequest();
            }, 0);
        }
    };

    changePage = (pageNumber) => {
        this.setState({ activePage: pageNumber.selected + 1 });
        setTimeout(() => {
            this.doRequest();
        }, 0);
    };

    changePageSize = (pageSize) => {
        this.setState({ pageSize: pageSize });
        setTimeout(() => {
            this.doRequest();
        }, 0);
    }

    choiseItem = (status) => {
        const { projects_list } = this.state;
        const choiseStatus = projects_list.find(item => item.value === status);
        return choiseStatus;
    }

    inputAmountCalceledHandler(event, orderId) {
        const { myOrders } = this.state;
        const myOrdersCopy = JSON.parse(JSON.stringify(myOrders));
        myOrdersCopy.results.find(order => order.id === orderId).canceled = Number(event.target.value)
        this.setState({myOrders: myOrdersCopy});
    }

    changeOrderHandler(event, order, input = false) {
        const { myOrders } = this.state;
        const { patchStatusOrder } = this.props;
        const myOrdersCopy = JSON.parse(JSON.stringify(myOrders));
        const choiseOrder = myOrdersCopy.results.find(orderItem => orderItem.id === order.id)
        if (!input) {
            choiseOrder.status = event.value
        }
        if (input) {
            choiseOrder.canceled = Number(event.target.value)
        }
        const data = {
            "status": choiseOrder.status,
            "canceled": Number(choiseOrder.canceled)  
        }
        this.setState({myOrders: myOrdersCopy});
        patchStatusOrder(order.id, data).then(res => {
            setTimeout(() => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    this.setState({ showSnackBar: true, classSnackBar: 'success', textSnackBar: 'Данные успешно изменены' });
                } else {
                    this.setState({ showSnackBar: true, classSnackBar: 'error', textSnackBar: 'Количество деталей не должно превышать общее' });
                }
            }, 100)
        });
    }

    render() {
        const {
            orders: { all_orders },
        } = this.props;
        const {
            activePage,
            pageSize,
            loading,
            valueSearch,
            status,
            headTables,
            projects_list,
            orderingDate,
            ordering_list,
            myOrders,
            showSnackBar,
            classSnackBar,
            textSnackBar,
            provider,
            provider_list,
        } = this.state;
        if (loading) return null;

        return (
            <TransitionedBlock>
                <TransitionedBlock>
                    <SnackBar
                        open={showSnackBar}
                        classes={classSnackBar}
                        vertical="top"
                        onClose={() => this.toggleSnack()}
                    >
                        {textSnackBar}
                    </SnackBar>
                </TransitionedBlock>
                <div className="orders_page container">
                    <div className="flex-center-btw title-block-page">
                        <div className="title-page">
                            <h1>Заказы</h1>
                        </div>
                    </div>
                    <div className="page_block">
                        <PanelPage
                            ordersPage={true}
                            doRequest={this.doRequest}
                            changeValueSearch={this.changeValueSearch}
                            handleSearch={this.handleSearch}
                            handleChange={this.handleChange}
                            valueSeacrh={valueSearch}
                            status={status}
                            orderingDate={orderingDate}
                            projects_list={projects_list}
                            ordering_list={ordering_list}
                            provider={provider}
                            provider_list={provider_list}
                        />
                        <div className="orders_table_wrapper">
                            <div className="transactions_table">
                                <div className="table_container transactions_columns">
                                    <div className="table_header">
                                        <div className="table_row">
                                            {headTables.map((rowItem, idx) => (
                                                <div className="row_item" key={idx}>
                                                    {rowItem}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="table_body">
                                        {!!myOrders &&
                                        myOrders.results &&
                                        myOrders.results.length !== 0 ? (
                                            myOrders.results.map(
                                                (order, idOrder) => (
                                                    <div
                                                        className="table_row link-orders"
                                                        key={idOrder}
                                                    >
                                                        <div className="row_item">
                                                            {moment(
                                                                order.date
                                                            ).format(
                                                                "DD.MM.YYYY HH:mm"
                                                            )}
                                                        </div>
                                                        <div className="row_item">
                                                            {order.id}
                                                        </div>
                                                        <div className="row_item">
                                                            {order.company}
                                                        </div>
                                                        <div className="row_item">
                                                            {order.article_number}
                                                        </div>
                                                        <div className="row_item">
                                                            {order.producer}
                                                        </div>
                                                        <div className="row_item">
                                                            {order.name}
                                                        </div>
                                                        <div className="row_item">
                                                            {order.amount}
                                                        </div>
                                                        <div className="row_item">
                                                            {!!order.expelled
                                                                ? order.expelled
                                                                : "0"}
                                                        </div>
                                                        <div className="row_item">
                                                            {!!order.in_stock
                                                                ? order.in_stock
                                                                : "0"}
                                                        </div>
                                                        <div className="row_item">
                                                            <div className="amount">
                                                                <input
                                                                    type="number"
                                                                    value={order.canceled}
                                                                    min={0}
                                                                    onInput={(e) => this.inputAmountCalceledHandler(e, order.id)}
                                                                    onBlur={(e) => this.changeOrderHandler(e, order, true)}
                                                                /> 
                                                            </div>
                                                        </div>
                                                        <div className="row_item" 
                                                            style={{color: !!order.days_to_delivery && order.days_to_delivery < 0 ? '#D0021B' : '#334150'}}
                                                        >
                                                            {order.days_to_delivery !== null
                                                                ? order.days_to_delivery
                                                                : '-'
                                                            }
                                                        </div>
                                                        <div className="row_item">
                                                            {order.provider !== null
                                                                ? order.provider
                                                                : '-'
                                                            }
                                                        </div>
                                                        <div className="row_item">
                                                            {order.original_price} ₽
                                                        </div>
                                                        <div className="row_item">
                                                            {order.price
                                                                ? splitByCommas(
                                                                      order.price
                                                                  )
                                                                : 0} ₽
                                                        </div>
                                                        <div className="row_item-1">
                                                            <FormControl className="select_wrapper">
                                                                <SelectComponent
                                                                    value={this.choiseItem(order.status)}
                                                                    options={projects_list}
                                                                    loading={!isArray(projects_list)}
                                                                    isClearable={true}
                                                                    isSearchable={false}
                                                                    change={(event) => this.changeOrderHandler(event, order)}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        ) : (
                                            <NoItems>
                                                <div className="box-img">
                                                    <img
                                                        src={OrdersIcon}
                                                        alt="edit icon"
                                                    />
                                                </div>
                                                <div className="title">
                                                    Пока заказов нет
                                                </div>
                                                <div className="descriptions">
                                                    ... но после их оформления
                                                    они обязательно здесь
                                                    появятся!
                                                </div>
                                            </NoItems>
                                        )}
                                    </div>
                                </div>
                                <div className='control_panel'>
                                    {all_orders.count && all_orders.count > pageSize ? (
                                        <Pagination
                                            active={activePage}
                                            pageItemsCount={pageSize}
                                            pageTotalCount={all_orders.count}
                                            onChange={this.changePage}
                                        />
                                    ) : null}
                                    <form className='control_panel--page-size' onChange={(event) => this.changePageSize(event.target.value)}>
                                        <div className='radio-wrapper'>
                                            <input type="radio" id="records-10" name="records-per-page" defaultChecked value="10" />
                                            <label htmlFor="records-10">10</label>
                                        </div>
                                        <div className='radio-wrapper'>
                                            <input type="radio" id="records-50" name="records-per-page" value="50" />
                                            <label htmlFor="records-50">50</label>
                                        </div>
                                        <div className='radio-wrapper'>
                                            <input type="radio" id="records-100" name="records-per-page" value="100" />
                                            <label htmlFor="records-100">100</label>
                                        </div>
                                        <div className='radio-wrapper'>
                                            <input type="radio" id="records-500" name="records-per-page" value="500" />
                                            <label htmlFor="records-500">500</label>
                                        </div>
                                        <div className='radio-wrapper'>
                                            <input type="radio" id="records-1000" name="records-per-page" value="1000" />
                                            <label htmlFor="records-1000">1000</label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </TransitionedBlock> 
        );
    }
}

const mapStateToProps = ({ orders }) => ({
    orders,
});

const mapDispatchToProps = {
    getOrders,
    patchStatusOrder,
    getProviders,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrdersPage);
