import * as types from './constants.jsx';

//providers actions

export function getProviders() {
    return {
        type: types.GET_PROVIDERS,
        payload: {
            client: 'default',
            request: {
                url: `/admin/japarts/providers/`,
                method: "get"
            }
        }
    };
}