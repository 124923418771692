import React, { Component } from "react";
import DownloadFile from "./DownloadFile/DownloadFile";
import ReceptionHistory from "./ReceptionHistory/ReceptionHistory";
import ProductSelection from "./ProductSelection/ProductSelection";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import Summary from "./Summary/Summary";
import SummaryAuto from "./Summary/SummaryAuto";
import WaitingReceptionGoods from './WaitingReceptionGoods/WaitingReceptionGoods'

import ProductSelectionAuto from "./ProductSelectionAuto/ProductSelectionAuto";
import "./GoodsReception.scss";

class GoodsReception extends Component {
  state = {
    tab: 0,
    processedOrders: [],
    arrayOfOrders: [],
    upd_number: null,
    sum: null,
    originalSum: null,
    supplier: null,
    upd_date: null,
    supplier_inn: null,
  };

  changeTab = (
    tab,
    upd_number = null,
    sum = null,
    processedOrders = null,
    originalSum = null,
    arrayOfOrders = null,
    supplier = null,
    upd_date = null,
    supplier_inn = null,
  ) => {
    this.setState({ tab });
    if (processedOrders !== null) {
      this.setState({ processedOrders });
    }
    if (arrayOfOrders !== null) {
      this.setState({ arrayOfOrders });
    }
    if (!!upd_number) {
      this.setState({ upd_number });
    }
    if (!!sum) {
      this.setState({ sum });
    }
    if (!!originalSum) {
      this.setState({ originalSum });
    }
    if (!!supplier) {
      this.setState({ supplier });
    }
    if (!!upd_date) {
      this.setState({ upd_date });
    }
    if (!!supplier_inn) {
      this.setState({ supplier_inn });
    }
  };

  render() {
    const {
      tab,
      processedOrders,
      originalSum,
      upd_number,
      sum,
      arrayOfOrders,
      supplier,
      upd_date,
      supplier_inn,
    } = this.state;
    
    return (
      <TransitionedBlock>
        <div>
          <div className="good_reception container">
            <div className="flex-center-btw title-block-page">
              <div className="title-page">
                <h1>Приём товара</h1>
              </div>
            </div>
            <div className="price-add">
              <div className="tab-button">
                <button className={`${tab === 0 ? "active" : ""}`}>
                  1. Загрузка файла
                </button>
                <button className={`${tab === 1 || tab === 3 ? "active" : ""}`}>
                  2. Выбор товара
                </button>
                <button className={`${tab === 2 || tab === 4 ? "active" : ""}`}>
                  3. Итог
                </button>
              </div>

              {tab === 0 && <DownloadFile changeTab={this.changeTab} />}
              {tab === 1 && (
                <ProductSelection
                  changeTab={this.changeTab}
                  processedOrders={processedOrders}
                  arrayOfOrders={arrayOfOrders}
                  clearProcessed={() =>
                    this.setState({
                      processedOrders: [],
                      arrayOfOrders: [],
                    })
                  }
                />
              )}
              {tab === 2 && (
                <Summary
                  changeTab={this.changeTab}
                  processedOrders={processedOrders}
                  arrayOfOrders={arrayOfOrders}
                  originalSum={originalSum}
                  upd_number={upd_number}
                  supplier={supplier}
                  upd_date={upd_date}
                  supplier_inn={supplier_inn}
                  sum={sum}
                  clearProcessed={() =>
                    this.setState({
                      processedOrders: [],
                      arrayOfOrders: [],
                    })
                  }
                />
              )}
              {tab === 3 && (
                <ProductSelectionAuto
                  changeTab={this.changeTab}
                  processedOrders={processedOrders}
                  arrayOfOrders={arrayOfOrders}
                  clearProcessed={() =>
                    this.setState({
                      processedOrders: [],
                      arrayOfOrders: [],
                    })
                  }
                />
              )}
              {tab === 4 && (
                <SummaryAuto
                  changeTab={this.changeTab}
                  processedOrders={processedOrders}
                  originalSum={originalSum}
                  upd_number={upd_number}
                  sum={sum}
                  clearProcessed={() =>
                    this.setState({
                      processedOrders: [],
                      arrayOfOrders: [],
                    })
                  }
                />
              )}
            </div>
          </div>
          {tab === 0 && <WaitingReceptionGoods changeTab={this.changeTab} />}
          {tab === 0 && <ReceptionHistory changeTab={this.changeTab} />}
        </div>
      </TransitionedBlock>
    );
  }
}

export default GoodsReception;
