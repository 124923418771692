import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pagination from '../HelperComponents/Pagination/Pagination';
import PanelPage from '../HelperComponents/PanelPage/PanelPage';
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import SnackBar from "../HelperComponents/SnackBar/SnackBar";

import { getStatusRefundRequest, getStatusColor, splitByCommas } from "../../helpers/functions";
import { getRefundRequests } from "../../actions/refundRequestsActions";
import { getProviders } from "../../actions/providersActions";

import './RefundRequests.scss';
import ControlPanel from '../HelperComponents/ControlPanel/ControlPanel';

class RefundRequests extends Component {

    state = {
        loading: true,
        activePage: 1,
        pageSize: 10,
        valueSearch: '',
        status: null,
        projects_list: [
            { label: "На рассмотрении", value: "processing" },
            { label: "Одобрена", value: "complete" },
            { label: "Отказ", value: "denied" },
            { label: "Ожидаем документы", value: "waiting_for_documents" },
            { label: "Ожидаем деталь", value: "waiting_for_details" },
        ],
        provider: null,
        provider_list: [],
        snackBarAttr: {
            isVisible: false,
            message: '',
        }
    };

    componentDidMount = () => {
        this.doRequest();
        this.getProviderList();
        const { location } = this.props;
        if (location.state && location.state.isVsibleSnackBar) {
            this.setState({
                snackBarAttr: {
                  isVisible: true,
                  message: location.state.snackBarMessage,
                }
            });
        }
        this.props.history.replace({
            ...this.props.location,
            state: {},
        })
    };

    doRequest = () => {
        const { getRefundRequests } = this.props;
        const { activePage, pageSize, valueSearch, status, provider } = this.state;

        let arr = [];
        if (!!status && status !== null) {
            arr.push(`status=${status.value}`);
            this.setState({ status })
        } else {
            this.setState({ status: '' })
        }
        if (valueSearch !== "") {
            arr.push(`query=${valueSearch}`);
        }
        if (provider !== null) {
            arr.push(`provider=${provider.value}`);
        }
        arr.push(`page_size=${pageSize}`);
        arr.push(`page=${activePage}`);

        getRefundRequests(arr).then(res => {
            if (res.payload && res.payload.status) {
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false, activePage: 1 });
                this.doRequest()
            }
        });
    };

    
    toggleSnack = () => {
        this.setState({
            snackBarAttr: {
              isVisible: false,
              message: '',
            }
        });
      };

    changeValueSearch = e => {
        this.setState({ valueSearch: e.target.value });
    };

    handleSearch = e => {
        const { status } = this.state;

        if (e.keyCode === 13) {
            this.setState({ activePage: 1 });
            setTimeout(() => {
                this.doRequest(status);
            }, 0);
        } else if (e === "iconClick") {
            setTimeout(() => {
                this.doRequest(status);
            }, 0);
        }
    };

    handleChange = (name, event) => {
        this.setState({ [name]: event });
        setTimeout(() => {
            this.doRequest();
        }, 0);
    };    

    changePage = (pageNumber) => {
        this.setState({ activePage: pageNumber.selected + 1 });
        setTimeout(() => {
            this.doRequest();
        }, 0);
    };

    changePageSize = (pageSize) => {
        this.setState({ pageSize: pageSize });
        setTimeout(() => {
            this.doRequest();
        }, 0);
    }

    getProviderList() {
        const { getProviders } = this.props;
        
        getProviders().then((res) => {
            if (res.payload && res.payload.status) {
                const providers = [];
                res.payload.data.forEach((item) => {
                    providers.push({label: item.name, value: item.name})
                })
                this.setState({ provider_list: providers });
            } else {
                this.setState({ provider_list: [] });
            }
        })
    }

    render(){
        const { refund_requests:{ all_refund_requests }} = this.props;
        const { loading, activePage, pageSize, valueSearch, status, projects_list, provider, provider_list, snackBarAttr } = this.state;

        if (loading) return null;
        return (
            <TransitionedBlock>
                <div className="refund_requests_page container">
                    <div className="flex-center-btw title-block-page">
                        <div className="title-page">
                            <h1>Заявки на возврат</h1>
                        </div>
                    </div>
                    <div className="page_block">
                        <PanelPage
                            doRequest={this.doRequest}
                            changeValueSearch={this.changeValueSearch}
                            handleSearch={this.handleSearch}
                            handleChange={this.handleChange}
                            valueSeacrh={valueSearch}
                            status={status}
                            projects_list={projects_list}
                            provider={provider}
                            provider_list={provider_list}
                        />
                        <div className="refund_requests_table_wrapper">
                            <div className="transactions_table">
                                <div className="table_container transactions_columns">
                                    <div className="table_header">
                                        <div className="table_row">
                                            <div className="row_item">Наименование</div>
                                            <div className="row_item">Артикул</div>
                                            <div className="row_item">Заказ</div>
                                            <div className="row_item">Компания</div>
                                            <div className="row_item">Поставщик</div>
                                            <div className="row_item">Дата/время</div>
                                            <div className="row_item">Статус</div>
                                            <div className="row_item">К-во товара</div>
                                            <div className="row_item">Сумма</div>
                                        </div>
                                    </div>
                                    <div className="table_body">
                                        {all_refund_requests.results.length !== 0 ? all_refund_requests.results.map((request, idRequest) => (
                                            <Link to={`/admin/refund-request/${request.id}`} className="table_row" key={idRequest}>
                                                <div className="row_item">{request.product_info && request.product_info.name}</div>
                                                <div className="row_item">{request.product_info && request.product_info.article_number}</div>
                                                <div className="row_item">{request.ordernum}</div>
                                                <div className="row_item">{request.company}</div>
                                                <div className="row_item">{request.provider}</div>
                                                <div className="row_item">{moment(request.date).format('DD.MM.YYYY HH:mm')}</div>
                                                <div className="row_item">
                                                    {request.status ?
                                                        <div className="block_status_info">
                                                            <span className="status" style={{background: `${getStatusColor(request.status)}`}} />
                                                            <span className="text">{getStatusRefundRequest(request.status)}</span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <div className="row_item">{request.amount}</div>
                                                <div className="row_item">
                                                    {splitByCommas(request.price)} ₽
                                                </div>
                                            </Link>
                                        )) :
                                            <h1 style={{marginTop: '30px'}}>Заявок не найдено</h1>
                                        }
                                    </div>
                                </div>
                                <div className='control_panel'>
                                    {all_refund_requests.count && all_refund_requests.count > pageSize ?
                                        <div>
                                            <Pagination
                                                active={activePage}
                                                pageItemsCount={pageSize}
                                                pageTotalCount={all_refund_requests.count}
                                                onChange={this.changePage}
                                            />
                                        </div>
                                        : null}
                                    <ControlPanel onChange={(event) => this.changePageSize(event.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SnackBar
                    open={snackBarAttr.isVisible}
                    classes="error"
                    vertical="top"
                    onClose={() => this.toggleSnack()}
                >
                    {snackBarAttr.message}
                </SnackBar>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = ({refund_requests}) => ({
    refund_requests
});

const mapDispatchToProps = {
    getRefundRequests,
    getProviders,
};

export default connect(mapStateToProps, mapDispatchToProps)(RefundRequests);