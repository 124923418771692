import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Loader from '../HelperComponents/Loader/Loader';
import NoItems from '../HelperComponents/NoItems/NoItems';
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import PopperInfo from '../HelperComponents/PopperInfo/PopperInfo';
import { isArray } from "../../helpers/functions";

import {getBlacklistOffers, deleteProductFromBlacklist,
    postProductToBlacklist, getProducts} from "../../actions/userActions";

import './BlackList.scss';
import AddIcon from '../../assets/image/add.png';
import SearchIcon from '../../assets/image/search_grey.png';
import CloseDialogIcon from '../../assets/image/Mask.png';
import OrdersIcon from "../../assets/image/no_orders.png";

const classes = {
    root: 'default_dialog_root blacklist-dialog',
    paper:'paper_custom_dialog'
};

class BlackList extends Component {
    state = {
        black_list: [],
        open: false,
        loading: true,
        index: null,
        valueSearch: '',
        product: null,
        value: '',
        openPopper: false,
        anchorEl: null,
        results: {data: [], query: ''},
        indexSearch: null,
        openAddDialog: false
    };

    componentDidMount() {
        const { getBlacklistOffers, history } = this.props;
        getBlacklistOffers().then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({
                    black_list: res.payload.data,
                    loading: false
                });
            }
            if(res.error && res.error.response.status === 401) {
                localStorage.clear();
                history.push('/auth');
            }
        });
    }

    deleteAction = (item) => {
        let idItem = item.id;
        this.setState(({open}) => ({
            open: !open,
            index: idItem
        }));
    };

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    setProduct = (product) => {
        this.setState({product});
    };

    deleteItem = () => {
        const { deleteProductFromBlacklist, getBlacklistOffers } = this.props;
        let idDeleting = this.state.index;
        deleteProductFromBlacklist(idDeleting).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState(({open}) => ({
                    open: !open
                }));
                getBlacklistOffers().then(res => {
                    if(res.payload && res.payload.status && res.payload.status === 200) {
                        this.setState({
                            black_list: res.payload.data
                        });
                    }
                });
            }
        });
    };

    onChange = (e) => {
        let value = e.target.value;
        this.setState({value});
    };

    onKeyUp = (e) => {
        const { results, openPopper } = this.state;
        let value = e.target.value;
        if(value.length === 3 && value.indexOf(" ") === -1){
            this.getProducts(value);
        } else if (value.length > 3 && !openPopper) {
            this.setState({
                anchorEl: document.getElementById("products_input"),
                openPopper: true
            });
        } else if(value.length < 3 && isArray(results.data)) {
            this.setState({ openPopper: false });
        }
    };

    getProducts = (value) => {
        const { getProducts } = this.props;
        let query = value;
        getProducts(query).then((res) => {
            this.setState({
                anchorEl: document.getElementById("products_input"),
                openPopper: true,
                results: {
                    data: res.payload.data,
                    query: query
                }
            });
        })
    };

    selectProduct = (item) => {
        let arr = [];
        arr.push(item.name);
        arr.push(item.article_number);
        arr.push(item.producer);

        this.setProduct({...item, text: arr.join(' • ')});
        this.setState({
            value: arr.join(' • '),
            openPopper: false,
            indexSearch: item.id
        });
    };

    clickAway = () => {
        this.setState(({openPopper}) => ({
            openPopper: !openPopper
        }));
    };

    toggleAddDialog = () => {
        this.setState(({openAddDialog}) => ({
            openAddDialog: !openAddDialog
        }));
    };

    addItem = () => {
        const { postProductToBlacklist, getBlacklistOffers } = this.props;
        if (this.state.indexSearch !== null) {
            let obj = {
                "id": this.state.indexSearch.toString()
            };
            postProductToBlacklist(obj).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    this.setState(({openAddDialog}) => ({
                        openAddDialog: !openAddDialog,
                        value: ''
                    }));
                }
                getBlacklistOffers().then(res => {
                    if (res.payload && res.payload.status && res.payload.status === 200) {
                        this.setState({
                            black_list: res.payload.data
                        });
                    }
                });
            });
        }
    };

    render(){
        const { black_list, open, loading, value, anchorEl, openPopper, results, openAddDialog } = this.state;
        let items = results.data.filter( el => el.article_number.toLowerCase().indexOf(value.toLowerCase() !== -1) && el.article_number.toLowerCase().includes(value.toLowerCase()));
        if (loading) return null;

        return (
            <TransitionedBlock>
                <main className="container orders-main-block">
                    <div className="flex-center-btw">
                        <div className="flex-center-start">
                            <div className="title-block">
                                <h1>Черный список</h1>
                            </div>
                        </div>
                        <div className="add_button">
                            <Button
                                onClick = {this.toggleAddDialog}
                                disableRipple ={true}
                                classes={{
                                    root: 'add_btn'
                                }}
                            >
                                Добавить
                                <img src={AddIcon} alt="edit icon"/>
                            </Button>
                        </div>
                    </div>
                    <div className="dashboard-section_content">
                        { !black_list ? <Loader/> :
                            black_list.length === 0
                                ?
                                <NoItems>
                                    <div className="box-img"><img src={OrdersIcon} alt="edit icon"/></div>
                                    <div className="title" >Пока здесь нет товаров</div>
                                    <div className="descriptions">Добавляйте сюда товары, которые не нужно учитывать при формировании общего прайса и импорта заказов.</div>
                                </NoItems>
                                :
                                <Fragment>
                                    <h1>Товар(ы)</h1>
                                    <div className="table_container transactions_columns">
                                        <div className="table_header">
                                            <div className="table_row">
                                                <div className="row_item">Наименование</div>
                                                <div className="row_item">Артикул</div>
                                                <div className="row_item">Производитель</div>
                                                <div className="row_item"></div>
                                            </div>
                                        </div>
                                        {black_list.map(( item, i ) => (
                                            <div className="table_body" key={item.id}>
                                                <div className="table_row">
                                                    <div className="row_item">{item.name}</div>
                                                    <div className="row_item">{item.article_number}</div>
                                                    <div className="row_item">{item.producer}</div>
                                                    <div className="row_item">
                                                        <button className="delete_btn" onClick={() => this.deleteAction(item)}>Удалить</button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Fragment>
                        }
                    </div>

                    <DialogComponent
                        open={open}
                        onClose={this.toggleDialog}
                    >
                        <div className="dialog-delete-wrapper">
                            <div className="title">Удалить из черного списка</div>
                            <div className="descriptions">Вы уверены?</div>
                            <div className="dialog-button-wrapper">
                                <DefaultButton
                                    variant="outlined"
                                    classes="cancel_btn"
                                    onClick={this.toggleDialog}
                                >
                                    ОТМЕНА
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick = {this.deleteItem}
                                >
                                    УДАЛИТЬ
                                </DefaultButton>

                            </div>
                        </div>
                        <IconButton
                            classes={{
                                root: "dialog_close_button"
                            }}
                            onClick={this.toggleDialog}
                        >
                            <img src={CloseDialogIcon} alt="close icon"/>
                        </IconButton>
                    </DialogComponent>

                    <DialogComponent
                        open={openAddDialog}
                        onClose={this.toggleAddDialog}
                        classes={classes}
                    >
                        <div className="dialog-delete-wrapper">
                            <div className="title">Добавить в черный список</div>
                            <div className='search_wrapper search_wrapper_small'>
                                <div className="search_product_wrapper">
                                    <input
                                        id="products_input"
                                        type="text"
                                        value={value}
                                        onChange={this.onChange}
                                        onKeyUp={this.onKeyUp}
                                        placeholder="Поиск по номеру детали"
                                        autoComplete="off"
                                    />
                                    <PopperInfo
                                        id="products_popper"
                                        className="products_popper"
                                        open={openPopper}
                                        anchorEl={anchorEl}
                                        clickAway={this.clickAway}
                                        position="bottom-start"
                                        transition
                                    >
                                        <ul className="search_products_list blacklist_ul">
                                            {isArray(items) ?
                                                items.map(el => {
                                                    const { id, name, article_number, producer } = el;
                                                    return (
                                                        <li
                                                            className="search_product_item"
                                                            onClick={() => this.selectProduct(el)}
                                                            key={id}
                                                        >
                                                            <div className="search_product_item_head">
                                                                {name}&nbsp;&nbsp;•&nbsp;&nbsp;{article_number}&nbsp;&nbsp;•&nbsp;&nbsp;{producer}
                                                            </div>
                                                        </li>
                                                    );
                                                })
                                                : <li>Нет товаров</li>
                                            }
                                        </ul>
                                    </PopperInfo>
                                </div>
                                <div className="search_input_icon">
                                    <img src={SearchIcon} alt="SearchIcon"/>
                                </div>
                            </div>
                            <div className="dialog-button-wrapper">
                                <DefaultButton
                                    variant="outlined"
                                    classes="cancel_btn"
                                    onClick={this.toggleAddDialog}
                                >
                                    ОТМЕНА
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick = {this.addItem}
                                >
                                    ДОБАВИТЬ
                                </DefaultButton>

                            </div>
                        </div>
                        <IconButton
                            classes={{
                                root: "dialog_close_button"
                            }}
                            onClick={this.toggleAddDialog}
                        >
                            <img src={CloseDialogIcon} alt="close icon"/>
                        </IconButton>
                    </DialogComponent>
                </main>
            </TransitionedBlock>
        );
    }
}

function mapStateToProps(state) {
    return{
        black_list: state.black_list
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getBlacklistOffers, deleteProductFromBlacklist,
        postProductToBlacklist, getProducts
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BlackList);
