import * as types from "../actions/constants";

const INITIAL_STATE = {
    all_refund_requests: {},
    refund_request: {},

    error: []
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.GET_REFUND_REQUESTS_SUCCESS :
            return {...state, all_refund_requests : action.payload.data};
        case types.GET_SPECIFIC_REFUND_REQUEST_SUCCESS :
            return {...state, refund_request : action.payload.data};

        default:
            return state;
    }
}