import * as types from "../actions/constants";

const INITIAL_STATE = {
    all_orders: {},
    all_statuses: {},
    import_orders: {},
    order: {},
    list_supplier: [],
    error: [],
    auto_suppliers: [],
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_ORDERS_SUCCESS:
            return { ...state, all_orders: action.payload.data };
        case types.GET_ORDER_SUCCESS:
            return { ...state, order: action.payload.data };
        case types.IMPORT_ORDERS_SUCCESS:
            return { ...state, import_orders: action.payload.data };
        case types.GET_ORDERS_FAIL:
            return { ...state, error: action.error.response.data };
        case types.GET_ORDER_FAIL:
            return { ...state, error: action.error.response.data };
        case types.CHANGE_ORDER_LOCAL:
            return {
                ...state,
                import_orders: { ...state.import_orders, orders: action.data },
            };
        case types.GET_SUPPLIERS_SUCCESS:
            return { ...state, list_supplier: action.payload.data };
        case types.GET_SUPPLIERS_AUTO_SUCCESS:
            return { ...state, auto_suppliers: action.payload.data };

        default:
            return state;
    }
}

// return {...state, import_orders: {...state.import_orders, orders: state.import_orders.orders.map(el => el.supplier_id === supplier_id ? {...el, products: el.products.filter(product => product.offer_id !== productEl.offer_id)} : el)}};
