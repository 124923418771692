import * as types from "../actions/constants";

const INITIAL_STATE = {
    orders_history: {},
    reception_history: {},
    waitingReceptionGoods: [],
    error: [],
    mexcarAuto: [],
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.POST_INVOICE_FILE_SUCCESS:
            return { ...state, orders_history: action.payload.data };
        case types.GET_RECEPTION_HISTORY_SUCCESS:
            return { ...state, reception_history: action.payload.data };

        case types.POST_INVOICE_FILE_FAIL:
            return {
                ...state,
                error:
                    action.error.response.data.error ||
                    action.error.response.data.file[0],
            };
        case types.GET_MEXCAR_AUTO_SUCCESS:
            return {
                ...state,
                mexcarAuto: action.payload.data,
            };
        case types.GET_WAITING_RECEPTION_GOODS_SUCCESS:
            return {
                ...state,
                waitingReceptionGoods: action.payload.data,
            }
        default:
            return state;
    }
}
