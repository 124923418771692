import * as types from "./constants.jsx";

//price list actions

export function getPriceListAddresses() {
    return {
        type: types.PRICE_LIST_ADDRESSES,
        payload: {
            client: "default",
            request: {
                url: `/accounts/addresses/`,
                method: "get",
            },
        },
    };
}

export function postSendFile(data) {
    return {
        type: types.POST_SEND_FILE,
        payload: {
            client: "default",
            request: {
                url: `/superadmin/price_list`,
                method: "post",
                data,
            },
        },
    };
}

export function getPriceLists() {
    return {
        type: types.PRICE_LISTS,
        payload: {
            client: "default",
            request: {
                url: `/superadmin/`,
                method: "get",
            },
        },
    };
}

export function getPriceListsSellers() {
  return {
    type: types.PRICE_LISTS_SELLERS,
    payload: {
      client: 'default',
      request: {
        url: `/admin/price_list/sellers/?page_size=1000`,
        method: 'get',
      },
    },
  };
}

export function sendPriceListsSellers(data) {
         return {
           type: types.SEND_PRICE_LISTS_SELLERS,
           payload: {
             client: 'default',
             request: {
               url: `/admin/price_list/sellers/`,
               method: 'post',
               data,
             },
           },
         };
       }

export function postCreatePriceList(data) {
    return {
        type: types.CREATE_PRICE_LIST,
        payload: {
            client: "default",
            request: {
                url: `/price_list/`,
                method: "post",
                data,
            },
        },
    };
}

export function patchPriceList(data, id) {
    return {
        type: types.PATCH_PRICE_LIST,
        payload: {
            client: "default",
            request: {
                url: `/price_list/${id}/`,
                method: "patch",
                data,
            },
        },
    };
}
export function downloadPriceList(path) {
    return {
        type: types.DOWNLOAD_PRICE_LIST,
        payload: {
            client: "default",
            request: {
                url: `/superadmin/download_price_list?download_path=/${path}`,
                method: "get",
            },
        },
    };
}

export function importOrdersFile(data) {
    return {
        type: types.IMPORT_ORDERS,
        payload: {
            client: "default",
            request: {
                url: "/admin/price_list/import",
                method: "post",
                data,
            },
        },
    };
}

export function pathChangeSupplier(data) {
    return {
        type: types.PATH_CHANGE_SUPPLIER,
        payload: {
            client: "default",
            request: {
                url: "/admin/price_list/import/change-supplier/",
                method: "patch",
                data,
            },
        },
    };
}

export function confirmImport(data) {
    return {
        type: types.POST_CONFIRM_IMPORT,
        payload: {
            client: "default",
            request: {
                url: `/admin/price_list/import/confirm/`,
                method: "post",
                data,
            },
        },
    };
}

export function deletePriceList(id) {
    return {
        type: types.DELETE_PRICE_LIST,
        payload: {
            client: "default",
            request: {
                url: `/price_list/${id}/`,
                method: "delete",
            },
        },
    };
}

export function changeMarkupPriceList(id, markup) {
    return {
        type: types.CHANGE_MARKUP_PRICE_LIST,
        id: id,
        markup: markup,
    };
}

export function changeSupplier(article_number, producer_name, quantity) {
    return {
        type: types.GET_CHANGE_SUPPLIER,
        payload: {
            client: "default",
            request: {
                url: `/admin/price_list/import/change-supplier/?article_number=${article_number}&producer_name=${producer_name}&quantity=${quantity}`,
                method: "get",
            },
        },
    };
}

export function getSuppliers() {
    return {
        type: types.GET_SUPPLIERS,
        payload: {
            client: "default",
            request: {
                url: `/admin/price_list/import/suppliers/`,
                method: "get",
            },
        },
    };
}

export function getSuppliersAuto() {
    return {
        type: types.GET_SUPPLIERS_AUTO,
        payload: {
            client: "default",
            request: {
                url: `/admin/japarts/orders/mexcarauto/supplier/`,
                method: "get",
            },
        },
    };
}
