import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Head from '../../components/Head/Head';
import Sidebar from '../../components/Sidebar/Sidebar';
// import Dashboard from '../../components/Dashboard/Dashboard';
import BlackList from '../../components/BlackList/BlackList';
import ImportOrders from '../../components/ImportOrders/ImportOrders';
import PriceList from '../../components/PriceList/PriceList';
import OrdersItems from '../../components/OrdersPage/OrdersItems/OrdersItems';
import RefundRequestsItems from '../../components/RefundRequests/RefundRequestsItems/RefundRequestsItems';
import OrdersPage from '../../components/OrdersPage/OrdersPage';
import Transactions from '../../components/Transactions/Transactions';
import TransactionsItem from "../../components/Transactions/TransactionsItem/TransactionsItem";
import RefundRequests from '../../components/RefundRequests/RefundRequests';
import GoodsReception from '../../components/GoodsReception/GoodsReception';
import DisposalBalances from '../../components/DisposalBalances/DisposalBalances';
import NewIssue from '../../components/DisposalBalances/NewIssue/NewIssue';
import ImportOrdersInner from '../../components/ImportOrders/ImportOrdersInner/ImportOrdersInner';
import NotFound from '../../components/NotFound/NotFound';

import { getUser } from "../../actions/userActions";

class Container extends Component {
     state = {
        open: false
    };

    componentDidMount() {
        const { getUser } = this.props;
        getUser();
    }

    toggleDrawer = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    render() {
        const { match, history } = this.props;
        const { open } = this.state;
        if(!localStorage.token) return <Redirect to="/auth" />;
        return (
            <Fragment>
                <Head history={history} open={open} onClick={this.toggleDrawer} />
                <Sidebar open={open} onClick={this.toggleDrawer} />
                <Switch>
                    <Route path={match.url} exact render={() =>  <Redirect to={`${match.url}/price-list`} /> } />
                    <Route path={`${match.url}/import-orders`} exact component={ImportOrders} />
                    <Route path={`${match.url}/black-list`} exact component={BlackList} />
                    <Route path={`${match.url}/price-list`} exact component={PriceList} />
                    <Route path={`${match.url}/orders-items/:idOrder`} exact component={OrdersItems} />
                    <Route path={`${match.url}/orders-page`} exact component={OrdersPage} />
                    <Route path={`${match.url}/transactions`} exact component={Transactions} />
                    <Route path={`${match.url}/transactions/:id`} exact component={TransactionsItem} />
                    <Route path={`${match.url}/refund-requests`} exact component={RefundRequests} />
                    <Route path={`${match.url}/refund-request/:id`} exact component={RefundRequestsItems} />
                    <Route path={`${match.url}/good-reception`} exact component={GoodsReception} />
                    <Route path={`${match.url}/disposal-balances`} exact component={DisposalBalances} />
                    <Route path={`${match.url}/new-issue`} exact component={NewIssue} />
                    <Route path={`${match.url}/import-orders-inner`} exact component={ImportOrdersInner} />
                    <Route component={NotFound} />
                </Switch>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getUser,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
