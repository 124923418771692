import * as types from './constants.jsx';

export function getRefundRequests(arr) {
    return {
        type: types.GET_REFUND_REQUESTS,
        payload: {
            client: 'default',
            request: {
                url: `/admin/japarts/refund_requests/${arr.length && arr.length !== 0 ? '?' : ''}${arr.join('&')}`,
                method: "get"
            }
        }
    };
}

export function getSpecificRefundRequest(id) {
    return {
        type: types.GET_SPECIFIC_REFUND_REQUEST,
        payload: {
            client: 'default',
            request: {
                url: `/admin/japarts/refund_requests/${id}`,
                method: "get"
            }
        }
    };
}

export function patchRefundRequestStatus(id, data) {
    return {
        type: types.PATCH_REFUND_REQUEST_STATUS,
        payload: {
            client: 'default',
            request: {
                url: `/admin/japarts/refund_requests/${id}`,
                method: "patch",
                data
            }
        }
    };
}

export function postRefundRequestConfirmStatus(id, data) {
    return {
        type: types.POST_REFUND_REQUEST_CONFIRM_STATUS,
        payload: {
            client: 'default',
            request: {
                url: `/admin/japarts/refund_requests/${id}/confirm/`,
                method: "post",
                data
            }
        }
    };
}

export function postDialogMessage(data) {
    return {
        type: types.POST_DIALOG_MESSAGE,
        payload: {
            client: "default",
            request: {
                url: `/refund/message/`,
                method: "post",
                data,
            },
        },
    };
}

export function postRefundRequestOffers(id, data) {
    return {
        type: types.POST_REFUND_REQUESTS_OFFERS,
        payload: {
            client: 'default',
            request: {
                url: `/admin/japarts/refund_requests/${id}/offers/`,
                method: "post",
                data
            }
        }
    };
}