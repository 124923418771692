import React, { Component } from "react";
import { connect } from "react-redux";
import Barcode from "react-barcode";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import IconButton from "@material-ui/core/IconButton";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import PrintComponents from "react-print-components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseDialogIcon from "../../../assets/image/Mask.png";
import SearchIcon from "../../../assets/image/search_grey.png";
import delete_items from "../../../assets/image/delete_items.png";
import MinusIcon from "../../../assets/image/minus.png";
import PlusIcon from "../../../assets/image/plus.png";

import "./ProductSelection.scss";

class ProductSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabledHandleOrderButton: false,
            openDeleteDialog: false,
            openNoItemDialog: false,
            openItemDialog: false,
            openItemInfoDialog: false,
            arrayOfOrders: [],
            filteredOrders: [],
            processedOrders: props.processedOrders,
            specificOrder: [],
            quantity: 1,
            maxAmount: null,
            orderToDelete: null,
            originalSum: null,
            file_type: localStorage.getItem("file_type"),
            inputValue: "",
            anchorEl: null,
            inStockCurrent: null,
            errorOpened: false,
            modalProducer: '',
            modalOrders: [],
            quantityPrint: 1,
        };
    }

    componentDidMount = () => {
        const { goods_reception } = this.props;
        let originalSum = 0;
        goods_reception.orders_history.rows.forEach((i) => {
            originalSum += i.amount;
        });
        setTimeout(() => {
            this.correctArrayOfOrders()
        }, 0);
    };
    correctArrayOfOrders = () => {
        const { goods_reception: {orders_history} } = this.props;
        const { processedOrders } = this.state;
        let correctOrders = JSON.parse(JSON.stringify(orders_history.rows));
        let originalSum = 0;
        orders_history.rows.forEach((i) => {
            originalSum += i.amount;
        });
        if (processedOrders.length !== 0) {
            processedOrders.map((order) => {
                const indexOrder = correctOrders.findIndex((item) => item.article_number === order.article_number && this.priceСomparison(item.price, order.price));
                if (indexOrder !== -1) {
                    correctOrders[indexOrder].amount -= order.in_stock;
                    if (correctOrders[indexOrder].amount === 0) {
                        correctOrders.splice(indexOrder, 1);
                    }
                }
            })
        }
        this.setState({arrayOfOrders: correctOrders, originalSum: originalSum})
    }
    deleteDialog = (processedOrder) => {
        this.setState(({ openDeleteDialog }) => ({
            openDeleteDialog: !openDeleteDialog,
            orderToDelete: processedOrder,
        }));
    };

    noItemDialog = () => {
        this.setState(({ openNoItemDialog }) => ({
            openNoItemDialog: !openNoItemDialog,
        }));
    };

    itemDialog = () => {
        this.setState(({ openItemDialog }) => ({
            openItemDialog: !openItemDialog,
        }));
    };

    itemInfoDialog = (noItemDialog) => {
        this.setState(({ openItemInfoDialog }) => ({
            openItemInfoDialog: noItemDialog ? false : !openItemInfoDialog,
            openItemDialog: false,
        }));
        setTimeout(() => {
            this.handleEventAdding();
        }, 100);
    };

    handleEventAdding = () => {
        const self = this;
        const item = document.getElementById("quantity_dialog");
        if (item) {
            item.addEventListener("keyup", function(e) {
                if (e.keyCode === 13) {
                    self.handleOrder();
                }
            });
            item.focus();
        }
    };

    priceСomparison = (price, price2) => {
        return price === price2 || Math.abs(price - price2).toFixed(2)
    }

    selectSpecificOrder = (item) => {
        const { inputValue, filteredOrders } = this.state;
        const selectedOrder = filteredOrders.filter((order) => order.row_id === item.row_id);
        this.setState({
            specificOrder: selectedOrder,
            currentOrder: selectedOrder,
            maxAmount: selectedOrder[0].amount_left || selectedOrder[0].amount,
        });
        if (selectedOrder[0].amount > 0) {
            setTimeout(() => {
                // добавить проверку на количетсво данных в накладной и проверку заказов
                this.getDataForModal(inputValue, selectedOrder[0].row_id);
                const { modalOrders } = this.state
                if (selectedOrder[0].amount === 1 && modalOrders.length === 1) {
                    const inputEl = document.getElementById('products_popper');
                    modalOrders[0].in_stock++;
                    this.setState({ modalOrders });
                    this.itemDialog();
                    this.handleOrder();
                    inputEl.blur();
                } else {
                    this.itemInfoDialog();
                }
            }, 0);
        } else {
            setTimeout(() => {
                this.handleOrder(true);
            }, 0);
        }
    };

    onKeyUp = (e) => {
        if (e.keyCode === 13) {
            setTimeout(() => {
                this.searchHandle();
            }, 0);
        }
    };

    getDataForModal = (val, row_id) => {
        const { processedOrders } = this.state;
        const { goods_reception } = this.props;
        const order = goods_reception.orders_history.order_list.find((order) => order.article_number === val);
        const listOrder = JSON.parse(JSON.stringify(goods_reception.orders_history.order_list.filter((order) => order.article_number === val && order.row_id === row_id)))
        // добавить сюда проверку на processedOrders
        listOrder.map((order) => {
            const checkProcessOrdersAmount = processedOrders.find((processedOrder) => processedOrder.article_number === order.article_number && processedOrder.order_number === order.order_number)
            order.amount = order.amount - order.in_stock;
            order.in_stock = 0;
            order.processed_amount = checkProcessOrdersAmount ? checkProcessOrdersAmount.in_stock : 0
        })
        if (processedOrders.length > 0) {
            processedOrders.map((order) => {
                const findIndex = listOrder.findIndex((item) => item.order_number === order.order_number);
                if (findIndex !== -1) {
                    listOrder[findIndex].amount -= order.in_stock;
                    listOrder[findIndex].in_stock = 0;
                }
            })
        }
        this.setState({ modalProducer: order.producer, modalOrders: JSON.parse(JSON.stringify(listOrder)) });
    }

    searchHandle = () => {
        const { inputValue, arrayOfOrders } = this.state;
        const { goods_reception: {orders_history} } = this.props;
        let searchedOrders = [];
        let currentAmount = 0;
        orders_history.order_list.map((order) => {
            if (order.article_number === inputValue) {
                currentAmount += order.amount
            }
        })
        searchedOrders = arrayOfOrders.filter((item) => item.article_number.toString().toLowerCase() === inputValue.toString().toLowerCase());
        if (searchedOrders.length === 0) {
            this.noItemDialog();
        } else if (searchedOrders.length === 1) {
            if (inputValue.length > 0 && !!arrayOfOrders.find((item) => item.article_number === inputValue)) {
                this.getDataForModal(inputValue, searchedOrders[0].row_id);
            }
            this.setState({
                specificOrder: searchedOrders,
                currentOrder: searchedOrders,
                maxAmount:
                    searchedOrders[0].amount_left || searchedOrders[0].amount,
                quantity: 1,
                inStockCurrent: searchedOrders[0].in_stock,
            });
            setTimeout(() => {
                const { modalOrders } = this.state;
                if (searchedOrders[0].amount === 1 && modalOrders.length === 1) {
                    modalOrders[0].in_stock++
                    this.setState({ modalOrders })
                    this.handleOrder();
                } else {
                    this.itemInfoDialog();
                }
            }, 200)
        } else {
            this.itemDialog();
            this.setState({ filteredOrders: searchedOrders });
        }
    }; 

    searchChange = (e) => {
        // функция вызова модалки по клику на айтем в селекте
        this.setState({ inputValue: e.trim() });
    };

    changeAmount = (order, index, type) => {
        const { modalOrders } = this.state;
        const { goods_reception: { orders_history: { rows }}} = this.props
        if (type === 'plus') {
            if (modalOrders[index].in_stock >= modalOrders[index].amount || modalOrders[index].in_stock >= rows.find((row) => modalOrders[index].row_id === row.row_id).amount) return;
            modalOrders[index].in_stock++;
            this.setState({ modalOrders })
        } else {
            if (modalOrders[index].in_stock == 0) return;
            modalOrders[index].in_stock--;
            this.setState({ modalOrders });
        }
    };

    checkAmountInStockHandler = (e, modalOrder) => {
        const { specificOrder, modalOrders } = this.state;
        let sum = 0;
        if (e.target.value === '' || e.target.value < 0) {
            modalOrder.in_stock = 0
        }
        if (modalOrder.in_stock > modalOrder.amount) {
            modalOrder.in_stock = modalOrder.amount
        }
        modalOrders.map((order) => {
            if (order.order_number !== modalOrder.order_number) {
                sum += Number(order.in_stock);
            }
        })
        if (specificOrder[0].amount - sum <= 0) {
            modalOrder.in_stock = 0;
        } 
        if (specificOrder[0].amount - sum > 0 && modalOrder.in_stock > specificOrder[0].amount - sum) {
            modalOrder.in_stock = specificOrder[0].amount - sum;
        }      
        this.setState({modalOrders})
    }

    handleOrder = (noItemDialog = false) => {
        if (this.state.disabledHandleOrderButton) {
            return;
        }

        const {
            specificOrder,
            processedOrders,
            modalOrders,
            arrayOfOrders,
            inputValue,
            openItemInfoDialog
        } = this.state;
        let customOrder = { ...specificOrder[0] };
        let arrayOfProcessedOrders = [...processedOrders];
        let arrayOfModalOrders = modalOrders.filter((order) => order.in_stock > 0);
        let choiseAmount = 0;
        this.setState({ disabledHandleOrderButton: true });
        arrayOfModalOrders.map((order) => { choiseAmount += Number(order.in_stock) });
        if (arrayOfModalOrders.length === 0 || choiseAmount > customOrder.amount) {
            this.setState({ errorOpened: true });
            setTimeout(() => {
                this.setState({ disabledHandleOrderButton: false });
            }, 1000)
            return;
        }

        this.setState({quantityPrint: choiseAmount});
        const currentAmount = arrayOfOrders.find((order) => order.article_number === arrayOfModalOrders[0].article_number && order.row_id === arrayOfModalOrders[0].row_id);
        
        if (!currentAmount) {
            setTimeout(() => {
                this.setState({ disabledHandleOrderButton: false });
            }, 1000)
            return;
        }

        currentAmount.amount = customOrder.amount - choiseAmount
        if (currentAmount.amount === 0) {
            const index = arrayOfOrders.findIndex((el) => el.article_number === customOrder.article_number && el.row_id === customOrder.row_id);
            arrayOfOrders.splice(index, 1);
        }
        if (arrayOfProcessedOrders.length === 0) {
            arrayOfProcessedOrders.push(...arrayOfModalOrders);
        } else {
            arrayOfModalOrders.map((order) => {
                const checkProcessOrders = arrayOfProcessedOrders.find((item) => item.row_id === order.row_id && item.order_number === order.order_number)
                if (checkProcessOrders === undefined) {
                    arrayOfProcessedOrders.push(order);
                } else {
                    checkProcessOrders.in_stock = Number(checkProcessOrders.in_stock) + Number(order.in_stock);
                }
            })
        }
        this.setState({ processedOrders: arrayOfProcessedOrders, specificOrder: arrayOfModalOrders, arrayOfOrders })
        const copyArrayOfOrders = JSON.parse(JSON.stringify(arrayOfOrders));
        const arrayWithSameArticle = copyArrayOfOrders.find((item) => item.article_number === inputValue);
        if (!arrayWithSameArticle) {
            this.setState({inputValue: ''})
        }
        if (openItemInfoDialog) {
            this.itemInfoDialog(noItemDialog);
        }
        const checkBrowser = !navigator.userAgent.includes("Chrome") && arrayOfModalOrders.length > 1;
        setTimeout(() => {
            this.setState({ disabledHandleOrderButton: false });
        }, 1000);
        setTimeout(() => {
            const printButtons = document.getElementsByClassName("print_btn");
            if (checkBrowser) {
                printButtons[1].click();
            };
            setTimeout(() => {
                arrayOfModalOrders.forEach((order, index) => {
                    printButtons[index].click();
                })
            }, 700);            
        }, 300)
    };

    deleteOrder = () => {
        const { orderToDelete, arrayOfOrders } = this.state;
        const { goods_reception: { orders_history } } = this.props;
        let arrayOfPrimalOrders = [...arrayOfOrders];
        const indexOfExistedOrder = arrayOfOrders.findIndex((el) => el.row_id === orderToDelete.row_id);
        if (indexOfExistedOrder !== -1) {
            arrayOfPrimalOrders[indexOfExistedOrder].amount += Number(orderToDelete.in_stock);
        } else {
            const newEl = JSON.parse(JSON.stringify(orders_history.rows.find((item) => item.row_id === orderToDelete.row_id)));
            newEl.amount = Number(orderToDelete.in_stock);
            arrayOfPrimalOrders.push(newEl);
        }
        this.setState(({ processedOrders }) => ({
            processedOrders: processedOrders.filter(
                (order) => order !== orderToDelete
            ),
            openDeleteDialog: false,
            arrayOfOrders: JSON.parse(JSON.stringify(arrayOfPrimalOrders)),
        }));
    };

    handleEnter = (e) => {
        if (e && e.keyCode === 13) {
            this.handleOrder();
        }
    };

    handleCloseError = (e) => {
        if (e && e.keyCode === 13) {
            this.setState({ errorOpened: false });
        }
    };

    render() {
        const {
            changeTab,
            goods_reception: { orders_history },
            goods_reception: { orders_history: { rows }}
        } = this.props;
        const {
            openDeleteDialog,
            openNoItemDialog,
            openItemDialog,
            openItemInfoDialog,
            arrayOfOrders,
            filteredOrders,
            specificOrder,
            processedOrders,
            maxAmount,
            originalSum,
            inputValue,
            inStockCurrent,
            modalProducer,
            modalOrders,
            quantityPrint,
            disabledHandleOrderButton,
            // anchorEl
        } = this.state;
        const hours = new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
        });
        const day = new Date().toLocaleDateString();
        const searchedOrders = [
            ...new Set(
                arrayOfOrders
                    .filter((el) =>
                        el.article_number
                            .toString()
                            .toLowerCase()
                            .includes(inputValue.toString().toLowerCase())
                    )
                    .map((el) => el.article_number)
            ),
        ];
        return (
            <div className="product_selection">
                <div className="descriptions">
                    Чтобы добавить товары из накладной, воспользуйтесь поиском
                    по артикулу детали или сканером штрих-кодов.
                </div>
                <div className="search_block">
                    <Autocomplete
                        id="products_popper"
                        options={searchedOrders}
                        value={inputValue}
                        onInputChange={(e, value) => this.searchChange(value)}
                        onChange={(e, value) => {
                            if (value) {
                                if (e.keyCode !== 13) {
                                    this.setState({ inputValue: value });
                                    setTimeout(() => {
                                        this.searchHandle();
                                    }, 0);
                                }
                            }
                        }}
                        noOptionsText="Совпадений не найдено"
                        renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                                <input
                                    id="inputField"
                                    type="text"
                                    value={inputValue}
                                    onKeyUp={(e) => this.onKeyUp(e)}
                                    autoComplete="off"
                                    {...params.inputProps}
                                />
                                <button onClick={() => this.searchHandle()}>
                                    <img src={SearchIcon} alt="SearchIcon" />
                                </button>
                            </div>
                        )}
                    />
                </div>
                <div className="product_wrapper_table">
                    <div className="table_left">
                        <div className="title_table">Товары из накладной</div>
                        {arrayOfOrders.length === 0 ? (
                            <div className="no_items">
                                Все товары из накладной обработаны.
                            </div>
                        ) : (
                            <div className="table_container transactions_columns">
                                <div className="table_header">
                                    <div className="table_row">
                                        <div
                                            className="row_item"
                                            style={{ width: "40%" }}
                                        >
                                            Деталь
                                        </div>
                                        <div
                                            className="row_item"
                                            style={{ width: "40%" }}
                                        >
                                            Наименование
                                        </div>
                                        {/*{file_type === "1" && <div className="row_item">Комментарий</div>}*/}
                                        <div className="row_item"
                                            style={{ width: "auto",  margin: '0 auto'}}
                                        >
                                            К-во товара
                                        </div>
                                    </div>
                                </div>
                                <div className="table_body">
                                    {arrayOfOrders &&
                                        arrayOfOrders.map((order, idOrder) => (
                                            <div
                                                className="table_row"
                                                key={idOrder}
                                            >
                                                <div
                                                    className="row_item"
                                                    style={{ width: "40%" }}
                                                >
                                                    {order.article_number}{" "}
                                                    &nbsp;•&nbsp;{" "}
                                                    {order.producer}
                                                </div>
                                                <div 
                                                    className="row_item"
                                                    style={{ width: "40%" }}
                                                >
                                                    {order.product}
                                                </div>
                                                <div className="row_item"
                                                    style={{ width: "20%", textAlign: 'center' }}
                                                >
                                                    {order.amount_left ||
                                                        order.amount}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="line_v" />
                    <div className="table_right">
                        <div className="title_table">Обработанные товары</div>
                        {processedOrders.length === 0 ? (
                            <div className="no_items">Здесь пока пусто.</div>
                        ) : (
                            <div className="table_container transactions_columns">
                                <div className="table_header">
                                    <div className="table_row">
                                        <div className="row_item">Заказ</div>
                                        <div
                                            className="row_item"
                                            style={{ width: "50%" }}
                                        >
                                            Деталь
                                        </div>
                                        {/*{file_type === "1" && <div className="row_item">Комментарий</div>}*/}
                                        <div className="row_item">
                                            К-во товара
                                        </div>
                                        <div className="row_item" />
                                    </div>
                                </div>
                                <div className="table_body">
                                    {processedOrders &&
                                        processedOrders.length &&
                                        processedOrders.map(
                                            (
                                                processedOrder,
                                                idProcessedOrder
                                            ) => (
                                                <div
                                                    className="table_row"
                                                    key={idProcessedOrder}
                                                >
                                                    <div className="row_item">
                                                        {
                                                            processedOrder.order_number
                                                        }
                                                    </div>
                                                    <div
                                                        className="row_item"
                                                        style={{ width: "50%" }}
                                                    >
                                                        {
                                                            processedOrder.article_number
                                                        }{" "}
                                                        &nbsp;•&nbsp;{" "}
                                                        {
                                                            processedOrder.producer
                                                        }
                                                    </div>
                                                    {/*{file_type === "1" && <div className="row_item">*/}
                                                    {/*{processedOrder.comment !== '' || null}*/}
                                                    {/*{processedOrder.comment}*/}
                                                    {/*</div>}*/}
                                                    <div className="row_item">
                                                        {
                                                            processedOrder.in_stock
                                                        }
                                                    </div>
                                                    <div className="row_item">
                                                        <button
                                                            onClick={() =>
                                                                this.deleteDialog(
                                                                    processedOrder
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    delete_items
                                                                }
                                                                alt="delete_items"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="btn_wrapper_product">
                    <DefaultButton
                        variant="outlined"
                        classes="cancel_btn"
                        onClick={() => {
                            changeTab(0);
                            this.props.clearProcessed();
                        }}
                    >
                        Назад
                    </DefaultButton>
                    <DefaultButton
                        disabled={processedOrders.length === 0}
                        onClick={() => {
                                changeTab(
                                    2,
                                    orders_history.upd_number,
                                    orders_history.price,
                                    processedOrders,
                                    originalSum,
                                    arrayOfOrders,
                                )
                            }
                        }
                    >
                        Продолжить
                    </DefaultButton>
                </div>
                <div className="no_display">
                    {specificOrder.length &&
                        specificOrder.map((order, idOrder) => (
                            <PrintComponents
                                key={idOrder}
                                trigger={<button className="print_btn">Печать</button>}
                            >
                               <div className="to-print-sticker">
                                    <div className='to-print-sticker__logo'></div>
                                    <div className='to-print-sticker__main-info'>
                                        <div className='to-print-sticker__left-block'>
                                            <div className='to-print-sticker__left-block-up'>
                                                {order &&
                                                    <p>{order.producer}</p>
                                                }
                                                {order &&
                                                    <p className='to-print-sticker__left-block-up--article'>{order.article_number}</p>
                                                }
                                                {order && order.comment &&
                                                    <p className='to-print-sticker__left-block-up--comment'>{order.comment}</p>
                                                }                                                
                                            </div>
                                            <div className={`to-print-sticker__left-block-bottom ${!order.comment ? 'to-print-sticker__left-block-bottom--wo-comment' : ''}`}>
                                            {/* штрих код */}
                                                {!!order && (
                                                    <Barcode
                                                        value={
                                                            order.article_number
                                                        }
                                                        width={1.1}
                                                        height={50}
                                                        margin={0}
                                                        displayValue={false}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className='to-print-sticker__center-block'>
                                            <div className='to-print-sticker__center-block-up'>
                                                <p className='to-print-sticker__center-block-up--amount'>{order && order.in_stock}<span>шт.</span></p>
                                            </div>
                                            <div className={`to-print-sticker__center-block-bottom ${!order.comment ? 'to-print-sticker__center-block-bottom--wo-comment' : ''}`}>
                                                <p>{day}</p>
                                                <p>{hours}</p>
                                                <p>{order && order.order_number}</p>
                                            </div>
                                        </div>
                                        <div className='to-print-sticker__right-block'>
                                            {order && order.company}
                                        </div>
                                    </div>
                               </div>
                            </PrintComponents>
                        )
                    )}
                </div>

                <DialogComponent
                    open={openDeleteDialog}
                    onClose={this.deleteDialog}
                >
                    <div className="title-dialog">Удалить товар</div>
                    <div className="descriptions_dialog_component no_margin">
                        Вы уверены, что хотите удалить данный товар из списка?
                    </div>
                    <div className="btn-wrapper order-dialog-btn">
                        <DefaultButton
                            variant="outlined"
                            classes="cancel_btn"
                            onClick={this.deleteDialog}
                        >
                            Отмена
                        </DefaultButton>
                        <DefaultButton onClick={this.deleteOrder}>
                            Удалить
                        </DefaultButton>
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button",
                        }}
                        onClick={this.deleteDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon" />
                    </IconButton>
                </DialogComponent>

                <DialogComponent
                    open={openNoItemDialog}
                    onClose={this.noItemDialog}
                >
                    <div className="title-dialog">Совпадений не найдено</div>
                    <div className="descriptions_dialog_component no_margin">
                        Убедитесь в правильности поискового запроса и повторите
                        попытку.
                    </div>
                    <div className="btn-wrapper order-dialog-btn">
                        <DefaultButton onClick={this.noItemDialog}>
                            Ок
                        </DefaultButton>
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button",
                        }}
                        onClick={this.noItemDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon" />
                    </IconButton>
                </DialogComponent>

                <DialogComponent
                    open={openItemDialog}
                    onClose={this.itemDialog}
                >
                    <div className="title-dialog no_padding_bottom">
                        Найдены совпадения
                    </div>
                    <div className="descriptions_dialog_component">
                        Выберите нужный товар, чтобы продолжить.
                    </div>
                    <div className="table_dialog_items">
                        <div className="table_container transactions_columns">
                            <div className="table_body">
                                {filteredOrders.map(
                                    (filteredOrder, idFilteredOrder) => (
                                        <div
                                            className="table_row"
                                            onClick={() =>
                                                this.selectSpecificOrder(
                                                    filteredOrder
                                                )
                                            }
                                            key={idFilteredOrder}
                                        >
                                            <div
                                                className="row_item"
                                                style={{ width: "33%" }}
                                            >
                                                <div className="name_items">
                                                    {
                                                        filteredOrder.article_number
                                                    }
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_table">
                                                    <span className="title_table">
                                                        Производитель
                                                    </span>
                                                    <span className="info_item">
                                                        {filteredOrder.producer}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_table">
                                                    <span className="title_table">
                                                        Количество
                                                    </span>
                                                    <span className="info_item">
                                                        {filteredOrder.amount_left ||
                                                            filteredOrder.amount}{" "}
                                                        шт.
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_table">
                                                    <span className="title_table">
                                                        Заказ
                                                    </span>
                                                    <span className="info_item">
                                                        {
                                                            filteredOrder.order_number
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button",
                        }}
                        onClick={this.itemDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon" />
                    </IconButton>
                </DialogComponent>
                <DialogComponent
                // modal
                    open={openItemInfoDialog}
                    onClose={this.itemInfoDialog}
                >
                    <div
                        id="quantity_dialog"
                        onKeyPress={(e) => this.handleEnter()}
                        tabIndex="0"
                    >
                        <div className="title-dialog">Количество товара</div>
                        <div className="product_wrapper">
                            <div className="table_dialog_items">
                                <div className="table_container transactions_columns">
                                    <div className="table_body">
                                        <div className="table_row">
                                            <div
                                                className="row_item"
                                                style={{ width: "33%" }}
                                            >
                                                <div className="name_items">
                                                    {specificOrder[0] &&
                                                        specificOrder[0]
                                                            .article_number}
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_table">
                                                    <span className="title_table">
                                                        Производитель
                                                    </span>
                                                    <span className="info_item">
                                                        {specificOrder[0] &&
                                                            modalProducer}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row_item">
                                                <div className="block_table">
                                                    <span className="title_table">
                                                        Количество
                                                    </span>
                                                    <span className="info_item">
                                                        {specificOrder[0] &&
                                                            (specificOrder[0]
                                                                .amount_left ||
                                                                specificOrder[0]
                                                                    .amount)}{" "}
                                                        шт.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="descriptions_dialog_component">
                            Распределение товара по заказам
                        </div>
                        <div className="table_left">
                            <div className="table_container transactions_columns">
                                <div className="table_header">
                                    <div className="table_row">
                                        <div className="row_item" style={{ width: "20%" }}>Заказ</div>
                                        <div className="row_item" style={{ width: "20%" }}>
                                            К-во товара
                                        </div>
                                        <div className="row_item" style={{ width: "20%" }}>№ заказа</div>
                                        <div className="row_item" style={{ width: "20%" }}>Кол-во принятых</div>
                                    </div>
                                </div>
                                <div className="table_body">
                                    {modalOrders && modalOrders.length && modalOrders.map((modalOrder, modalOrderId) => (
                                            <div className="table_row" key={modalOrderId}>
                                                <div className="row_item" style={{ width: "20%" }}> {modalOrder.company} </div>
                                                <div className="row_item" style={{ width: "20%" }}>
                                                    <div className="amount">
                                                        <button
                                                            style={{opacity: modalOrder.in_stock === 0 ? "0.3": "1", cursor: modalOrder.in_stock === 0 ? "not-allowed": "pointer"}}
                                                            onClick={() => this.changeAmount(modalOrder, modalOrderId, 'minus')}
                                                        >
                                                            <img src={MinusIcon} alt="-" />
                                                        </button>
                                                        <input
                                                            type="number"
                                                            value={modalOrder.in_stock}
                                                            onChange={(e) => {
                                                                modalOrder.in_stock = Number(e.target.value);
                                                                this.setState({modalOrders})
                                                            }}
                                                            onBlur={(e) => this.checkAmountInStockHandler(e, modalOrder)}
                                                        /> 
                                                        <button
                                                            style={{
                                                                opacity: modalOrder.in_stock >= modalOrder.amount || modalOrder.in_stock >= rows.find((row) => modalOrder.row_id === row.row_id).amount ? "0.3": "1",
                                                                cursor: modalOrder.in_stock >= modalOrder.amount || modalOrder.in_stock >= rows.find((row) => modalOrder.row_id === row.row_id).amount ? "not-allowed": "pointer"
                                                            }}
                                                            onClick={() => this.changeAmount(modalOrder, modalOrderId, 'plus')}
                                                        >
                                                            <img src={PlusIcon} alt="+" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row_item" style={{ width: "20%" }}> {modalOrder.order_number}</div>
                                                <div className="row_item" style={{ width: "20%" }}> {modalOrder.processed_amount}</div>
                                            </div>
                                        )
                                    )}
                                </div>                            
                            </div>
                        </div>
                        <div className="btn-wrapper order-dialog-btn">
                            <DefaultButton
                                variant="outlined"
                                classes="cancel_btn"
                                onClick={this.itemInfoDialog}
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                onClick={this.handleOrder}
                                disabled={disabledHandleOrderButton}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                        <IconButton
                            classes={{
                                root: "dialog_close_button",
                            }}
                            onClick={this.itemInfoDialog}
                        >
                            <img src={CloseDialogIcon} alt="close icon" />
                        </IconButton>
                    </div>
                </DialogComponent>
                <DialogComponent
                    open={this.state.errorOpened}
                    onClose={() => this.setState({ errorOpened: false })}
                >
                    <div
                        className="title-dialog"
                        style={{ paddingBottom: "10px" }}
                    >
                        Ошибка
                    </div>
                    <div
                        id="quantity_dialog"
                        onKeyPress={(e) => this.handleCloseError()}
                        tabIndex="0"
                    >
                        <div
                            className="descriptions_dialog_component"
                            style={{ fontSize: "16px" }}
                        >
                            Укажите допустимое количество товаров для данного
                            заказа.{" "}
                        </div>

                        <div className="btn-wrapper order-dialog-btn">
                            <DefaultButton
                                onClick={() =>
                                    this.setState({ errorOpened: false })
                                }
                            >
                                OK
                            </DefaultButton>
                        </div>
                        <IconButton
                            classes={{
                                root: "dialog_close_button",
                            }}
                            onClick={() =>
                                this.setState({ errorOpened: false })
                            }
                        >
                            <img src={CloseDialogIcon} alt="close icon" />
                        </IconButton>
                    </div>
                </DialogComponent>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        goods_reception: state.goods_reception,
    };
}

export default connect(mapStateToProps)(ProductSelection);
