import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import IconButton from '@material-ui/core/IconButton';
import PopperInfo from '../HelperComponents/PopperInfo/PopperInfo';
import SearchIcon from '../../assets/image/search_grey.png';
import CloseDialogIcon from '../../assets/image/Mask.png';
import TransactionsTable from './TransactionsTable/TransactionsTable';
import TransactionPanel from './TransactionPanel/TransactionPanel';
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import { isArray } from "../../helpers/functions";

import { getTransactions, getCompanies, postTransaction } from "../../actions/transactionsActions";

import AddIcon from '../../assets/image/add.png';
import './Transactions.scss';

const classes = {
    root: 'default_dialog_root transactions-dialog',
    paper:'paper_custom_dialog'
};

class Transactions extends Component {

    state = {
        open: false,
        valueSearch: '',
        openPopper: false,
        loading: true,
        typeOfTransactions: '',
        anchorEl: null,
        amount: null,
        blocker: true,
        loadingBtn: false,
    };

    componentDidMount = () => {
        const { getCompanies } = this.props;
        getCompanies();
        this.doRequest();
    };

    doRequest = (activePage = 1, typeOfTransactions = '', company = '') => {
        const { getTransactions } = this.props;

        let arr = [];
        if (typeOfTransactions !== '') {
            arr.push(`type_transaction=${typeOfTransactions}`);
        }
        if (!!company && company !== '') {
            arr.push(`company=${company}`);
        }
        arr.push(`page_size=10`);
        arr.push(`page=${activePage}`);

        getTransactions(arr).then(res => {
            if (res.payload && res.payload.status) {
                this.setState({ loading: false });
            }
        });
    };

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    clickAway = () => {
        this.setState(({openPopper}) => ({
            openPopper: !openPopper
        }));
    };

    onChange = (e) => {
        const valueSearch = e.target.value;
        this.setState({valueSearch});
    };

    onKeyUp = (e) => {
        const value = e.target.value;

        if (value.length > 0 && value.indexOf(" ") === -1) {
            this.setState({
                anchorEl: document.getElementById("products_input"),
                openPopper: true,
                blocker: true
            });
        } else {
            this.setState({
                openPopper: false,
                blocker: true
            });
        }
    };

    selectProduct = (e) => {
        this.setState({ valueSearch: e.company, idCompany: e.id, openPopper: false, blocker: false });
    };

    onAmountChange = (e) => {
        this.setState({ amount: e.target.value });
    };

    submitPost = () => {
        const { postTransaction } = this.props;
        const { idCompany, amount } = this.state;
        const data = {
            "company_id": idCompany,
            "amount": amount
        };
        postTransaction(data).then(res => {
            if (res.payload && res.payload.status) {
                this.setState(({open}) => ({
                    open: !open
                }));
                this.doRequest();
            }
            this.setState({loadingBtn: false});
        })
    };

    render(){
        const { valueSearch, anchorEl, openPopper,  open, loading, loadingBtn, amount, blocker } = this.state;
        const { transactions: { all_transactions, all_companies }} = this.props;
        const positiveCheck = '^(?!(?:^[-+]?[0.]+(?:[Ee]|$)))(?!(?:^-))(?:(?:[+-]?)(?=[0123456789.])(?:(?:(?:[0123456789]+)(?:(?:[.])(?:[0123456789]*))?|(?:(?:[.])(?:[0123456789]+))))(?:(?:[Ee])(?:(?:[+-]?)(?:[0123456789]+))|))$';
        let items = [];

        if (loading) return null;
        if ( !!all_companies && all_companies.length > 0 ) {
            items = all_companies.filter( el => !!el.company && el.company !== null ? el.company.toLowerCase().includes(valueSearch.toLowerCase()) : null)
        }

        return (
            <TransitionedBlock>
                <div className="container transactions_page">
                    <div className="flex-center-btw title-block-page">
                        <div className="title-page">
                            <h1>Транзакции</h1>
                        </div>
                        <div className="add_button">
                            <Button
                                onClick = {this.toggleDialog}
                                disableRipple ={true}
                                classes={{
                                    root: 'add_btn'
                                }}
                            >
                                Добавить
                                <img src={AddIcon} alt="edit icon"/>
                            </Button>
                        </div>
                    </div>
                    <div className="page_block">

                        <TransactionPanel
                            type = 'search'
                            doRequest = {this.doRequest}
                        />

                        <TransactionsTable
                            doRequest = {this.doRequest}
                            all_transactions = {all_transactions}
                            all_companies = {all_companies}
                            linkType
                        />

                    </div>
                    <DialogComponent
                        open={open}
                        onClose={this.toggleDialog}
                        classes={classes}
                    >
                        <div className="title-dialog">
                            Пополнить счет
                        </div>
                        <div className="dialog-delete-wrapper">
                            <div className='search_wrapper search_wrapper_small'>
                                <div className="search_product_wrapper">
                                    <input
                                        id="products_input"
                                        type="text"
                                        value={valueSearch}
                                        onChange={(e) => this.onChange(e)}
                                        onKeyUp={(e) => this.onKeyUp(e)}
                                        placeholder="Введите название компании"
                                        autoComplete="off"
                                    />
                                    <PopperInfo
                                        id="products_popper"
                                        open={openPopper}
                                        anchorEl={anchorEl}
                                        clickAway={this.clickAway}
                                        position="bottom-start"
                                        transition
                                    >
                                        <ul className="search_products_list transactions_ul">
                                            {isArray(items) ?
                                                items.map((el, idEl) => (
                                                        <li
                                                            className="search_transactions_item"
                                                            onClick={() => this.selectProduct(el)}
                                                            key={idEl}
                                                        >
                                                            <div className="search_transactions_item_head">
                                                                {el.company}
                                                            </div>
                                                        </li>
                                                    )
                                                )
                                                : <li className="search_transactions_no_items">Компаний не найдено</li>
                                            }
                                        </ul>
                                    </PopperInfo>
                                </div>
                                <div className="search_input_icon">
                                    <img src={SearchIcon} alt="SearchIcon"/>
                                </div>
                            </div>
                            <div className="amount-info-wrapper">
                                <input
                                    id="amount"
                                    type="text"
                                    placeholder="Сумма"
                                    autoComplete="off"
                                    onChange={(e) => this.onAmountChange(e)}
                                />
                                <span>₽</span>
                            </div>
                        </div>
                        <div className="btn-wrapper order-dialog-btn">
                            <DefaultButton
                                variant="outlined"
                                classes="cancel_btn"
                                onClick={this.toggleDialog}
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                disabled={
                                    amount === null ||
                                    blocker ||
                                    amount === '' ||
                                    !amount.match(positiveCheck) ||
                                    loadingBtn
                                }
                                loading={loadingBtn}
                                onClick={() => {
                                    this.setState({loadingBtn: true});
                                    this.submitPost();
                                }}
                            >
                                Пополнить
                            </DefaultButton>
                        </div>
                        <IconButton
                            classes={{
                                root: "dialog_close_button"
                            }}
                            onClick={this.toggleDialog}
                        >
                            <img src={CloseDialogIcon} alt="close icon"/>
                        </IconButton>
                    </DialogComponent>
                </div>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = ({transactions}) => ({
    transactions
});

const mapDispatchToProps = {
    getTransactions,
    getCompanies,
    postTransaction
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);