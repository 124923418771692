import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import PrintComponents from "react-print-components";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import IconButton from "@material-ui/core/IconButton";
import CloseDialogIcon from "../../../assets/image/Mask.png";

import { postAcceptedOrders } from "../../../actions/goodsReceptionActions";

import Loader from "../../HelperComponents/Loader/Loader";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { positiveNumber } from "../../../helpers/validation";
import "./Summary.scss";

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentDialogData: {
        canceled_comment: '',
      },
      open: false,
      modifyedProcessedOrders: [],
      modifyedArrayOfOrders: [],
      originalSum: null,
      printed: false,
      file_type: localStorage.getItem("file_type"),
      loading: false,
      isCommentDialogOpen: false,
      calculatedAmountInProcessedOrders: null
    };
  }

  componentDidMount = () => {
    const { originalSum } = this.props;
    this.setState({ originalSum });
    this.doRequest();
    this.calculatedAmountInProcessedOrdersHandler();
  };

  calculatedAmountInProcessedOrdersHandler = () => {
    const { processedOrders, goods_reception: { orders_history } } = this.props;
    const calculatedAmountInProcessedOrdersLocal = processedOrders.reduce((acc, order) => {
        if (acc[order.row_id]) {
            acc[order.row_id].in_stock += order.in_stock
            return acc
        }

        const allAmount = orders_history.rows.find((row) => row.row_id === order.row_id).amount
        acc[order.row_id] = { 
          in_stock: order.in_stock,
          all_amount: allAmount,
         }
        return acc
    }, {})
    this.setState(prevState => ({
      ...prevState,
      calculatedAmountInProcessedOrders: calculatedAmountInProcessedOrdersLocal
    }))
    return calculatedAmountInProcessedOrdersLocal;
}

  doRequest = () => {
    const { processedOrders, arrayOfOrders, goods_reception: { orders_history } } = this.props;
    const processedAmountRows = this.calculatedAmountInProcessedOrdersHandler();
    const modifyedArrayOfOrders = arrayOfOrders.map(item => ({
      ...item,
      amount_canceled: item.amount,
      comment: '',
      canceled_comment: '',
      order_number: orders_history.order_list.find(el => el.row_id === item.row_id).order_number,
      order_amount: orders_history.order_list.find(el => el.row_id === item.row_id).amount
    }))

    const processedOrderNumbersSet = new Set();    
    const modifyedProcessedOrders = processedOrders.map(item => {
      processedOrderNumbersSet.add(item.order_number)

      return {
        ...item,
        comment: orders_history.order_list.find(el => el.row_id === item.row_id).comment,
        country: orders_history.rows.find(el => el.row_id === item.row_id).country,
        country_num: orders_history.rows.find(el => el.row_id === item.row_id).country_num,
        number_gtd: orders_history.rows.find(el => el.row_id === item.row_id).number_gtd,
        amount_in_stock: item.in_stock,
        amount_canceled: processedAmountRows[item.row_id].all_amount - processedAmountRows[item.row_id].in_stock,
        product: orders_history.rows.find(el => el.row_id === item.row_id).product,
        order_amount: orders_history.order_list.find(el => el.row_id === item.row_id).amount,
        canceled_comment: '',
      }
    })
    orders_history.order_list.forEach((order) => {
      if (!processedOrderNumbersSet.has(order.order_number)) {
        modifyedProcessedOrders.push({
          ...order,
          comment: orders_history.order_list.find(el => el.row_id === order.row_id).comment,
          country: orders_history.rows.find(el => el.row_id === order.row_id).country,
          country_num: orders_history.rows.find(el => el.row_id === order.row_id).country_num,
          number_gtd: orders_history.rows.find(el => el.row_id === order.row_id).number_gtd,
          amount_in_stock: 0,
          amount_canceled: !processedAmountRows[order.row_id]
            ? orders_history.rows.find(el => el.row_id === order.row_id).amount
            : processedAmountRows[order.row_id].all_amount - processedAmountRows[order.row_id].in_stock,
          product: orders_history.rows.find(el => el.row_id === order.row_id).product,
          order_amount: orders_history.order_list.find(el => el.row_id === order.row_id).amount,
          canceled_comment: '',
        })
      }
    })
    this.setState({modifyedArrayOfOrders, modifyedProcessedOrders})
  };

  toggleDialog = () => {
    this.setState(({ open }) => ({
      open: !open,
    }));
  };

  setCommentDialogData = (order) => {
    if (order && Object.entries(order).length) {
      this.setState({  commentDialogData: order });
    }
  };

  toggleCommentDialog = () => {
    this.setState(({ isCommentDialogOpen }) => ({
      isCommentDialogOpen: !isCommentDialogOpen
    }));
  };

  onChangeComment = (e, order) => {
    const { modifyedArrayOfOrders, modifyedProcessedOrders } = this.state;
    order.canceled_comment = e.target.value
    modifyedArrayOfOrders.find((modifyedOrder) => modifyedOrder.order_number === order.order_number).canceled_comment = e.target.value
    modifyedProcessedOrders.find((processedOrder) => processedOrder.order_number === order.order_number).canceled_comment = e.target.value
    this.setState({ commentDialogData: order, modifyedArrayOfOrders, modifyedProcessedOrders });
  };
  
  onChangeAmountCanceled = (e, order) => {
    if (e === '-' && order.amount_canceled && order.amount_canceled !== 0) {
      order.amount_canceled--
    } else if (e === '+') {
      order.amount_canceled++
    } else if (e !== '-' && e !== '+' && positiveNumber(e.target.value)) {
      order.amount_canceled = Number(e.target.value)
    }
    this.setState({ commentDialogData: order });
  };

  handlePost = () => {
    const {
      postAcceptedOrders,
      sum,
      upd_number,
      changeTab,
      clearProcessed,
      goods_reception: { orders_history },
    } = this.props;
    const { modifyedProcessedOrders } = this.state;
    const data = {
      sum: sum,
      upd_number: upd_number,
      accepted_orders: modifyedProcessedOrders,
      supplier: orders_history.supplier,
      upd_date: orders_history.upd_date,
      supplier_inn: orders_history.supplier_inn,
      total_tax: orders_history.total_tax,
    };
    postAcceptedOrders(data).then(() => {
      localStorage.removeItem("file_type");
      setTimeout(() => {
        clearProcessed();
        changeTab(0);
        this.setState({ loading: false });
      }, 500);
    });
  };

  handlePrint = () => {
    this.setState({ printed: true });
  };

  handleDialogPrint = () => {
    const button = document.getElementById("print_button");
    button.click();
  };

  render() {
    const { changeTab } = this.props;
    const { originalSum, open, printed, loading, isCommentDialogOpen, commentDialogData, modifyedArrayOfOrders, modifyedProcessedOrders } = this.state;
    let sum = 0;
    if (!!modifyedProcessedOrders) {
      modifyedProcessedOrders.forEach((el) => {
        sum += Number(el.amount_in_stock);
      });
    }
    if (originalSum === 0 || sum === 0) return <Loader />;
    return (
      <div className="summary_page">
        <div className="summary_table">
          {
            modifyedProcessedOrders &&
            modifyedProcessedOrders.length !== 0 &&
            <Fragment>
              <div className="table_container transactions_columns">
                <div className="title_table">Принятые товары</div>
                <div className="table_header">
                  <div className="table_row">
                    <div className="row_item" style={{ width: "10%" }}>Заказ</div>
                    <div className="row_item" style={{ width: "20%" }}>
                      Деталь
                    </div>
                    <div className="row_item" style={{ width: "35%" }}>
                      Наименование
                    </div>
                    {/*{file_type === "1" && <div className="row_item">Комментарий</div>}*/}
                    <div className="row_item" style={{ width: "10%" }}>К-во товара</div>
                    <div className="row_item" style={{ width: "10%" }}>Принято</div>
                    <div className="row_item" style={{ width: "15%" }}></div>
                  </div>
                </div>
                <div className="table_body">
                  {
                    modifyedProcessedOrders
                    .map((el, idEl) => {
                      return (
                        el.amount_in_stock > 0 ?
                          <div className="table_row" key={idEl}>
                            <div className="row_item" style={{ width: "10%" }}>{el.order_number}</div>
                            <div className="row_item" style={{ width: "20%" }}>
                              {el.article_number} &nbsp;•&nbsp; {el.producer}
                            </div>
                            <div className="row_item" style={{ width: "35%" }}>{el.product}</div>
                            <div className="row_item" style={{ width: "10%" }}>{el.order_amount}</div>
                            <div className="row_item" style={{ width: "10%" }}>
                              <div
                                className="green_text"
                              >
                                {el.amount_in_stock}
                              </div>
                            </div>
                            <div className="row_item" style={{ width: "15%" }}>
                            </div>
                          </div>
                          : null
                        )
                      })
                    }
                </div>
              </div>
            </Fragment>
          }
          {
            modifyedArrayOfOrders &&
            modifyedArrayOfOrders.length !== 0 &&
            <Fragment>
              <div className="table_container transactions_columns">
                <div className="title_table mt-20">Непринятые товары</div>
                <div className="table_header">
                  <div className="table_row">
                    <div className="row_item" style={{ width: "10%" }}>Заказ</div>
                    <div className="row_item" style={{ width: "20%" }}>
                      Деталь
                    </div>
                    <div className="row_item" style={{ width: "35%" }}>
                      Наименование
                    </div>
                    {/*{file_type === "1" && <div className="row_item">Комментарий</div>}*/}
                    <div className="row_item" style={{ width: "10%" }}>К-во товара</div>
                    <div className="row_item" style={{ width: "10%" }}>Не принято</div>
                    <div className="row_item" style={{ width: "15%" }}>Комментарий</div>
                  </div>
                  </div>
                <div className="table_body">
                  {
                    modifyedArrayOfOrders
                    .map((el, idEl) => (
                      <div className="table_row" key={idEl}>
                        <div className="row_item" style={{ width: "10%" }}>{el.order_number}</div>
                        <div className="row_item" style={{ width: "20%" }}>
                          {el.article_number} &nbsp;•&nbsp; {el.producer}
                        </div>
                        <div className="row_item" style={{ width: "35%" }}>{el.product}</div>
                        <div className="row_item" style={{ width: "10%" }}>{el.order_amount}</div>
                        <div className="row_item" style={{ width: "10%" }}>
                          <div
                            className="red_text"
                          >
                            {el.amount_canceled}
                          </div>
                        </div>

                        <div className="row_item" style={{ width: "15%" }}>
                        <DefaultButton
                          onClick={() => {
                            this.setCommentDialogData(el)
                            this.toggleCommentDialog()
                          }}
                          >
                          Комментарий
                        </DefaultButton>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </Fragment>
          }
          <div className="info_summary">
            <div className="title_table mt-20">Итог</div>
            <div>
              <span>Товаров в накладной: </span>
              <span>{originalSum}</span>
            </div>
            <div>
              <span>Принято товаров: </span>
              <span
                className={`${
                  sum === originalSum ? "green_text" : "red_text"
                } `}
              >
                {sum}
              </span>
            </div>
          </div>
          <div className="btn_wrapper">
            <div>
              <DefaultButton
                variant="outlined"
                classes="cancel_btn"
                onClick={() => {
                  changeTab(1)
                  this.props.clearProcessed()
                }}
              >
                Назад
              </DefaultButton>
              <DefaultButton
                onClick={() => {
                  if (!printed || originalSum !== sum) {
                    this.toggleDialog();
                  } else {
                    this.setState({ loading: true });
                    this.handlePost();
                  }
                  this.props.clearProcessed();
                }}
                loading={loading}
                disabled={loading}
              >
                Завершить сеанс
              </DefaultButton>
            </div>
            <div onClick={this.handlePrint}>
              <PrintComponents
                trigger={
                  <button id="print_button" className="print_btn">
                    Печать
                  </button>
                }
              >
                <Fragment>
                  {
                    modifyedProcessedOrders &&
                    modifyedProcessedOrders.length !== 0 &&
                    <Fragment>
                      <div className="table_container transactions_columns">
                        <div className="title_table">Принятые товары</div>
                        <div className="table_header">
                          <div className="table_row">
                            <div className="row_item" style={{ width: "10%" }}>Заказ</div>
                            <div className="row_item" style={{ width: "20%" }}>
                              Деталь
                            </div>
                            <div className="row_item" style={{ width: "35%" }}>
                              Наименование
                            </div>
                            {/*{file_type === "1" && <div className="row_item">Комментарий</div>}*/}
                            <div className="row_item" style={{ width: "10%" }}>К-во товара</div>
                            <div className="row_item" style={{ width: "10%" }}>Принято</div>
                            <div className="row_item" style={{ width: "15%" }}></div>
                          </div>
                        </div>
                        <div className="table_body">
                          {
                            modifyedProcessedOrders
                            .map((el, idEl) => (
                              <div className="table_row" key={idEl}>
                                <div className="row_item" style={{ width: "10%" }}>{el.order_number}</div>
                                <div className="row_item" style={{ width: "20%" }}>
                                  {el.article_number} &nbsp;•&nbsp; {el.producer}
                                </div>
                                <div className="row_item" style={{ width: "35%" }}>{el.product}</div>
                                <div className="row_item" style={{ width: "10%" }}>{el.order_amount}</div>
                                <div className="row_item" style={{ width: "10%" }}>
                                  <div
                                    className="green_text"
                                  >
                                    {el.amount_in_stock}
                                  </div>
                                </div>
                                <div className="row_item" style={{ width: "15%" }}>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </Fragment>
                  }
                  {
                    modifyedArrayOfOrders &&
                    modifyedArrayOfOrders.length !== 0 &&
                    <Fragment>
                      <div className="table_container transactions_columns">
                        <div className="title_table mt-20">Непринятые товары</div>
                        <div className="table_header">
                          <div className="table_row">
                            <div className="row_item" style={{ width: "10%" }}>Заказ</div>
                            <div className="row_item" style={{ width: "20%" }}>
                              Деталь
                            </div>
                            <div className="row_item" style={{ width: "35%" }}>
                              Наименование
                            </div>
                            {/*{file_type === "1" && <div className="row_item">Комментарий</div>}*/}
                            <div className="row_item" style={{ width: "10%" }}>К-во товара</div>
                            <div className="row_item" style={{ width: "10%" }}>Не принято</div>
                            <div className="row_item" style={{ width: "15%" }}>Комментарий</div>
                          </div>
                          </div>
                        <div className="table_body">
                          {
                            modifyedArrayOfOrders
                            .map((el, idEl) => (
                              <div className="table_row" key={idEl}>
                                <div className="row_item" style={{ width: "10%" }}>{el.order_number}</div>
                                <div className="row_item" style={{ width: "20%" }}>
                                  {el.article_number} &nbsp;•&nbsp; {el.producer}
                                </div>
                                <div className="row_item" style={{ width: "35%" }}>{el.product}</div>
                                <div className="row_item" style={{ width: "10%" }}>{el.order_amount}</div>
                                <div className="row_item" style={{ width: "10%" }}>
                                  <div
                                    className="red_text"
                                  >
                                    {el.amount_canceled}
                                  </div>
                                </div>
                                <div className="row_item" style={{ width: "15%" }}>{el.canceled_comment}</div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </Fragment>
                  }
                  <div className="info_summary">
                    <div className="title_table mt-20">Итог</div>
                    <div>
                      <span>Товаров в накладной: </span>
                      <span>{originalSum}</span>
                    </div>
                    <div>
                      <span>Принято товаров: </span>
                      <span
                        className={`${
                          sum === originalSum ? "green_text" : "red_text"
                        } `}
                      >
                        {sum}
                      </span>
                    </div>
                  </div>
                </Fragment>
              </PrintComponents>
            </div>
            </div>
            <DialogComponent open={open} onClose={this.toggleDialog}>
              <div className="title-dialog">Завершить сеанс</div>
              {Number(originalSum) !== Number(sum) ? (
                <div className="descriptions_dialog_component no_margin">
                  Внимание! Количество товаров в накладной отличается от
                  количества принятых товаров. Вы уверены, что всё равно хотите
                  завершить сеанс приёма товара?
                </div>
              ) : (
                <div className="descriptions_dialog_component  no_margin">
                  Вы уверены, что не хотите распечатать отчёт?
                </div>
              )}
              <div className="btn-wrapper order-dialog-btn more-dialog-btn">
                <div>
                  <DefaultButton
                    variant="outlined"
                    classes="cancel_btn"
                    onClick={this.toggleDialog}
                  >
                    Отмена
                  </DefaultButton>
                  <DefaultButton
                    onClick={() => {
                      this.setState({ loading: true });
                      this.handlePost();
                    }}
                    loading={loading}
                    disabled={loading}
                  >
                    Завершить
                  </DefaultButton>
                </div>
                {!printed ? (
                  <div>
                    <div>
                      <button
                        className="print_btn"
                        onClick={this.handleDialogPrint}
                      >
                        Печать
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
              <IconButton
                classes={{
                  root: "dialog_close_button",
                }}
                onClick={this.toggleDialog}
              >
                <img src={CloseDialogIcon} alt="close icon" />
              </IconButton>
            </DialogComponent>

            <DialogComponent open={isCommentDialogOpen} onClose={this.toggleCommentDialog}>
              <div className="title-dialog">Комментарий к заказу {commentDialogData.order_number}</div>
              <div className="descriptions_dialog_component  no_margin">
                {/* <div className="flex align-items-center gap-20 mt-10">
                  <span>Введите количество непринятых товаров</span>
                  <div className="amount">
                    <button
                        onClick={() => this.onChangeAmountCanceled('-', commentDialogData)}
                    >
                        <img src={MinusIcon} alt="-" />
                    </button>
                    <input
                        type="number"
                        value={commentDialogData.amount_canceled}
                        onChange = {(e) => this.onChangeAmountCanceled(e, commentDialogData)}
                    />
                    <button
                        onClick={() => this.onChangeAmountCanceled('+', commentDialogData)}
                    >
                        <img src={PlusIcon} alt="+" />
                    </button>
                  </div>
                </div> */}
                <TextareaAutosize
                  id="quantity_dialog" 
                  className="mt-10"
                  placeholder="Введите комментарий"
                  value={commentDialogData.canceled_comment}
                  onChange={(e) => this.onChangeComment(e, commentDialogData)}
                  />
                </div>
              <div className="btn-wrapper order-dialog-btn more-dialog-btn">
                <div>
                  <DefaultButton
                    onClick={this.toggleCommentDialog}
                  >
                    ОК
                  </DefaultButton>
                </div>
              </div>
              <IconButton
                classes={{
                  root: "dialog_close_button",
                }}
                onClick={this.toggleCommentDialog}
              >
                <img src={CloseDialogIcon} alt="close icon" />
              </IconButton>
            </DialogComponent>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    goods_reception: state.goods_reception,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postAcceptedOrders,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary);