import * as types from "../actions/constants";

const INITIAL_STATE = {
    user_info: {},
    dashboard: {},
    notifications: [],
    info_company: {},
    info_contacts: [],
    info_contacts_loaded: false,
    info_addresses: [],
    info_addresses_loaded: false,

    offers: {},
    black_list: [],


    error: []
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.USER_SUCCESS :
            return {...state, user_info : action.payload.data};
        case types.DASHBOARD_SUCCESS :
            return {...state, dashboard : action.payload.data};
        case types.NOTIFICATION_SUCCESS :
            return {...state, notifications : action.payload.data};
        case types.INFO_SUCCESS :
            return {...state, info_company : action.payload.data};
        case types.CHANGE_INFO_SUCCESS :
            return {...state, info_company : action.payload.data};
        case types.INFO_CONTACTS_SUCCESS :
            return {...state, info_contacts : action.payload.data, info_contacts_loaded: true};
        case types.NEW_CONTACT_SUCCESS :
            return {...state, info_contacts : [ ...state.info_contacts, action.payload.data]};
        case types.CHANGE_CONTACT_SUCCESS :
            let changeContactObj = state.info_contacts;
            changeContactObj[changeContactObj.findIndex(x => x.id === action.payload.data.id)] = action.payload.data;
            return {...state, info_contacts : changeContactObj};
        case types.DELETE_CONTACT_SUCCESS :
            let id = Number(action.meta.previousAction.payload.request.url.split('/')[3]);
            return {...state, info_contacts : state.info_contacts.filter(el => el.id !== id)};
        case types.INFO_ADDRESSES_SUCCESS :
            return {...state, info_addresses : action.payload.data, info_addresses_loaded: true};
        case types.NEW_ADDRESS_SUCCESS :
            return {...state, info_addresses : [ ...state.info_addresses, action.payload.data]};
        case types.CHANGE_ADDRESS_SUCCESS :
            let changeAddressObj = state.info_addresses;
            changeAddressObj[changeAddressObj.findIndex(x => x.id === action.payload.data.id)] = action.payload.data;
            return {...state, info_addresses : changeAddressObj};
        case types.DELETE_ADDRESS_SUCCESS :
            let idx = Number(action.meta.previousAction.payload.request.url.split('/')[3]);
            return {...state, info_addresses : state.info_addresses.filter(el => el.id !== idx)};


        ///////////////// BlackList //////////////////////

        case types.GET_BLACKLIST_OFFERS_SUCCESS :
            return {...state, black_list : action.payload.data};
        case types.POST_PRODUCT_TO_BLACKLIST_SUCCESS:
            let listProducts = state.black_list;
            let objList = {
                "id": action.payload.data.id
            };
            listProducts.push(objList);
            return {...state, black_list : listProducts};
        case types.DELETE_PRODUCT_FROM_BLACKLIST_SUCCESS :
            let index = Number(action.meta.previousAction.payload.request.url.split('/')[3]);
            return {...state, black_list : state.black_list.filter(el => el.id !== index)};


        ///////////////// ERRORS //////////////////////

        case types.INFO_FAIL :
            return {...state, error : action.error.response.data};
        case types.CHANGE_INFO_FAIL :
            return {...state, error : action.error.response.data};
        case types.INFO_CONTACTS_FAIL :
            return {...state, error : action.error.response.data};
        case types.INFO_ADDRESSES_FAIL :
            return {...state, error : action.error.response.data};
        default:
            return state;
    }
}