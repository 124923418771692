import React from 'react';
import './ControlPanel.scss';

const ControlPanel = ({onChange}) => {
    return (
        <form className='control_panel--page-size' onChange={onChange}>
            <div className='radio-wrapper'>
                <input type="radio" id="records-10" name="records-per-page" defaultChecked value="10" />
                <label htmlFor="records-10">10</label>
            </div>
            <div className='radio-wrapper'>
                <input type="radio" id="records-50" name="records-per-page" value="50" />
                <label htmlFor="records-50">50</label>
            </div>
            <div className='radio-wrapper'>
                <input type="radio" id="records-100" name="records-per-page" value="100" />
                <label htmlFor="records-100">100</label>
            </div>
            <div className='radio-wrapper'>
                <input type="radio" id="records-500" name="records-per-page" value="500" />
                <label htmlFor="records-500">500</label>
            </div>
            <div className='radio-wrapper'>
                <input type="radio" id="records-1000" name="records-per-page" value="1000" />
                <label htmlFor="records-1000">1000</label>
            </div>
        </form>
    );
};

export default ControlPanel;