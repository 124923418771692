import React, { Component } from "react";
import PopperInfo from "../PopperInfo/PopperInfo";
import FormControl from "@material-ui/core/FormControl";
import SelectComponent from "../SelectComponent/SelectComponent";
import { isArray } from "../../../helpers/functions";

import SearchIcon from "../../../assets/image/search_grey.png";

import "./PanelPage.scss";

class PanelPage extends Component {
    state = {
        open: false,
        openPopper: false,
        valueSearch: "",
    };

    render() {
        const {
            status,
            projects_list,
            orderingDate,
            ordering_list,
            handleSearch,
            handleChange,
            changeValueSearch,
            ordersPage = false,
            provider,
            provider_list,
        } = this.props;
        const {
            value,
            anchorEl,
            openPopper,
        } = this.state;

        return (
            <div className="panel_page">
                <div className="search_wrapper search_transactions_panel">
                    <div className="search_product_wrapper">
                        <input
                            id="products"
                            type="text"
                            value={value}
                            onKeyDown={(e) => handleSearch(e)}
                            onChange={(e) => changeValueSearch(e)}
                            placeholder="Введите название компании, номер заказа или артикул"
                            autoComplete="off"
                        />
                        <PopperInfo
                            id="products_popper"
                            open={openPopper}
                            anchorEl={anchorEl}
                            clickAway={this.clickAway}
                            position="bottom-start"
                            transition
                        >
                            <ul className="search_products_list">
                                <li
                                    className="search_product_item"
                                >
                                    <div className="search_product_item_head">
                                        products_list
                                    </div>
                                </li>
                                {/*<li>Нет товаров</li>*/}
                            </ul>
                        </PopperInfo>
                    </div>
                    <div className="search_input_icon">
                        <img
                            src={SearchIcon}
                            alt="SearchIcon"
                            onClick={() => handleSearch("iconClick")}
                        />
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {ordersPage && (
                        <FormControl className="select_wrapper">
                            <SelectComponent
                                value={orderingDate}
                                options={ordering_list}
                                loading={!isArray(ordering_list)}
                                change={(event) => handleChange("orderingDate", event)}
                                placeholder="Сортировка по сроку"
                                isSearchable={false}
                            />
                        </FormControl>
                    )}
                    <FormControl className="select_wrapper ml20px">
                        <SelectComponent
                            value={provider}
                            options={provider_list}
                            loading={!isArray(provider_list)}
                            change={(event) => handleChange("provider", event)}
                            placeholder="Поставщик"
                            isSearchable={false}
                        />
                    </FormControl>                                      
                    <FormControl className="select_wrapper ml20px">
                        <SelectComponent
                            value={status}
                            options={projects_list}
                            loading={!isArray(projects_list)}
                            change={(event) => handleChange("status", event)}
                            placeholder="Статус"
                            isSearchable={false}
                        />
                    </FormControl>
                </div>
            </div>
        );
    }
}

export default PanelPage;
