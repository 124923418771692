import * as types from './constants.jsx';

//user actions

export function getUser() {
    return {
        type: types.USER,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/users/`,
                method: "get"
            }
        }
    };
}

export function getNotifications() {
    return {
        type: types.NOTIFICATION,
        payload: {
            client: 'default',
            request: {
                url: `/notification/producer/`,
                method: "get"
            }
        }
    };
}

export function getInfo() {
    return {
        type: types.INFO,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/totalinfo/`,
                method: "get"
            }
        }
    };
}

export function patchInfo(data) {
    return {
        type: types.CHANGE_INFO,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/totalinfo/`,
                method: "patch",
                data
            }
        }
    };
}

export function getContacts() {
    return {
        type: types.INFO_CONTACTS,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/phones/`,
                method: "get"
            }
        }
    };
}

export function postNewContact(data) {
    return {
        type: types.NEW_CONTACT,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/phones/`,
                method: "post",
                data
            }
        }
    };
}

export function patchContact(id, data) {
    return {
        type: types.CHANGE_CONTACT,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/phone/${id}/`,
                method: "patch",
                data
            }
        }
    };
}

export function deleteContact(id) {
    return {
        type: types.DELETE_CONTACT,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/phone_delete/${id}`,
                method: "delete"
            }
        }
    };
}

export function getAddresses() {
    return {
        type: types.INFO_ADDRESSES,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/addresses/`,
                method: "get"
            }
        }
    };
}

export function getDashboard() {
    return {
        type: types.DASHBOARD,
        payload: {
            client: 'default',
            request: {
                url: `/dashboard/`,
                method: "get"
            }
        }
    };
}

export function postNewAddress(data) {
    return {
        type: types.NEW_ADDRESS,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/addresses/`,
                method: "post",
                data
            }
        }
    };
}

export function patchAddress(id, data) {
    return {
        type: types.CHANGE_ADDRESS,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/address/${id}/`,
                method: "patch",
                data
            }
        }
    };
}

export function deleteAddress(id) {
    return {
        type: types.DELETE_ADDRESS,
        payload: {
            client: 'default',
            request: {
                url: `/accounts/address_delete/${id}`,
                method: "delete"
            }
        }
    };
}

///////////////// BlackList //////////////////////

export function getProducts(query) {
    return {
        type: types.GET_PRODUCTS,
        payload: {
            client: 'default',
            request: {
                url: `/superadmin/black_list_search?article_number=${query}`,
                method: "get"
            }
        }
    };
}

export function getBlacklistOffers() {
    return {
        type: types.GET_BLACKLIST_OFFERS,
        payload: {
            client: 'default',
            request: {
                url: `/superadmin/black_list`,
                method: "get"
            }
        }
    };
}

export function postProductToBlacklist(data) {
    return {
        type: types.POST_PRODUCT_TO_BLACKLIST,
        payload: {
            client: 'default',
            request: {
                url: `/superadmin/black_list_action`,
                method: "post",
                data
            }
        }
    };
}

export function deleteProductFromBlacklist(id) {
    return {
        type: types.DELETE_PRODUCT_FROM_BLACKLIST,
        payload: {
            client: 'default',
            request: {
                url: `superadmin/black_list_action?id=${id}`,
                method: "delete"
            }
        }
    };
}