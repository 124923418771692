import React from 'react';
import './BurgerButton.scss';

const BurgerButton = ({children, onClick, open, classes = ''}) => {
    return (
        <div className={open ? "burger-button-default burger-button-default_open" : "burger-button-default" } onClick={onClick}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );


};

export default BurgerButton;
