import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import {bindActionCreators} from 'redux';

import {isArray} from "../../helpers/functions";

import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import NoItems from '../HelperComponents/NoItems/NoItems';
import DefaultButton from '../Buttons/DefaultButton/DefaultButton';
import ButtonLoader from "../Buttons/ButtonLoader/ButtonLoader";
import CheckboxHelper from "./CheckboxHelper/CheckboxHelper";

import {getPriceLists, getPriceListAddresses, changeMarkupPriceList} from "../../actions/priceListActions";

import PricesIcon from "../../assets/image/no_prices.png";
import {postSendFile} from '../../actions/priceListActions';
import {downloadPriceList} from '../../actions/priceListActions';
import './PriceList.scss';


class PriceList extends Component {

    state = {
        loading: true,
        activePage: 1,
        open: [],
        price_lists: [],
        errorId: [],
        markup: 0,
        checkboxObj: {},
        buttonLoader: false
    };


    componentDidMount() {
        const {getPriceLists, getPriceListAddresses, history} = this.props;
        getPriceLists().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({loading: false});
                this.handlerChecked();
            }
            if (res.error && res.error.response.status === 401) {
                localStorage.clear();
                history.push('/auth');
            }
        });

        getPriceListAddresses();


    }
    submitForm = (data) => {
        const {postSendFile, downloadPriceList} = this.props;
        this.setState({ buttonLoader: true });
        let fromationObjectTest = this.testFormation();
        // let fromationObject = this.formationObjectCheckBox();

        // let obj = {
        //     markup: Number(markup),
        //     price_lists: fromationObject
        // };

        postSendFile(fromationObjectTest).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                let urlSplit = res.payload.data.path_file.split('/')[5];
                let host = window.location.host === 'admin.mexcar.ru' ? 'https://api.mexcar.ru/media/' : 'https://api.testmexcar.ru/media/';
                // downloadPriceList(urlSplit).then(res => {
                //     if (res.payload && res.payload.status && res.payload.status === 200) {
                        this.setState({ buttonLoader: false });
                        // window.open(`https://api.supplier.parts.4-com.pro/api/v0/superadmin/download_price_list?download_path=/${urlSplit}`);
                        window.open(`${host}${urlSplit}`, '_blank');
                //     }
                // })
            }
        });
    };

    /*--------------------------------------------Открытие и скрытие блока--------------------------------------------*/

    toggleOpen = id => {
        const {open} = this.state;
        if (open.includes(id)) {
            this.setState(({open}) => ({
                open: open.filter(el => el !== id)
            }));
        } else {
            this.setState(({open}) => ({
                open: [...open, id]
            }));
        }
    };

    /*--------------------------------------------Открытие и скрытие блока--------------------------------------------*/


    toggleCheck = (id, arrChild) => {
        const {checkboxObj} = this.state;
        let arr = arrChild.map(({id}) => {
            return id
        });

        if (!checkboxObj[id]) {
            checkboxObj[id] = [];
        }
        if (checkboxObj[id].length === 0) {
            checkboxObj[id] = [...arr];
        } else {
            delete checkboxObj[id];
        }
        this.setState({
            testCheckboxs: checkboxObj
        });
    };


    toggleChildChange = (parentId, childId, lenghtChild) => {
        const {checkboxObj} = this.state;


        if (!checkboxObj[parentId]) {
            checkboxObj[parentId] = [];
        }

        if (!checkboxObj[parentId].includes(childId)) {
            checkboxObj[parentId].push(childId);
        } else {
            checkboxObj[parentId] = checkboxObj[parentId].filter(el => el !== childId);
        }
        this.setState({
            checkboxObj
        });


    };

    markupChange = (value) => {
        if (!isNaN(Number(value))) {
            if (value > 100) {
                this.setState({markup_isError: true});
            } else {
                this.setState({markup_isError: false});
            }
            this.setState({markup: Number(value)});
        }
    };


    //formation array string
/*    formationObjectCheckBox = () => {
        const {checkboxObj} = this.state;
        let arrTestCheck = [];
        let arrTestCheckStr = '';
        for (let key in checkboxObj) {
            arrTestCheck = [...arrTestCheck, ...checkboxObj[key]]
        }

        arrTestCheckStr = arrTestCheck.join(',');
        return arrTestCheckStr
    };*/

    testFormation = () => {
        const {price_list: {price_lists}} = this.props;
        const {checkboxObj} = this.state;
        let testArr = [];
        let markup = null;
        for (let key in checkboxObj) {
            for (let i = 0; i < price_lists.length; i++){
                if(price_lists[i].id === Number(key)){
                    markup = Number(price_lists[i].markup);
                    break;
                }
            }
            let testObj = {};
            testObj["company_id"] = Number(key);
            testObj["price_lists"] = [...checkboxObj[key]];
            testObj["markup"] = markup;
            testArr.push(testObj);
        }

        return testArr;
    }


    handlerChecked = () => {
        const {price_list: {price_lists}} = this.props;
        const {checkboxObj} = this.state;

        price_lists.forEach(elParent => {
            if (elParent.price_list.length !== 0) {
                elParent.price_list.forEach(elChild => {
                    if (elChild.superadmin_mark) {
                        if (!checkboxObj[elParent.id]) {
                            checkboxObj[elParent.id] = [];
                        }

                        checkboxObj[elParent.id].push(elChild.id);
                    }
                })
            }
        });

        this.setState({
            checkboxObj
        });

    };

    changeValue = (id, value) => {
        const {errorId} = this.state;
        const {changeMarkupPriceList} = this.props;
        changeMarkupPriceList(id, value);
        if(!errorId.includes(id) && Number(value) < -100) {
            this.setState({errorId: [...errorId, id]})
        }else if(errorId.includes(id) && Number(value) >= -100) {
            this.setState({errorId: errorId.filter(id => id !== id)})
        }
    };

    render() {
        const {price_list: {price_lists}, handleSubmit, error} = this.props;
        const { loading, open, checkboxObj, buttonLoader, errorId } = this.state;
        if (loading) return null;
        return (
            <TransitionedBlock>
                <main className="price_list_page container main-container-block">
                    <div className="title-block">
                        <div className="flex-center-btw">
                            <h1>Общий прайс-лист</h1>
                        </div>
                    </div>
                    <div className="content-block">
                        <h3>Прайс-листы поставщиков </h3>
                        <form onSubmit={handleSubmit(this.submitForm)}>
                            <div className="scroll_block">

                                {price_lists && price_lists.length && price_lists.length === 0 ?
                                    <NoItems>
                                        <div className="box-img"><img src={PricesIcon} alt="edit icon"/></div>
                                        <div className="title">Пока нет ни одного прайс-листа</div>
                                    </NoItems>
                                    :
                                    price_lists.map(({company, price_list, id, markup}) => {
                                        return(
                                            price_list.length !== 0 ?
                                                <CheckboxHelper
                                                    key={id}
                                                    id={id}
                                                    markup={markup}
                                                    price_list={price_list}
                                                    company={company}
                                                    toggleOpen={() => this.toggleOpen(id)}
                                                    open={open}
                                                    toggleCheck={() => this.toggleCheck(id, price_list)}
                                                    checkboxObj={this.state.checkboxObj}
                                                    toggleChildChange={this.toggleChildChange}
                                                    changeValue={this.changeValue}
                                                    markup_isError={errorId.includes(id)}
                                                />
                                                : null
                                        )
                                    })
                                }
                            </div>
                            <hr/>
                            <div className="edit_btn_wrapper">
                                <DefaultButton
                                    variant="contained"
                                    // disabled={isArray(!checkboxesChild) || submitting || pristine}
                                    disabled={isArray(!checkboxObj) || Object.keys(checkboxObj).length === 0 || errorId.length > 0 || buttonLoader}
                                    formAction
                                >
                                    {buttonLoader ?
                                        <ButtonLoader/>
                                        :
                                        "Скачать XLSX"
                                    }
                                </DefaultButton>
                                {error && error.text !== null
                                    ? <p className="error_after_button">{error.text}</p>
                                    : null
                                }
                            </div>
                        </form>
                    </div>
                </main>
            </TransitionedBlock>
        );
    }

}


PriceList = reduxForm({
    form: 'PriceList'
})(PriceList);

const selector = formValueSelector('PriceList');

function mapStateToProps(state) {
    const price_lists = selector(state, 'price_lists');
    return {
        price_list: state.price_list,
        price_lists,
        initialValues: {
            // price_lists: (state.orders.order && isArray(state.orders.order.orderproducts) && state.orders.order.orderproducts.map(el => {
            //     let arr = [];
            //     arr.push(el.name);
            //     arr.push(el.article_number);
            //     arr.push(el.producer);
            //     arr.push(`${el.amount} шт`);
            //     arr.push(el.price_list);
            //     arr.push(`${el.price} ₽`);
            //     return {
            //         id: el.offer,
            //         count: el.quantity,
            //         info: arr.join(' • '),
            //         product: {
            //             ...el
            //         }
            //     }
            // })) || '',
            status: (state.orders.order && state.orders.order.status) || '',
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPriceLists,
        getPriceListAddresses,
        postSendFile,
        downloadPriceList,
        changeMarkupPriceList
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceList);


