import * as types from './constants.jsx';

export function getTransactions(arr) {
    return {
        type: types.GET_TRANSACTIONS,
        payload: {
            client: 'default',
            request: {
                url: `/admin/transactions-history/${arr.length && arr.length !== 0 ? '?' : ''}${arr.join('&')}`,
                method: "get"
            }
        }
    };
}

export function getCompanies(arr) {
    return {
        type: types.GET_COMPANIES,
        payload: {
            client: 'default',
            request: {
                url: `/admin/get-company/`,
                method: "get"
            }
        }
    };
}

export function postTransaction(data) {
    return {
        type: types.POST_TRANSACTION,
        payload: {
            client: 'default',
            request: {
                url: `/admin/create-transaction/`,
                method: "post",
                data
            }
        }
    };
}
