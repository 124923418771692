import React, {Fragment} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AuthHead from '../../components/Auth/AuthHead/AuthHead';
import Login from "../../components/Auth/Login/Login";
import NotFound from '../../components/NotFound/NotFound';

import Logo from '../../assets/image/logo.png';
import logo_large from '../../assets/image/logo_large.png';

import './AuthContainer.scss';

const AuthContainer = (props) => {
    const { location, match } = props;
    if(!!localStorage.token) return <Redirect to="/admin" />;
    return (
        <Fragment>
            <AuthHead location={location}/>
            <main className="auth_container">
                <div className="auth-box">
                    <div className="auth_bg">
                        <div className="auth_logo">
                            <img src={logo_large} alt="logo_large" className="logo_large"/>
                            <img src={Logo} alt="logo"/>

                        </div>
                    </div>
                    <div className="auth_content">
                        <Switch>
                            <Route path={match.url} exact component={Login} />
                            <Route component={NotFound} />
                        </Switch>
                    </div>
                </div>
            </main>
        </Fragment>
    );
};

export default AuthContainer;