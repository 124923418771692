import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import './ButtonLoader.scss';

const ButtonLoader = (props) => {
    return (
        <div className="loader_wrapper">
            <CircularProgress size={30} />
        </div>
    );
};

export default ButtonLoader;