import * as types from './constants.jsx';

//orders actions

export function getOrders(arr) {
    return {
        type: types.GET_ORDERS,
        payload: {
            client: 'default',
            request: {
                url: `/admin/japarts/orders/${arr.length && arr.length !== 0 ? '?' : ''}${arr.join('&')}`,
                method: "get"
            }
        }
    };
}

export function getOrder(id) {
    return {
        type: types.GET_ORDER,
        payload: {
            client: 'default',
            request: {
                url: `/admin/japarts/order/${id}`,
                method: "get"
            }
        }
    };
}

export function patchStatusOrder(id, data) {
    return {
        type: types.PATCH_STATUS_ORDER,
        payload: {
            client: 'default',
            request: {
                url: `/admin/japarts/order/${id}`,
                method: "patch",
                data
            }
        }
    };
}


export function changeOrderLocal(data) {
    return {
        type: types.CHANGE_ORDER_LOCAL,
        data
    };
}
