import React, {Component, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import NoItems from '../HelperComponents/NoItems/NoItems';
import {isArray} from "../../helpers/functions";
import {getOrders} from "../../actions/ordersActions";
import { importOrdersFile, getPriceLists, downloadPriceList, getSuppliers, getPriceListsSellers, sendPriceListsSellers } from '../../actions/priceListActions';
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import SnackBar from "../HelperComponents/SnackBar/SnackBar"
import CloseDialogIcon from "../../assets/image/Mask.png";
import FormControl from '@material-ui/core/FormControl';
import SelectComponent from '../HelperComponents/SelectComponent/SelectComponent';
import PricesIcon from "../../assets/image/no_prices.png";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";
import {reduxForm} from "redux-form";
import CheckboxHelper from "../PriceList/CheckboxHelper/CheckboxHelper";
import Loader from "../HelperComponents/Loader/Loader"
import timeSVG from "../../assets/image/time.svg"
import moment from 'moment';
import '../Search/Search.scss';
import './ImportOrders.scss';


class ImportOrders extends Component {
    state = {
        tab: "0",
        open: [],
        loading: false,
        senDataLoading: false,
        valueSearch: "",
        activePage: 1,
        selectFile: null,
        checkboxObj: {},
        isError: false,
        textError: '',
        textSuccess: '',
        modal: false,
        urlSplit: null,
        statusSelect: {},
        projects_list: [],
        waiting_orders: [],
        showSnackBar: false,
        loadingBtn: false,
    };

    changeTab = (tab) => {
        this.setState({ tab });
    };

    /*-------------------------------------------------------componentDidMount------------------------------------------*/
    componentDidMount() {
        const {getPriceLists, getSuppliers, getPriceListsSellers, history} = this.props;

        getPriceLists().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({loading: false});
                this.handlerChecked();
            }
            if (res.error && res.error.response.status === 401) {
                localStorage.clear();
                history.push('/auth');
            }
        });
        getSuppliers().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200){
                // {label: "ОллАвто"}
                // let arrSupplier = [];
                let arrSupplier = res.payload.data.map(supplier => {
                    return {label: supplier.company, value: supplier.id}
                })
                this.setState({projects_list: arrSupplier, statusSelect: arrSupplier[0]})
            }
        })
        getPriceListsSellers().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ waiting_orders: res.payload.data.results});
            }
        })
    }

    /*-------------------------------------------------------componentDidMount------------------------------------------*/


    submitForm = (data) => {
        const {selectFile, statusSelect, tab} = this.state;
        const {importOrdersFile, history} = this.props;
        let fromationObject = this.formationObjectCheckBox();
        let supplier_id = statusSelect ? statusSelect.value : statusSelect;
        const supplier_name = tab === '0' ? statusSelect.label : 'Заказ';
        let formData = new FormData();
        formData.append('file', selectFile);
        formData.append('price_lists', fromationObject);
        formData.append('supplier_name', supplier_name);
        let objState = {
            file: selectFile,
            price_lists: fromationObject,
        };
        if(supplier_id){
            objState.supplier_id = supplier_id;
        }
        this.setState({senDataLoading: true});
        importOrdersFile(formData).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200 && res.payload.data.undistributed_download_path) {
                this.setState({modal: true, urlSplit: res.payload.data.undistributed_download_path});
                this.setState({
                    isError: false,
                    textSuccess: res.payload.data.status,
                    senDataLoading: false
                });
                history.push({pathname: '/admin/import-orders-inner', state: objState})
            }else if(res.payload && res.payload.status && res.payload.status === 200 && !res.payload.data.undistributed_download_path){
                this.setState({
                    isError: false,
                    textSuccess: res.payload.data.status
                });
                setTimeout(() => {
                    this.setState({textSuccess: '', })
                }, 2000);
                this.setState({senDataLoading: false});
                history.push({pathname: '/admin/import-orders-inner', state: objState})
            }else{
                this.setState({
                    isError: true,
                    textError: res.error.response.data.file,
                    senDataLoading: false
                });

                setTimeout(() => {
                    this.setState({textError: ''})
                }, 2000)
            }
        });
    };

    loadOrders  = () => {
        const {urlSplit} = this.state;
        window.open(`https://api.supplier.parts.4-com.pro/media${urlSplit}`);
        this.setState({modal: false, urlSplit: null});
        setTimeout(() => {
            this.setState({textSuccess: ''});
        }, 2000)
    };

    toggleCheck = (id, arrChild) => {
        const {checkboxObj} = this.state;
        let arr = arrChild.map(({id}) => {
            return id
        });

        if (!checkboxObj[id]) {
            checkboxObj[id] = [];
        }
        if (checkboxObj[id].length === 0) {
            checkboxObj[id] = [...arr];
        } else {
            delete checkboxObj[id];
        }
        this.setState({
            testCheckboxs: checkboxObj
        });
    };

    toggleChildChange = (parentId, childId, lenghtChild) => {
        const {checkboxObj} = this.state;

        if (!checkboxObj[parentId]) {
            checkboxObj[parentId] = [];
        }

        if (!checkboxObj[parentId].includes(childId)) {
            checkboxObj[parentId].push(childId);
        } else {
            checkboxObj[parentId] = checkboxObj[parentId].filter(el => el !== childId);
        }

        this.setState({
            checkboxObj
        });
    };

    toggleOpen = id => {
        const {open} = this.state;
        if (open.includes(id)) {
            this.setState(({open}) => ({
                open: open.filter(el => el !== id)
            }));
        } else {
            this.setState(({open}) => ({
                open: [...open, id]
            }));
        }
    };

    addFile = e => {
        e.preventDefault();
        let selectFile = e.target.files[0];
        this.setState({selectFile});
    };

    handlerChecked = () => {
        const {price_list: {price_lists}} = this.props;
        const {checkboxObj} = this.state;

        price_lists.forEach(elParent => {
            if (elParent.price_list.length !== 0) {
                elParent.price_list.forEach(elChild => {
                    if (elChild.superadmin_mark) {
                        if (!checkboxObj[elParent.id]) {
                            checkboxObj[elParent.id] = [];
                        }

                        checkboxObj[elParent.id].push(elChild.id);
                    }
                })
            }
        });

        this.setState({
            checkboxObj
        });

    };

    formationObjectCheckBox = () => {
        const {checkboxObj} = this.state;
        let arrTestCheck = [];
        let arrTestCheckStr = '';
        for (let key in checkboxObj) {
            arrTestCheck = [...arrTestCheck, ...checkboxObj[key]]
        }
        arrTestCheckStr = arrTestCheck.join(',');
        return arrTestCheckStr
    };

    toggleDialog = () => {
        this.setState({
            modal: !this.state.modal
        })
    };

    handleChange = name => event => {
        this.setState({ [name]: event});
    };

    sendEmail = async (index, id) => {
        const { sendPriceListsSellers } = this.props;
        const {waiting_orders} = this.state
        sendPriceListsSellers({id: id}).then((res) => {
            if (res.payload && res.payload.status === 200) {
                waiting_orders.splice(index, 1);
                this.setState({ waiting_orders: waiting_orders });
            } else {
                this.setState({ showSnackBar: true });
            }
            this.setState({loadingBtn: false})
        });
    }

    toggleSnack = () => {
        this.setState({ showSnackBar: false });
    };

    render() {
        const { price_list: {price_lists}, handleSubmit} = this.props;
        const { open, selectFile, tab, checkboxObj, projects_list, statusSelect, senDataLoading, waiting_orders, showSnackBar, loadingBtn } = this.state;

        return (
            <TransitionedBlock>
                <main className="import_order_page container orders-main-block">
                    <div className="title-block">
                        <h1>Импорт заказов</h1>
                    </div>

                    <div className="dashboard-section_content dashboard-import-orders">

                        <div className="tab_custom">
                            <button
                                className={tab === "0" ? "active" : ""}
                                onClick={() => this.changeTab("0")}
                            >
                                Закупка
                            </button>
                            <button
                                className={tab === "1" ? "active" : ""}
                                onClick={() => this.changeTab("1")}
                            >
                                Заказ
                            </button>
                            <button
                                className={tab === "2" ? "active" : ""}
                                onClick={() => this.changeTab("2")}
                            >
                                Ожидают отправки
                            </button>                           
                        </div>

                        {tab === "0" &&
                        <div className="purchase">
                            <div className="wrapper-upload">
                                <p>Загрузка файла</p>
                                <div className="form-control">
                                    <div>
                                        <label className="form-input--file">
                                            <span className="form-input--file-text">{selectFile ? selectFile.name : 'Выберите файл'}</span>
                                            <span className="form-input--file-button">Выбрать</span>
                                            <input className="form-input-file" type="file" id="file"
                                                   size="14" onChange={this.addFile} placeholder={'asddas'} name="file"/>
                                        </label>
                                    </div>
                                </div>
                                <div className="drop_down_block">
                                    <span>Поставщик</span>
                                    <FormControl className="select_wrapper">
                                        <SelectComponent
                                            value={statusSelect}
                                            options={projects_list}
                                            change={this.handleChange("statusSelect")}
                                            placeholder=""
                                            isSearchable = {false}
                                        />
                                    </FormControl>
                                </div>


                            </div>
                            <hr/>
                            <div className="">
                                <h3>Прайс-листы поставщиков </h3>
                                <form onSubmit={handleSubmit(this.submitForm)}>
                                    <div className="scroll_block">

                                        {price_lists && price_lists.length && price_lists.length === 0 ?
                                            <NoItems>
                                                <div className="box-img"><img src={PricesIcon} alt="edit icon"/></div>
                                                <div className="title">Пока нет ни одного прайс-листа</div>
                                            </NoItems>
                                            :
                                            price_lists.map(({company, price_list, id}) => {
                                                return(
                                                    price_list.length !== 0 ?
                                                        <CheckboxHelper
                                                            key={id}
                                                            id={id}
                                                            price_list={price_list}
                                                            company={company}
                                                            toggleOpen={() => this.toggleOpen(id)}
                                                            open={open}
                                                            toggleCheck={() => this.toggleCheck(id, price_list)}
                                                            checkboxObj={this.state.checkboxObj}
                                                            toggleChildChange={this.toggleChildChange}
                                                        />
                                                        : null
                                                )
                                            })
                                        }
                                    </div>
                                    <hr/>
                                    <div className="edit_btn_wrapper">
                                        <DefaultButton
                                            variant="contained"
                                            disabled={isArray(!checkboxObj) || Object.keys(checkboxObj).length === 0 || selectFile === null || statusSelect === null}
                                            formAction
                                            Link
                                            to={`/admin/import-orders-inner`}
                                        >
                                            {
                                                senDataLoading ? <Loader/>  : 'Далее'
                                            }

                                        </DefaultButton>
                                        {this.state.isError || this.state.modal
                                            ? <p className="error_after_button">{this.state.textError}</p>
                                            : <p className="success_after_button">{this.state.textSuccess}</p>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                        }
                        {tab === "1" &&
                        <div className="Order">
                            <div className="wrapper-upload">
                                <p>Загрузка файла</p>
                                <div className="form-control">
                                    <div>
                                        <label className="form-input--file">
                                            <span className="form-input--file-text">{selectFile ? selectFile.name : 'Выберите файл'}</span>
                                            <span className="form-input--file-button">Выбрать</span>
                                            <input className="form-input-file" type="file" id="file"
                                                   size="14" onChange={this.addFile} placeholder={'asddas'} name="file"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="">
                                <h3>Прайс-листы поставщиков </h3>
                                <form onSubmit={handleSubmit(this.submitForm)}>
                                    <div className="scroll_block">

                                        {price_lists && price_lists.length && price_lists.length === 0 ?
                                            <NoItems>
                                                <div className="box-img"><img src={PricesIcon} alt="edit icon"/></div>
                                                <div className="title">Пока нет ни одного прайс-листа</div>
                                            </NoItems>
                                            :
                                            price_lists.map(({company, price_list, id}) => {
                                                return(
                                                    price_list.length !== 0 ?
                                                        <CheckboxHelper
                                                            key={id}
                                                            id={id}
                                                            price_list={price_list}
                                                            company={company}
                                                            toggleOpen={() => this.toggleOpen(id)}
                                                            open={open}
                                                            toggleCheck={() => this.toggleCheck(id, price_list)}
                                                            checkboxObj={this.state.checkboxObj}
                                                            toggleChildChange={this.toggleChildChange}
                                                        />
                                                        : null
                                                )
                                            })
                                        }
                                    </div>
                                    <hr/>
                                    <div className="edit_btn_wrapper">
                                        <DefaultButton
                                            variant="contained"
                                            disabled={isArray(!checkboxObj) || Object.keys(checkboxObj).length === 0 || selectFile === null}
                                            formAction
                                            Link
                                            to={`/admin/import-orders-inner`}
                                        >
                                            {
                                                senDataLoading ? <Loader/>  : 'Далее'
                                            }
                                        </DefaultButton>
                                        {this.state.isError || this.state.modal
                                            ? <p className="error_after_button">{this.state.textError}</p>
                                            : <p className="success_after_button">{this.state.textSuccess}</p>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                        }
                        {tab === "2" &&
                        <div>
                            <h3>Ожидают</h3>
                            <div className='scroll_block'>
                                {waiting_orders.length === 0 ?
                                    <NoItems>
                                        <div className="box-img"><img src={PricesIcon} alt="edit icon"/></div>
                                        <div className="title">Нет заказов для отправки</div>
                                    </NoItems>
                                    :
                                    waiting_orders.map((item, index) => {
                                        return(
                                            <div className="waiting-company" key={item.id}>
                                                <div className='item-content flex-center'>
                                                    <div className='name-company' onClick={() => this.toggleOpen(item.id)}>{item.company}</div>
                                                    <IconButton onClick={() => this.toggleOpen(item.id)}>
                                                        {open.includes(item.id) ? (
                                                            <ExpandLessIcon />
                                                        ) : (
                                                            <ExpandMoreIcon />
                                                        )}
                                                    </IconButton>
                                                    <button 
                                                        onClick={() => {
                                                            this.setState({loadingBtn: true});
                                                            this.sendEmail(index, item.id);
                                                        }}
                                                        loading={loadingBtn}
                                                        disabled={loadingBtn}
                                                        className='waiting-company__btn'
                                                        > ОТПРАВИТЬ EMAIL С ЗАКАЗАМИ
                                                    </button>
                                                </div>
                                                <div className='expansion-body flex-center' style={{ display: open.includes(item.id) ? "flex" : "none" }}>
                                                    <div className="schedule_map_wrapper">
                                                        {item.companyorders.map((order, index) => {
                                                            return(
                                                                <div className='order' key={index}>
                                                                    {order.price_list !== null ? (
                                                                        <div className='order__name'> {order.price_list}</div>
                                                                    ) : (
                                                                        <div className='order__name'> -</div>
                                                                    )

                                                                    }
                                                                    <img src={timeSVG} className="checkbox-img" />
                                                                    <span className="checkbox-date">
                                                                        {moment(order.date).format(
                                                                            "DD.MM.YYYY HH:mm"
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <TransitionedBlock>
                                <SnackBar
                                    open={showSnackBar}
                                    classes="error"
                                    vertical="top"
                                    onClose={() => this.toggleSnack()}
                                >
                                    Произошла ошибка
                                </SnackBar>
                            </TransitionedBlock>
                            <hr></hr>
                        </div>
                        }
                    </div>
                    <DialogComponent open={this.state.modal}>
                        <div className="import-orders-dialog">
                            <p className="title">Импорт заказов</p>
                            <p>Не все заказы были обработаны. Вы можете скачать документ со списком необработанных заказов.</p>
                            <div className="dialog-button-wrapper">
                                <DefaultButton
                                    variant="outlined"
                                    classes="cancel_btn"
                                    onClick={() => {this.setState({modal: false})}}
                                >
                                    ЗАКРЫТЬ
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.loadOrders}
                                >
                                    СКАЧАТЬ
                                </DefaultButton>
                            </div>
                        </div>
                        <IconButton
                            classes={{
                                root: "dialog_close_button"
                            }}
                            onClick={this.toggleDialog}
                        >
                            <img src={CloseDialogIcon} alt="close icon"/>
                        </IconButton>
                    </DialogComponent>
                    {/*---------------*/}
                </main>
            </TransitionedBlock>
        );
    }
}

ImportOrders = reduxForm({
    form: 'ImportOrders'
})(ImportOrders);

function mapStateToProps(state) {
    return {
        orders: state.orders,
        price_list: state.price_list
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getOrders,
        getPriceLists,
        getPriceListsSellers,
        sendPriceListsSellers,
        importOrdersFile,
        downloadPriceList,
        getSuppliers
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportOrders);