import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import UserReducer from './reducerUser';
import ReviewsReducer from './reducerReviews';
import PriceListReducer from './reducerPriceList';
import OrdersReducer from './reducerOrders';
import TransactionsReducer from './reducerTransactions';
import RefundRequestsReducer from './reducerRefundRequests';
import GoodsReceptionReducer from './reducerGoodsReception';
import DisposalBalancesReducer from './reducerDisposalBalances';
import {reducer as formReducer} from 'redux-form';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    form: formReducer,
    user: UserReducer,
    reviews: ReviewsReducer,
    price_list: PriceListReducer,
    orders: OrdersReducer,
    transactions: TransactionsReducer,
    refund_requests: RefundRequestsReducer,
    goods_reception: GoodsReceptionReducer,
    disposal_balances: DisposalBalancesReducer
});

export default rootReducer;