import * as types from "../actions/constants";

const INITIAL_STATE = {
    addresses_list: [],
    price_list_info: {},
    price_lists: [],
    // price_list_info: {"rows":[["","","","","",""],["#90919-01164","Свеча зажигания","Toyota","163.00","2182 шт.","1-S2"],["#90919-01164","Свеча зажигания","Toyota","163.00","2182 шт.","1-S2"],["#90919-01164","Свеча зажигания","Toyota","163.00","2182 шт.","1-S2"],["#90919-01164","Свеча зажигания","Toyota","163.00","2182 шт.","1-S2"]],"cells":[["article_number","Артикул"],["name","Наименование"],["producer","Производитель"],["price","Цена"],["amount","Количество"],["location","Место хранения"]],"file_name":"asdasd","markup":[["miss","Нет"],["fixate","Фиксированая"],["stepped","Ступенчатая"]],"delivery_time":[["in stock","В наличии"],["custom","Под заказ"]],"representation":5},
    error: []
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.PRICE_LIST_ADDRESSES_SUCCESS :
            let addressesObj = {
                loaded: true,
                data: action.payload.data
            };
            return {...state, addresses_list : addressesObj};
        case types.SET_PRICE_LIST_INFO :
            return {...state, price_list_info : action.data};
        case types.PRICE_LISTS_SUCCESS :
            return {...state, price_lists : action.payload.data};
        case types.PRICE_LIST_ADDRESSES_FAIL :
            return {...state, error : action.error.response.data};
        case types.CHANGE_MARKUP_PRICE_LIST :
            return {...state, price_lists: state.price_lists.map(el => el.id === action.id ? {...el, markup: action.markup} : el)};
        default:
            return state;
    }
}