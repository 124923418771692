import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

export const configureSentry = ({ dsn, dev, prod }) => {
  const host = window.location.host
  let environment
  if (host.includes(dev)) environment = 'development'
  if (host.includes(prod)) environment = 'production'

  if (!host.includes('localhost')) {
    return Sentry.init({
      dsn,
      autoSessionTracking: true,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      environment,
    })
  }
}

export function getRating(rating) {
  switch (rating) {
    case 1:
      return 'Очень плохо'
    case 2:
      return 'Плохо'
    case 3:
      return 'Неплохо'
    case 4:
      return 'Хорошо'
    case 5:
      return 'Отлично'
    default:
      return null
  }
}

export function isArray(arr) {
  return arr && arr.length && arr.length > 0
}

export function splitByCommas(data) {
  return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function getStatus(status) {
  switch (status) {
    case 'new':
      return 'Новый'
    case 'on_way':
      return 'В пути'
    case 'no_payment':
      return 'Нет оплаты'
    case 'processing':
      return 'В работе'
    case 'complete':
      return 'Выполнен'
    case 'confirmed':
      return 'Подтверждено'
    case 'denied':
      return 'Отказ'
    default:
      return null
  }
}

export function getStatusRefundRequest(status) {
  switch (status) {
    case 'processing':
      return 'На рассмотрении'
    case 'complete':
      return 'Одобрена'
    case 'denied':
      return 'Отказ'
    case 'waiting_for_documents':
      return 'Ожидаем документы'
    case 'waiting_for_details':
      return 'Ожидаем деталь'
    default:
      return null
  }
}

export function getStatusColor(status) {
  switch (status) {
    case 'new':
    case 'waiting_for_documents':
      return '#0253B3'
    case 'processing':
    case 'no_payment':
    case 'confirmed':
    case 'waiting_for_details':
      return '#249AF3'
    case 'on_way':
      return '#d5dc00'
    case 'complete':
      return '#16D761'
    case 'denied':
      return '#D0021B'
    default:
      return null
  }
}

export const days = [
  { name: 'monday', label: 'Пн' },
  { name: 'tuesday', label: 'Вт' },
  { name: 'wednesday', label: 'Ср' },
  { name: 'thursday', label: 'Чт' },
  { name: 'friday', label: 'Пт' },
  { name: 'saturday', label: 'Сб' },
  { name: 'sunday', label: 'Нд' },
]

export function hasExtension(fileName, exts) {
  return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(fileName)
}

export const formatDate = (data) => {
  const date = new Date(data).toLocaleDateString('ru')
  const hours = new Date(data).toLocaleTimeString('ru')
  return `${date} ${hours}`
}
