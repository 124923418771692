import React, {Component} from 'react';
import { connect } from "react-redux";
import PopperInfo from '../../HelperComponents/PopperInfo/PopperInfo';
import FormControl from '@material-ui/core/FormControl';
import SelectComponent from '../../HelperComponents/SelectComponent/SelectComponent';
import { isArray } from "../../../helpers/functions";

// import { getCompanies } from "../../../actions/transactionsActions";

import SearchIcon from '../../../assets/image/search_grey.png';
import './TransactionPanel.scss';

class TransactionPanel extends Component {

    state = {
        open: false,
        openPopper: false,
        value : '',
        status: null,
        anchorEl: null,
        idCompany: '',
        projects_list: [
            {label: "Пополнение счета", value: "in"},
            {label: "Покупка товара", value: "out"},
            {label: "Возврат средств", value: "refund"}
        ]
    };

    componentDidMount = () => {
        this.setState({ anchorEl: document.getElementById("products")});
    };

    handleChange = name => event => {
        const { doRequest } = this.props;
        const { idCompany } = this.state;

        this.setState({ [name]: event});
        doRequest(1, event !== null ? event.value : '', idCompany !== '' ? idCompany : '');
    };

    onKeyUp = (e) => {
        const { doRequest } = this.props;
        const { status } = this.state;
        const value = e.target.value;

        if (value.length > 0 && value.indexOf(" ") === -1) {
            this.setState({ openPopper: true });
        } else if (value.length === 0) {
            doRequest(1, status !== null ? status.value : '');
            this.setState({ idCompany: '', openPopper: false })
        } else {
            this.setState({ openPopper: false });
        }

    };

    clickAway = () => {
        this.setState(({openPopper}) => ({
            openPopper: !openPopper
        }));
    };

    selectProduct = (e) => {
        const { doRequest } = this.props;
        const { status } = this.state;

        this.setState({ value: e.company, idCompany: e.id, openPopper: false });
        doRequest(1, status !== null ? status.value : '', e.id);
    };

    onChange = (e) => {
        const value = e.target.value;
        this.setState({value});
    };

    render(){
        const { transactions:{ all_companies }, type} = this.props;
        const { value, anchorEl, openPopper, status, projects_list } = this.state;
        let items = [];

        if ( !!all_companies && all_companies.length > 0 ) {
            items = all_companies.filter( el => !!el.company && el.company !== null ? el.company.toLowerCase().includes(value.toLowerCase()) : null)
        }

        return (
            <div className="panel_page">
                {type === 'search' && <div className='search_wrapper search_transactions_panel'>
                    <div className="search_product_wrapper">
                        <input
                            id="products"
                            type="text"
                            value={value}
                            onChange={(e) => this.onChange(e)}
                            onKeyUp={(e) => this.onKeyUp(e)}
                            placeholder="Введите название компании"
                            autoComplete="off"
                        />
                        <PopperInfo
                            id="products_popper"
                            open={openPopper}
                            anchorEl={anchorEl}
                            clickAway={this.clickAway}
                            position="bottom-start"
                            transition
                        >
                            <ul className="search_transactions_list">
                                {isArray(items) ?
                                    items.map((el, idEl) => (
                                            <li
                                                className="search_transactions_item"
                                                onClick={() => this.selectProduct(el)}
                                                key={idEl}
                                            >
                                                <div className="search_transactions_item_head">
                                                    {el.company}
                                                </div>
                                            </li>
                                        )
                                    )
                                    : <li className="search_transactions_no_items">Компаний не найдено</li>
                                }
                            </ul>
                        </PopperInfo>
                    </div>
                    <div className="search_transactions_input_icon">
                        <img src={SearchIcon} alt="SearchIcon" onClick={() => this.handleSearch("iconClick")} />
                    </div>
                </div>}
                {type === 'sorting' && <FormControl className="select_wrapper">
                    <SelectComponent
                        value={status}
                        options={projects_list}
                        loading={!isArray(projects_list)}
                        change={this.handleChange("status")}
                        placeholder="Тип транзакции"
                        isSearchable = {false}
                    />
                </FormControl>}

            </div>
        );
    }
}

const mapStateToProps = ({transactions}) => ({
    transactions
});


export default connect(mapStateToProps)(TransactionPanel);