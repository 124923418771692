import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import BackLink from '../../HelperComponents/BackLink/BackLink';
import FormControl from '@material-ui/core/FormControl';
import SelectComponent from '../../HelperComponents/SelectComponent/SelectComponent';
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import Loader from "../../HelperComponents/Loader/Loader";
import { isArray, getStatus } from "../../../helpers/functions";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import TooltipMessage from '../../HelperComponents/TooltipMessage/TooltipMessage';
import ErrorIcon from '@material-ui/icons/Error';

import { getOrder, patchStatusOrder } from "../../../actions/ordersActions";
import { positiveNumber } from "../../../helpers/validation";

import TelegramIcon from '../../../assets/image/telegram.png';
import ViberIcon from '../../../assets/image/viber.png';
import WhatsappIcon from '../../../assets/image/whatsapp.png';
import MinusIcon from '../../../assets/image/minus.png';
import PlusIcon from '../../../assets/image/plus.png';

import './OrdersItems.scss';

class OrdersItems extends Component {

    state = {
        loading: true,
        status: null,
        id: null,
        quantity: 0,
        projects_list: [
            {label: "В обработке", value: "processing"},
            {label: "Выполнен", value: "complete"},
            {label: "Отказ", value: "denied"},
        ],
        error: false
    };

    componentDidMount = () => {
        const { match:{ params }, getOrder } = this.props;
        getOrder(params.idOrder).then(res => {
            console.log(res.payload.data);
            if (res.payload && res.payload.status) {
                this.setState({
                    loading: false,
                    status: {label: getStatus(res.payload.data.status), value: res.payload.data.status},
                    id: res.payload.data.id,
                    quantity: res.payload.data.canceled
                });
            }
        });
    };

    handleChange = name => event => {
        this.setState({
            status: event,
            error: false
        });
    };

    doRequest = () => {
        const { patchStatusOrder, history } = this.props;
        const { id, status, quantity } = this.state;
        const data = {
            "status": status.value,
            "canceled": Number(quantity)
        };
        patchStatusOrder(id, data).then(res => {
            setTimeout(() => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    history.push('/admin/orders-page');
                } else {
                    this.setState({ error: true })
                }
            }, 100)
        });
    };

    changeAmount = (e) => {
        const { quantity } = this.state;

        if (e === '-' && quantity !== 0) {
            this.setState(({quantity}) => ({
                quantity: quantity - 1,
                error: false
            }));
        } else if (e === '+') {
            this.setState(({quantity}) => ({
                quantity: quantity + 1,
                error: false
            }));
        } else if (e !== '-' && e !== '+' && positiveNumber(e.target.value)) {
            this.setState({
                quantity: Number(e.target.value),
                error: false
            });
        }
    };

    handleCancel = () => {
        const { history } = this.props;
        history.push('/admin/orders-page');
    };

    render(){
        const { orders:{ order }} = this.props;
        const { loading, status, projects_list, quantity, error } = this.state;

        if (loading) return <Loader/>;

        return (
            <TransitionedBlock>
                <div className="item_page container">
                    <div className="flex-center-btw title-block-page">
                        <div className="title-page">
                            <BackLink/>
                            <h1>Заказ {order.id}</h1>
                        </div>

                    </div>
                    <div className="item_page_block">
                        <div className="block_wrapper">
                            <div className="title_items">Статус заказа</div>
                            <div className="descriptions_items">Статус</div>
                            <FormControl className="select_wrapper">
                                <SelectComponent
                                    value={status}
                                    options={projects_list}
                                    loading={!isArray(projects_list)}
                                    change={this.handleChange('status', )}
                                    placeholder="Статусы"
                                    isClearable="false"
                                    isSearchable = {false}
                                />
                            </FormControl>
                        </div>
                        <div className="line_wrapper" />
                        <div className="block_wrapper">
                            <div className="title_items">Информация о компании</div>
                            <div className="half_block_wrapper_items">
                                <div>
                                    <div className="descriptions_items">Название</div>
                                    <div className="title_items_mini">{order.company}</div>
                                </div>
                                <div>
                                    <div className="descriptions_items">Электронный адрес</div>
                                    <div className="title_items_mini">{order.company_email}</div>
                                </div>
                            </div>
                            <div className="half_block_wrapper_items">
                                {order.phones.map((phone, idPhone) => (
                                    <div key={idPhone}>
                                        <div className="descriptions_items">Телефон</div>
                                        <div className="title_items_mini indent_none">
                                            {phone.phone_number}
                                            <div className="info-social-wrapper">
                                                {!!phone.telegram ? <img src={TelegramIcon} alt="telegram icon"/> : null}
                                                {!!phone.viber ? <img src={ViberIcon} alt="viber icon"/> : null}
                                                {!!phone.whats_app ? <img src={WhatsappIcon} alt="whatsapp icon"/> : null}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="line_wrapper" />
                        <div className="block_wrapper">
                            <div className="title_items">Сведения о товаре</div>
                            <div className="descriptions_items">Название</div>
                            <div className="title_items_mini">{order.name}</div>
                            <div className="half_block_wrapper_items">
                                <div>
                                    <div className="descriptions_items">Артикул</div>
                                    <div className="title_items_mini indent_none">{order.article_number}</div>
                                </div>
                                <div>
                                    <div className="descriptions_items">Производитель</div>
                                    <div className="title_items_mini indent_none">{order.producer}</div>
                                </div>
                            </div>

                            <div className="descriptions_dialog_component">Укажите количество деталей, которые не могут быть доставлены.</div>
                            <div className="amount_wrapper">
                                <span>Количество</span>
                                <div className="amount">
                                    <button
                                        style={{ opacity: quantity === 0 ? "0.3": "1" }}
                                        onClick={() => this.changeAmount('-')}
                                    >
                                        <img src={MinusIcon} alt="-" />
                                    </button>
                                    <input
                                        type="number"
                                        value={quantity}
                                        onChange = {(e) => this.changeAmount(e)}
                                    />
                                    <button
                                        onClick={() => this.changeAmount('+')}
                                    >
                                        <img src={PlusIcon} alt="+" />
                                    </button>
                                </div>
                            </div>

                        </div>
                        {order.comment !== null && order.comment !=="" ?
                            <Fragment>
                                <div className="line_wrapper" />
                                <div className="">
                                    <div className="title_items">Комментарий к заказу</div>
                                    <div className="descriptions_items">Комментарий</div>
                                    <div className="text">{order.comment}</div>
                                </div>
                            </Fragment>
                            :
                            <div style={{height: "30px"}}/>
                        }
                        <div className="btn_wrapper_product">
                            <DefaultButton
                                variant="outlined"
                                classes="cancel_btn"
                                onClick={() => this.handleCancel()}
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                onClick={() => this.doRequest()}
                            >
                                Сохранить
                            </DefaultButton>
                            <div className="file_error flex-center">
                                {error ?
                                    <TooltipMessage
                                        text="Количество деталей не должно превышать общее"
                                        delay={200}
                                        error
                                        position='right'
                                        classes=''
                                    >
                                        <ErrorIcon />
                                    </TooltipMessage>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = ({orders}) => ({
    orders
});

const mapDispatchToProps = {
    getOrder,
    patchStatusOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersItems);