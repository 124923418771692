import React from 'react';

import LogoHeader from '../../../assets/image/logo_header.png';

const AuthHead = ({location}) => {
    return (
        <header className="auth_header">
            <img src={LogoHeader} alt="logo header"/>
        </header>
    );
};

export default AuthHead;