import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckOn from "../../../assets/image/checkbox_on.png";
import CheckOff from "../../../assets/image/checkbox_off.png";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Checkbox from "@material-ui/core/Checkbox";
import timeSVG from "../../../assets/image/time.svg";
import moment from "moment";

import "../PriceList.scss";

const CheckboxHelper = ({
    id,
    company,
    price_list,
    toggleOpen,
    toggleCheck,
    open,
    checkboxObj,
    toggleChildChange,
    markup,
    changeValue,
    markup_isError,
    type
}) => {
    return (
        <div className="expansion-block check_field_wrapper">
            <div className="expansion-title flex-center">
                <div
                    className={type === "approve" ? "checkbox_custom approve" : "checkbox_custom"}
                    onClick={() => {
                        toggleOpen(id);
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    typeof checkboxObj[id] !== "undefined" &&
                                    checkboxObj[id].length === price_list.length
                                }
                                onChange={() => {
                                    toggleCheck(id, price_list);
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                classes={{
                                    root: "custom_check",
                                }}
                                checkedIcon={
                                    <img src={CheckOn} alt="check on" />
                                }
                                icon={<img src={CheckOff} alt="check off" />}
                            />
                        }
                        label={company}
                    />
                    <IconButton>
                        {open.includes(id) ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                    </IconButton>
                    {type !== "approve" ? <div className="markup_wrapper">
                        <span>Наценка</span>
                        <input
                            type="number"
                            // placeholder='0'
                            value={markup}
                            onChange={(e) => {
                                changeValue(id, e.target.value);
                            }}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <div className="box_icon">%</div>
                        {markup_isError ? (
                            <p>Наценка не может быть меньше -100%</p>
                        ) : null}
                    </div> : null}
                </div>
            </div>
            <div
                className="expansion-body flex-center"
                style={{ display: open.includes(id) ? "flex" : "none" }}
            >
                <div className="schedule_map_wrapper">
                    {price_list.map((item) => {
                        return (
                            <div key={item.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                typeof checkboxObj[id] !==
                                                    "undefined" &&
                                                checkboxObj[id].includes(
                                                    item.id
                                                )
                                            }
                                            onChange={() => {
                                                toggleChildChange(
                                                    id,
                                                    item.id,
                                                    price_list.length
                                                );
                                            }}
                                            classes={{
                                                root: "custom_check",
                                            }}
                                            checkedIcon={
                                                <img
                                                    src={CheckOn}
                                                    alt="check on"
                                                />
                                            }
                                            icon={
                                                <img
                                                    src={CheckOff}
                                                    alt="check off"
                                                />
                                            }
                                        />
                                    }
                                    label={item.name}
                                />
                                <img src={timeSVG} className="checkbox-img" />
                                <span className="checkbox-date">
                                    {moment(item.updated_date).format(
                                        "DD.MM.YYYY HH:mm"
                                    )}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default CheckboxHelper;
