import React from 'react';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import './PopperInfo.scss';

const PopperInfo = ({id, open, anchorEl, clickAway, children, position = "bottom-end", modifiers, className = "" }) => {
    return (
        <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            transition
            placement={position}
            modifiers= {modifiers}
        >
            {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={clickAway}>
                    <Grow {...TransitionProps} timeout={350}>
                        <div className={`popper_wrapper ${className}`}>
                            {children}
                        </div>
                    </Grow>
                </ClickAwayListener>
            )}
        </Popper>
    );
};

export default PopperInfo;